import React, {
  Fragment,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { GalleryCardViewProps } from './GalleryCard.types'
import Styles from './GalleryCard.module.scss'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import { i18n } from 'translate/i18n'
import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer'
import useHero from '@onepercentio/one-ui/dist/hooks/useHero'
import chroma from 'chroma-js'
import SectionStyles from 'openspace/components/Section/Section.module.scss'
import AllGalleriesStyles from 'openspace/pages/Unauthenticated/AllGalleries/AllGalleries.module.scss'

export const BaseWidth = Number(Styles.baseWidth)
export const BaseShortAssetWidth = Number(Styles.baseShortAssetWidth)
export const BaseAssetWidth = BaseShortAssetWidth
export const BaseAssetMargin = Number(Styles.baseAssetMargin)
export const BaseAssetInnerPadding = Number(Styles.baseAssetInnerPadding)

const SpaceRequiredForAtLeastOneAsset =
  BaseAssetWidth + BaseAssetInnerPadding * 2

enum DataProps {
  IS_HIGHLIGHT = 'data-highlight',
}

/**
 * A gallery preview and assets preview
 **/
function GalleryCardView({
  gallery,
  willExpand,
  onOpenGallery,
  banner,
  className = '',
  ExpandedContent,
  mode,
  shouldExpandOnMount,
  size = 'static',
  version = 'v1',
}: GalleryCardViewProps) {
  const { heroRef, getHerosOnScreen } = useHero(
    `gallery-${gallery.id}`,
    undefined,
    {
      onHeroDetect: (possibleHero) => {
        return document.querySelectorAll(
          '[data-orderableid="orderable-list-clone"]'
        ).length
          ? false
          : banner
          ? possibleHero.matches(':hover')
          : possibleHero.getAttribute(DataProps.IS_HIGHLIGHT) ===
              (mode === 'highlight' ? '1' : '0') ||
            possibleHero.classList.contains(Styles.banner)
      },
      onHeroEnd: () => {
        if (shouldExpandOnMount) setExpanded(true)
      },
      onHeroStart: (clone) => {
        function disableDynamicHeightAndSetupHeightTransition() {
          if (firstChild.classList.contains(Styles.dynamic)) {
            firstChild.classList.remove(Styles.dynamic)
            firstChild.style.height = 'auto'
            ;(
              firstChild.lastElementChild! as HTMLDivElement
            ).style.maxHeight = `${BaseWidth}px`
          }
        }
        const galleryRoot = clone!
        const firstChild = galleryRoot.firstElementChild! as HTMLDivElement
        clone.style.overflow = 'hidden'
        if (banner) {
          galleryRoot.classList.remove(Styles.readyForTransition)
          disableDynamicHeightAndSetupHeightTransition()
          galleryRoot.classList.add(Styles.banner)
        } else {
          disableDynamicHeightAndSetupHeightTransition()
          galleryRoot.classList.remove(Styles.banner)
          if (shouldExpandOnMount) {
            galleryRoot.classList.add(Styles.readyForTransition)
          } else {
            galleryRoot.classList.remove(Styles.readyForTransition)
          }
        }
      },
      onBeforeTransition: (origin, target) => {
        const findParent = (el: HTMLDivElement) => {
          return (
            el.closest(`.${SectionStyles.items}`) ||
            el.closest(`.${AllGalleriesStyles.orderableGalleries}`) ||
            window.visualViewport!
          )
        }
        return [findParent(origin), findParent(target)] as const
      },
      onHeroSkipped: () => {
        if (shouldExpandOnMount) setExpanded(true)
      },
    }
  )
  const [expanded, setExpanded] = useState(() => {
    const isOrderingCard = !!document.querySelectorAll(
      '[data-orderableid="orderable-list-clone"]'
    ).length
    return (
      shouldExpandOnMount &&
      (isOrderingCard ||
        getHerosOnScreen().filter(
          (a) =>
            a.getAttribute(DataProps.IS_HIGHLIGHT) ===
            (mode === 'highlight' ? '1' : '0')
        ).length === 0)
    )
  })

  useEffect(() => {
    heroRef.current!.setAttribute(
      DataProps.IS_HIGHLIGHT,
      mode === 'highlight' ? '1' : '0'
    )
  }, [])

  const itemRef = heroRef
  const [maxExpandableWidth, setWidth] = useState<number>()
  const [direction, setDirection] = useState<'h' | 'v'>('h')
  useEffect(() => {
    const EXTRA_SPACING = version === 'v1' ? 48 : 0
    function calculateAvailableSpace() {
      const availableContainerWidth =
        itemRef.current!.parentElement!.parentElement!.clientWidth -
        EXTRA_SPACING
      const availableWidth =
        size === 'dynamic' || version === 'v2'
          ? availableContainerWidth
          : Math.min(
              availableContainerWidth,
              BaseWidth + BaseAssetWidth * 3 + BaseAssetInnerPadding * 2
            )

      const spaceAvailableForTheAssets = availableWidth - BaseWidth

      if (spaceAvailableForTheAssets < SpaceRequiredForAtLeastOneAsset) {
        setDirection('v')
        setWidth(availableContainerWidth)
      } else {
        setDirection('h')
        setWidth(spaceAvailableForTheAssets)
      }
    }
    calculateAvailableSpace()
    window.addEventListener('resize', calculateAvailableSpace)
    return () => window.removeEventListener('resize', calculateAvailableSpace)
  }, [])
  const pageSize = useMemo(() => {
    if (direction === 'v') return 1
    return (
      Math.floor(
        ((maxExpandableWidth || 0) - BaseAssetInnerPadding * 2) / BaseAssetWidth
      ) || 1
    )
  }, [direction, maxExpandableWidth])

  const Wrapper = useMemo<(p: PropsWithChildren<{}>) => ReactElement>(() => {
    if (size === 'dynamic' && direction === 'h') {
      const W = ({ children }: PropsWithChildren<{}>) => {
        return <div className={Styles.scrollable}>{children}</div>
      }
      return ({ children }) => (
        <AdaptiveContainer containerElement={W} direction='v'>
          {children as any}
        </AdaptiveContainer>
      )
    } else {
      return ({ children }) => children as ReactElement
    }
  }, [size, direction])

  const occupyAllWidth =
    mode === 'highlight' && direction === 'v' && shouldExpandOnMount
      ? Math.max(302, maxExpandableWidth!)
      : undefined

  const transparentize = useMemo(() => {
    if (version === 'v2') {
      return {
        color: '#fff',
        text: '#fff',
        contrast: 'var(--text-dark)',
      }
    }

    if (shouldExpandOnMount && gallery.predominantColor) {
      const transparentizedColor = chroma
        .hex(gallery.predominantColor)
        .mix(chroma.hex('#fff'), 0.5)

      return {
        color: transparentizedColor.hex(),
        text: transparentizedColor.luminance() <= 0.4 ? '#fff' : '#000',
        contrast: transparentizedColor.luminance() <= 0.4 ? '#0006' : '#fff6',
      }
    } else {
      return {
        color: '#fff',
        text: '#000',
        contrast: '#fff6',
      }
    }
  }, [gallery, shouldExpandOnMount])

  return (
    <>
      <div
        className={`${Styles[version] || ''} ${Styles.root} ${
          Styles[direction]
        } ${willExpand ? Styles.readyForTransition : ''} ${
          banner ? Styles.banner : ''
        } ${className}`}
        ref={itemRef}
        style={
          {
            width: occupyAllWidth,
            '--predominant-color': gallery.predominantColor,
            '--predominant-color-light': transparentize.color,
            '--text-contrast-to-gallery': transparentize.contrast,
            '--bg-image': `url("${gallery.banner}")`,
          } as any
        }>
        <div
          onClick={() => onOpenGallery(gallery)}
          className={`${Styles.gallery} ${expanded ? Styles.expanded : ''} ${
            size === 'dynamic' ? Styles.dynamic : ''
          }`}
          style={
            {
              width: occupyAllWidth,
              height: direction === 'v' ? 302 : undefined,
              '--text-default-color': transparentize.text,
            } as any
          }>
          <GalleryThumbnail icon={gallery.icon} />
          <div>
            <Text type={FigmaTypo.H4}>
              {gallery[i18n.language as LanguageCodes].title}
            </Text>
            <Text type={FigmaTypo.P14}>
              {gallery[i18n.language as LanguageCodes].description}
            </Text>
          </div>
        </div>
        {maxExpandableWidth !== undefined && (
          <AdaptiveContainer direction={direction}>
            {expanded ? (
              <div
                key={'ass'}
                className={Styles.assets}
                style={{
                  minWidth: direction === 'v' ? 302 : undefined,
                  width:
                    direction === 'h' ? maxExpandableWidth : occupyAllWidth,
                  height:
                    direction === 'h'
                      ? '100%'
                      : size === 'dynamic'
                      ? undefined
                      : 355,
                }}>
                {ExpandedContent && (
                  <Wrapper>
                    <ExpandedContent
                      key='c'
                      pageSize={pageSize}
                      gallery={gallery}
                    />
                  </Wrapper>
                )}
              </div>
            ) : (
              <Fragment key={'null'} />
            )}
          </AdaptiveContainer>
        )}
      </div>
    </>
  )
}

export function GalleryThumbnail({
  icon,
  className,
}: {
  icon: string
  className?: string
}) {
  return (
    <div className={className} style={{ backgroundImage: `url(${icon})` }} />
  )
}

export default GalleryCardView
