import { Theme } from '@material-ui/core'
import { flatten } from '@onepercentio/one-ui/dist/utils/flatten'
import { get, set } from 'lodash'
import BG from 'openspace/assets/svg/bg.svg'
import BGDark from 'openspace/assets/svg/bg-dark.svg'

const themeModeBasedFactory = (theme: {
  palette: { type: 'light' | 'dark' }
}) => {
  const basePalette =
    theme.palette.type === 'dark' ? OneUIDarkConfig : OneUILightConfig
  return {
    '--text-default-color': () =>
      theme.palette.type === 'dark' ? '#fff' : '#000',
    '--svg-color': () => (theme.palette.type === 'dark' ? '#fff' : '#626262'),
    '--input-text-color': () =>
      theme.palette.type === 'dark' ? '#fff' : '#626262',
    '--text-color': () => (theme.palette.type === 'dark' ? '#fff' : '#000'),
    '--glass-bg': () => (theme.palette.type === 'dark' ? '#fff4' : '#fffa'),
    '--main-bg': () => (theme.palette.type === 'dark' ? '#0D0D0D' : '#fff'),
    '--secondary-background': () =>
      theme.palette.type === 'dark' ? '#181818' : '#F5F9FA',
    '--bg-image': `url(${theme.palette.type === 'light' ? BG : BGDark})`,
    '--halo': () => {
      const [c1, c2] =
        theme.palette.type === 'dark'
          ? ['#1f3a63', '#fff']
          : ['#1f3a63', '#1b0b28']
      return `radial-gradient(
              68.01% 282.67% at 31.99% 50%,
              var(--digital-blue) 0%,
              ${c1} 67.33%,
              ${c2} 88.02%
            )`
    },
    '--halo-section': () => {
      const [c1, c2] =
        theme.palette.type === 'dark'
          ? ['rgba(220,209,255,.1)', 'rgba(203,250,253,.1)']
          : ['rgba(220,209,255,.1)', 'rgba(203,250,253,.3)']
      return `radial-gradient(
                ${c1} 12.49%,
                ${c2} 35.69%,
                #fff0 60%
              )`
    },
    '& [color="inverse"]': {
      '--text-default-color': () =>
        theme.palette.type === 'dark' ? 'white' : '#000',
      '--svg-color': () => (theme.palette.type === 'dark' ? 'white' : '#000'),
    },
    ...OneUIProperties.reduce((r, property) => {
      set(r, toVar(property), get(basePalette, property))
      return r
    }, {}),
  }
}

export const overrides = (theme: Theme) =>
  ({
    MuiCssBaseline: {
      '@global': {
        'body, body > *': {
          '--error-color': '#d00',
          '--screen-padding': '16px',
          '--button-box-shadow': '0px 4px 24px -8px rgba(0, 0, 0, 0.2)',
          '--button-transparent-bg': 'rgba(255, 255, 255, 0.4)',
          '--text-dark': '#0008',
          '--top-bar-height': '94px',
          '--negative-top-bar-height': '-94px',
          '--glass-backdrop': 'blur(8px)',
          '--zoomable--backdrop-bg': 'var(--glass-bg)',
          '--digital-blue': () => theme.palette.primary.light,
          '--primary-color': () => theme.palette.primary.main,
          '--digital-blue-alpha': () => theme.palette.primary.light + 'aa',
          '--scrollbar-color': 'var(--digital-blue-alpha)',
          '--digital-blue-contrast': 'white',
          '--main-font': () => theme.typography.font.primary,
          '--secondary-font': () => theme.typography.font.secondary,
          '--mutable-hamburger-background': 'var(--text-default-color)',
          '--card-elevation': '0px 4px 10px rgba(0, 0, 0, 0.1)',
          '--card-elevation-x2': '0px 2px 6px rgba(0, 0, 0, 0.8)',
          '--card-refraction': '10px 10px 15px rgba(0, 0, 0, 0.25)',
          '--ok-color': () => theme.palette.oneui!.status.success,
          '--alert-bg': () => theme.palette.alert.background,
          '--adaptive-dialog-bg': () =>
            theme.palette.type === 'light'
              ? '#fff'
              : 'var(--secondary-background)',
          '--benefits-card-bg': () =>
            theme.palette.type === 'light'
              ? '#fff'
              : 'var(--secondary-background)',
          '--file-input-bg-color': () => theme.palette.background.paper,
          '--footer-overflow-spacing': () =>
            `${theme.palette.rarum?.footer.overflowSpacing || 0}px`,
          '--collection-item-bg': () =>
            theme.palette.type === 'light' ? '#fffc' : '#333d',
          ...themeModeBasedFactory(theme),
          '--main-bg': () => theme.palette.background.default,
        },
        'body .dark': themeModeBasedFactory({ palette: { type: 'dark' } }),
        'body .light': themeModeBasedFactory({ palette: { type: 'light' } }),
        '[color="inverse"]': {
          '--text-default-color': () =>
            theme.palette.type === 'dark' ? 'white' : '#000',
          '--svg-color': () =>
            theme.palette.type === 'dark' ? 'white' : '#000',
        },
      },
    },
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiButton: {
      root: {
        borderWidth: 1.5,
        textTransform: 'none', // NOTE: default UPPER
        fontWeight: 600,
        padding: '10px 0',
        fontSize: '0.875rem',
        '&$disabled': { borderWidth: 1.5 },
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.dark,
        },
        '&.rounded': {
          borderRadius: '32px',
          padding: '10px 12px',
        },
        '&.highlight': {
          background:
            'linear-gradient(268.68deg, #00E1FF 0.49%, #DE00E9 75.95%)',
        },
      },
      containedSizeLarge: {
        fontSize: '0.875rem',
        padding: 16,
      },
      outlined: { borderWidth: 1.5, '&.Mui-disabled': { borderWidth: 1.5 } },
      outlinedPrimary: { borderWidth: 1.5 },
    },
    MuiFormLabel: {
      root: {
        '&$disabled': {
          color: () => theme.palette.text.secondary,
        },
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          color: () => theme.palette.text.secondary,
          opacity: 0.6,
        },
        '&::before': {
          borderColor: () => `${theme.palette.text.secondary} !important`,
        },
      },
    },
  } as const)
const OneUILightConfig = {
  status: {
    success: '#78ADA0',
  },
  button: {
    background: '#fff',
    textColor: '#000',
    inverseTextColor: '#fff',
    primary: {
      textColor: '#fff',
    },
    outline: {
      default: '#626262',
      light: {
        text: '#626262',
        background: '#D5D6D5',
      },
      hover: {
        textColor: '#aaa',
      },
    },
    highlight: {
      primary: '#00E1FF',
      secondary: '#DE00E9',
      text: '#fff',
    },
    filled: {
      hover: {
        background: '#ddd',
        textColor: '#000',
      },
    },
  },
}
const OneUIDarkConfig = {
  status: {
    success: '#78ADA0',
  },
  button: {
    background: '#fff',
    textColor: '#000',
    primary: {
      textColor: '#000',
    },
    outline: {
      default: '#fff',
      light: {
        text: '#D5D6D5',
        background: '#D5D6D5',
      },
      hover: {
        textColor: '#aaa',
      },
    },
    highlight: {
      primary: '#00E1FF',
      secondary: '#DE00E9',
      text: '#fff',
    },
    filled: {
      hover: {
        background: '#ddd',
        textColor: '#000',
      },
    },
  },
}
const OneUIProperties = Object.keys(flatten(OneUIDarkConfig))
const toVar = (property: string) =>
  `--oneui-${property.toLowerCase().replace(/[^a-z]/g, '-')}`

export const OneUI = () => {
  return OneUIProperties.reduce((r, property) => {
    set(r, property, `var(${toVar(property)})`)
    return r
  }, {})
}
