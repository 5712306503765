import { SelectItem } from '@onepercentio/one-ui/dist/components/Select/Select'
import { OfferType } from 'core/logic/drop/drop.types'
import { Address } from 'features/Form/AddressForm/AddressForm.types'
import { UploadTask } from 'firebase/storage'
import { ReactElement } from 'react'
import { t } from 'translate/i18n'

export enum DataStatus {
  /** Form is empty */
  PENDING,
  /** Form is partially filled */
  INCOMPLETE,
  /** Form is completed */
  COMPLETE,
  /** Form has error */
  ERROR,
}

export enum StepStatus {
  /** When the data is empty and needs to be filled */
  INCOMPLETE,
  /** When data has already been filled */
  COMPLETED,
  /** When the step is the current selected */
  CURRENT,
  /** When the step has error */
  ERROR,
}

export enum FormStep {
  PERSONAL_DATA,
  ADDITIONAL_DATA,
  DOCUMENTS,
  INVEST_PROFILE,
  CONTRACTS,
}

export enum FormMode {
  READ_ONLY,
  WRITE,
}

export type FormFieldView = FormField & {
  title: string
}

export type BasicFormFields =
  | {
      type: 'select'
      filter?: (item: SelectItem, term: string) => boolean
      options: SelectItem[]
    }
  | {
      type: 'text'
      mask?: string
    }
  | {
      type: 'number'
    }

export type FormField = {
  optional?: true
  id: string
  validator?: (val: AnswerAction<FormField> | undefined) => boolean | string
  footer?: (val: AnswerAction<FormField>) => ReactElement
} & (
  | BasicFormFields
  | {
      type: 'phone'
      mask?: string
    }
  | {
      type: 'currency' | 'date'
    }
  | {
      type: 'radio'
      options: SelectItem[]
    }
  | {
      type: 'check' | 'rawcheck'
      options: (SelectItem & {
        header?: {
          title: Parameters<typeof t>[0]
          description: Parameters<typeof t>[0]
        }
      })[]
    }
  | {
      type: 'file'
      extensions: string[]
    }
  | {
      type: 'accept'
      link: {
        type: 'file' | 'route'
        to: string
      }
      accept: {
        label: string
        optional?: true
      }[]
    }
  | {
      type: 'address'
    }
)

export type AnswerByField<F extends Pick<FormField, 'type'>> =
  F['type'] extends 'file'
    ? UploadTask | true
    : F['type'] extends 'address'
    ? [address: Address, isComplete: boolean, error: string | false]
    : F['type'] extends 'accept' | 'check' | 'rawcheck'
    ? boolean[]
    : F['type'] extends 'phone'
    ? [phoneNumber: string, isValid: boolean, error: string | undefined]
    : string

export type SerializableAnswerByField<F extends Pick<FormField, 'type'>> =
  F['type'] extends 'file' ? boolean : Exclude<AnswerByField<F>, UploadTask>

export type AnswerAction<F extends Pick<FormField, 'type'>> =
  F['type'] extends 'file' ? File : AnswerByField<F>

export type FormProps = {
  offer: OfferType
  onFormSubmitted: () => void
  savedAnswers: Partial<{
    [questionId: string]: AnswerByField<FormField>
  }>
  startAt: FormStep
  /** Indicates the status will only be updated for those changed answers */
  keepStatus: boolean
} & ClickActions<'onGoBack'>
export type StepFormProps = Pick<FormProps, 'savedAnswers' | 'offer'> & {
  step: FormStep
} & ModeProps

export type ModeProps =
  | {
      mode: FormMode.READ_ONLY
    }
  | ({
      mode: FormMode.WRITE
    } & ClickActions<'onFinish' | 'onCancel'>)
export type FormViewProps = {
  sections: {
    title: string
    description?: string
    questions: FormFieldView[]
  }[]
  headings: {
    stepName: string
    dataStatus: DataStatus
    stepStatus: StepStatus
  }[]
  step?: FormStep
  answers: Partial<{
    [questionId: string]: AnswerByField<FormField>
  }>
  errors: {
    [questionId: string]: string | undefined
  }
  onAnswer: <T extends FormField['type']>(
    questionType: T,
    id: string,
    answer: AnswerAction<{ type: T }>
  ) => void
} & {
  onBack: () => void
  next: {
    disabled: boolean
    label: string
    action: () => void
  }
}

export type AnswersMap = Partial<{
  [questionId: string]: AnswerByField<FormField>
}>

export enum INVESTOR_PROFILE {
  /**
   * User can only spend up to 20k
   */
  RESTRICTED,
  /**
   * User can only spend up to 10% of it's income
   */
  LIMITED,
  /**
   * User doesn't have restrictions
   */
  UNLIMITED,
}
