import { i18n } from 'translate/i18n'
import { useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import stripeLogo from 'assets/images/stripe.svg'
import junoLogo from 'assets/images/juno-logo.png'
import coinbaseLogo from 'assets/images/coinbase.svg'
import IconRarum from 'components/IconRarum'
import useStyles from './SafetyLabel.style'
import MercadoPagoLogo from 'assets/icons/mercado_pago.svg'

interface SafetyLabelViewProps {
  type?: string
  className?: React.ReactNode
}

const SafetyLabelView: React.FC<SafetyLabelViewProps> = ({
  className,
  type,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const getLogoBasedPaymentType = (paymentType: string | undefined) => {
    switch (paymentType) {
      case 'pix':
        return junoLogo
      case 'pix_mp':
        return MercadoPagoLogo
      case 'credit':
        return stripeLogo
      case 'crypto':
        return coinbaseLogo

      default:
        return ''
    }
  }

  return (
    <Box className={clsx(classes.wrapper, className)}>
      <IconRarum
        icon='security'
        size={22}
        color={theme.palette.grey[500]}
        className={classes.icon}
      />
      {type ? (
        <>
          <Typography variant='body2' component='span' className={classes.text}>
            {i18n.t('authenticated.payment.safetyLabel.safePurchaseBy')}
          </Typography>
          <figure className={classes.figure}>
            <img src={getLogoBasedPaymentType(type)} alt='' />
          </figure>
        </>
      ) : (
        <Typography variant='body2' component='span' className={classes.text}>
          {i18n.t('authenticated.payment.safetyLabel.safePurchase')}
        </Typography>
      )}
    </Box>
  )
}

export default SafetyLabelView
