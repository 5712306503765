import {
  ElementRef,
  ForwardedRef,
  forwardRef,
  Fragment,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Benefit, BenefitsViewProps, BenefitWithStatus, ClaimableBenefit } from './Benefits.types'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import AdaptiveButton from '@onepercentio/one-ui/dist/components/AdaptiveButton'
import { t, tO } from 'translate/i18n'
import { ReactComponent as Checkbox } from 'assets/icons/checkmark.svg'
import { ReactComponent as Caution } from 'assets/icons/caution.svg'
import Countdown from '@onepercentio/one-ui/dist/components/Countdown'
import { CountdownTextModel } from '@onepercentio/one-ui/dist/components/Countdown/Countdown'
import { ONE_DAY_DELAY } from 'utility/timer'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import { Container } from '@material-ui/core'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'
import useHero from '@onepercentio/one-ui/dist/hooks/useHero'
import Loader from '@onepercentio/one-ui/dist/components/Loader'
import { BENEFITS_TEST_IDS } from './Benefits.e2e'
import Styles from './Benefits.module.scss'

const { Scaled } = require('assets/components/benefits/bg.svg?w=150')

enum StateBenefit {
  CLAIMED = 'claimed',
  EXPIRED = 'expired',
  AVAILABLE = 'available',
  CLAIMING = 'claiming',
}

const DEFAULT_HERO_CONFIG: Parameters<typeof useHero>['2'] = {
  onHeroStart: (clone, origin, target) => {
    origin.style.visibility = 'visible'
    clone.classList.add(Styles.heroEls)
    origin.setAttribute('data-hero', target.getAttribute('data-hero')!)
  },
  onHeroDetect: (origin, target) => {
    const theOriginIsACard = !!origin.closest(`.${Styles.benefitItemContainer}`)
    const theTargetIsAnotherCard = !!target.closest(
      `.${Styles.benefitItemContainer}`
    )

    const fromCardToDetails = theOriginIsACard && !theTargetIsAnotherCard
    const fromDetailsToCard = !theOriginIsACard && theTargetIsAnotherCard

    return fromCardToDetails || fromDetailsToCard
  },
}

const HeroedImage = ({ benefit }: { benefit: Benefit }) => {
  const { heroRef } = useHero(
    `benefit-${benefit.code}-comp-img`,
    undefined,
    DEFAULT_HERO_CONFIG
  )
  return (
    <img
      ref={heroRef as any}
      className={Styles.coverImg}
      src={benefit.coverImg ?? Scaled['0.5x']}
      alt={benefit.title}
    />
  )
}

const HeroedTitle = ({ benefit }: { benefit: Benefit }) => {
  const { heroRef } = useHero(
    `benefit-${benefit.code}-comp-title`,
    undefined,
    DEFAULT_HERO_CONFIG
  )
  return (
    <Text ref={heroRef} type={FigmaTypo.H4}>
      {benefit.title}
    </Text>
  )
}

const UnitsLabel = forwardRef(
  (
    { units }: { units: number },
    ref: ForwardedRef<ElementRef<typeof Text>>
  ) => (
    <Text ref={ref} type={FigmaTypo.P12}>
      {tO('components.benefits.remaining', { count: units })}
    </Text>
  )
)

const HeroedUnits = ({ benefit }: { benefit: BenefitWithStatus }) => {
  const { heroRef } = useHero(
    `benefit-${benefit.code}-comp-units`,
    undefined,
    DEFAULT_HERO_CONFIG
  )
  return <UnitsLabel ref={heroRef} units={benefit.available} />
}

const HeroedExpiration = ({
  benefit,
  timeRemaining,
  state,
  onFinishCountdown,
}: {
  benefit: BenefitWithStatus
  timeRemaining?: number
  state: StateBenefit
  onFinishCountdown: () => void
}) => {
  const showExpiration =
    state === StateBenefit.AVAILABLE && timeRemaining !== undefined
  const Wrapper = useCallback(
    function Wrapper() {
      const countdownStyle = useMemo(() => {
        if (timeRemaining! < ONE_DAY_DELAY) {
          return (
            <Countdown
              timeRemaining={timeRemaining!}
              model={CountdownTextModel.SHORT}
              onFinish={() => setInterval(() => onFinishCountdown(), 1000)}
            />
          )
        } else {
          return (
            <span>
              {tO('components.benefits.days', {
                count: Math.floor(timeRemaining! / ONE_DAY_DELAY),
              })}
            </span>
          )
        }
      }, [])
      const { heroRef } = useHero(
        `benefit-${benefit.code}-comp-exp`,
        undefined,
        DEFAULT_HERO_CONFIG
      )
      return (
        <Text ref={heroRef} type={FigmaTypo.P12}>
          {tO('components.benefits.expireIn')} {countdownStyle}
        </Text>
      )
    },
    [benefit, onFinishCountdown, timeRemaining]
  )

  return !showExpiration ? null : <Wrapper />
}

const useBenefitState = (b: BenefitWithStatus) => {
  const [now, setNow] = useState(Date.now)

  const timeRemaining = useMemo(() => {
    if (b.expiration) return b.expiration.getTime() - now
  }, [now, b])

  const isExpired = useMemo(() => {
    return timeRemaining !== undefined && timeRemaining <= 0
  }, [timeRemaining])

  const state = useMemo(() => {
    if (b.status === 'AWAITING_CONFIRMATION') return StateBenefit.CLAIMING
    if (b.available === 0) return StateBenefit.CLAIMED
    if (isExpired) return StateBenefit.EXPIRED
    return StateBenefit.AVAILABLE
  }, [b, isExpired])

  const hasUnitsAvailable = state === StateBenefit.AVAILABLE && b.available > 0

  const resetCountdown = useCallback(() => setNow(Date.now()), [])

  return { state, timeRemaining, resetCountdown, hasUnitsAvailable }
}

export const BenefitStatusView = forwardRef(
  (
    {
      benefit: b,
      onClaim,
      onDetails,
    }: {
      benefit: BenefitWithStatus
      onClaim?: (benefit: ClaimableBenefit) => void
      onDetails?: (benefit: ClaimableBenefit) => void
    },
    ref: ForwardedRef<{ showCard: () => void }>
  ) => {
    const { state, timeRemaining, resetCountdown, hasUnitsAvailable } =
      useBenefitState(b)

    const content = (
      <Fragment key={`${Styles[state]} ${b.available}`}>
        <HeroedImage benefit={b} />
        <div>
          <HeroedTitle benefit={b} />
          <Text type={FigmaTypo.P14}>{b.description}</Text>

          <HeroedExpiration
            benefit={b}
            state={state}
            onFinishCountdown={resetCountdown}
            timeRemaining={timeRemaining}
          />
        </div>
        <div className={Styles.action}>
          {!onClaim ? null : (
            <>
              {hasUnitsAvailable && <UnitsLabel units={b.available} />}
              <AdaptiveButton
                variant={
                  state !== StateBenefit.AVAILABLE ? 'transparent' : 'filled'
                }
                color={state === StateBenefit.AVAILABLE ? 'primary' : 'inverse'}
                onClick={(e) => {
                  e.stopPropagation()
                  if (state === StateBenefit.AVAILABLE) onClaim(b)
                }}
                disabled={state !== StateBenefit.AVAILABLE}>
                <Fragment key={state}>
                  {state === StateBenefit.AVAILABLE ? (
                    tO('components.benefits.claim')
                  ) : state === StateBenefit.EXPIRED ? (
                    <div className={Styles.col}>
                      <div className={Styles.icon}>
                        <Caution />
                      </div>
                      {tO('components.benefits.expired')}
                    </div>
                  ) : state === StateBenefit.CLAIMING ? (
                    <div className={Styles.col}>
                      <Loader />
                      {tO('components.benefits.claiming')}
                    </div>
                  ) : (
                    <div className={Styles.col}>
                      <div className={Styles.icon}>
                        <Checkbox />
                      </div>
                      {tO('components.benefits.claimed')}
                    </div>
                  )}
                </Fragment>
              </AdaptiveButton>
            </>
          )}
          {hasUnitsAvailable && <HeroedUnits benefit={b} />}
        </div>
      </Fragment>
    )
    const id = `benefit-item-${b.code}`
    const originalHero = useRef<HTMLDivElement>()
    const hero = useHero(id, undefined, {
      onHeroDetect: (ref) => {
        originalHero.current = ref
        return ref.matches(':hover')
      },
      onHeroEnd: () => {
        originalHero.current!.style.opacity = '0'
      },
      onHeroStart: (ref) => {
        ref.classList.add(Styles.animating)
      },
    })
    useImperativeHandle(
      ref,
      () => ({
        showCard: () => {
          originalHero.current!.setAttribute('data-hero', id)
          originalHero.current!.style.visibility = 'initial'
          originalHero.current!.style.opacity = '1'
        },
      }),
      []
    )

    return (
      <UncontrolledTransition
        ref={(ref) => {
          if (ref) {
            ;(hero.heroRef as any).current! = ref.sectionRef.current
          }
        }}
        className={`${Styles.benefitItemContainer} ${
          onDetails ? Styles.clickable : ''
        }`}
        contentClassName={`${Styles.root} ${Styles[state]}`}
        transitionType={TransitionAnimationTypes.COIN_FLIP}
        onClick={
          !onDetails
            ? undefined
            : () => {
                onDetails(b)
              }
        }>
        {content}
      </UncontrolledTransition>
    )
  }
)

export const BenefitItem = ({
  benefit: b,
  onDetails,
}: {
  benefit: Benefit | BenefitWithStatus
  onDetails?: (benefit: Benefit) => void
}) => {
  return (
    <UncontrolledTransition
      className={`${Styles.benefitItemContainer} ${Styles.clickable}`}
      contentClassName={`${Styles.root} ${Styles[StateBenefit.AVAILABLE]}`}
      transitionType={TransitionAnimationTypes.COIN_FLIP}
      onClick={() => onDetails?.(b)}>
      <Fragment key='item'>
        <HeroedImage benefit={b} />
        <div>
          <HeroedTitle benefit={b} />
          <Text type={FigmaTypo.P14}>{b.description}</Text>
        </div>
      </Fragment>
    </UncontrolledTransition>
  )
}

/**
 * A place where the user can check the benefits it has available based on an asset
 **/
export default function BenefitsView(props: BenefitsViewProps) {
  return (
    <>
      <Container maxWidth='lg'>
        <Text type={FigmaTypo.H2}>{t('components.benefits.title')}</Text>
        <Text type={FigmaTypo.P16}>{t('components.benefits.subtitle')}</Text>
        <Spacing size='32' />
        <div className={Styles.parentRoot}>
          {props.benefits.map((b) => {
            if ('onCollect' in props) {
              return (
                <BenefitStatusView
                  key={b.title}
                  benefit={b as BenefitWithStatus}
                  onClaim={props.onCollect}
                  onDetails={props.onDetails}
                />
              )
            } else {
              return (
                <BenefitItem
                  key={b.title}
                  benefit={b as Benefit}
                  onDetails={props.onDetails}
                />
              )
            }
          })}
        </div>
      </Container>
    </>
  )
}

function BenefitDetailsStatusInfo({ benefit }: { benefit: BenefitWithStatus }) {
  const { state, timeRemaining, resetCountdown, hasUnitsAvailable } =
    useBenefitState(benefit)
  return (
    <>
      <HeroedExpiration
        benefit={benefit}
        state={state}
        onFinishCountdown={resetCountdown}
        timeRemaining={timeRemaining}
      />
      {hasUnitsAvailable && <HeroedUnits benefit={benefit} />}
    </>
  )
}

export function BenefitDetails({
  benefit,
}: {
  benefit: Benefit | BenefitWithStatus
}) {
  return (
    <>
      <div className={Styles.detailsContainer}>
        <div>
          <HeroedImage benefit={benefit} />
          <HeroedTitle benefit={benefit} />

          {'status' in benefit && (
            <BenefitDetailsStatusInfo benefit={benefit} />
          )}
        </div>
        <div>
          <Text type={FigmaTypo.H4}>
            {tO('components.benefits.claimConfirmation.details.details')}
          </Text>
          <div
            data-testid={BENEFITS_TEST_IDS.DETAILS_CONTAINER}
            dangerouslySetInnerHTML={{ __html: benefit.details }}
          />
        </div>
      </div>
    </>
  )
}
