import { rrfConfig, firebase } from 'core/modules/firebase'
import store from 'core/modules/redux'
import React, { PropsWithChildren } from 'react'
import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SnackbarProvider } from 'notistack'
import { WalletConnectorProvider } from 'core/logic/wallet'
import { UseWalletProvider } from 'use-wallet'
import FeatureProvider from 'context/Feature'
import { WalletProvider } from 'context/Wallet'
import { ToastProvider } from 'context/ToastAlert'
import { GalleryProvider } from 'context/Gallery'
import ProfileProvider from 'context/Profile'
import DisplayModelsProvider from 'context/DisplayModels'
import MyCollectionProvider from 'context/MyCollection'
import ChainProvider from 'context/Chain'
import ThemeProvider from 'containers/ThemeProvider/ThemeProvider'
import { ProcessPoolProvider } from 'context/ProcessPool'
import AssetProvider from 'context/Asset'
import ChallengeProvider from 'context/Challenge'
import ContextAsyncControlProvider from '@onepercentio/one-ui/dist/context/ContextAsyncControl'
import UserProvider from 'context/User'

// Firebase props
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
}

const queryClient = new QueryClient()

// Providers
const AllProviders = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ContextAsyncControlProvider>
      <ProcessPoolProvider>
        <DisplayModelsProvider>
          <UseWalletProvider>
            <SnackbarProvider maxSnack={3}>
              <QueryClientProvider client={queryClient}>
                <WalletConnectorProvider>
                  <ThemeProvider>
                    <Provider store={store}>
                      <ReactReduxFirebaseProvider {...rrfProps}>
                        <UserProvider>
                          <WalletProvider>
                            <ChainProvider>
                              <GalleryProvider>
                                <AssetProvider>
                                  <ChallengeProvider>
                                    <FeatureProvider>
                                      <ToastProvider>
                                        <MyCollectionProvider>
                                          <ProfileProvider>
                                            {children}
                                          </ProfileProvider>
                                        </MyCollectionProvider>
                                      </ToastProvider>
                                    </FeatureProvider>
                                  </ChallengeProvider>
                                </AssetProvider>
                              </GalleryProvider>
                            </ChainProvider>
                          </WalletProvider>
                        </UserProvider>
                      </ReactReduxFirebaseProvider>
                    </Provider>
                  </ThemeProvider>
                </WalletConnectorProvider>
              </QueryClientProvider>
            </SnackbarProvider>
          </UseWalletProvider>
        </DisplayModelsProvider>
      </ProcessPoolProvider>
    </ContextAsyncControlProvider>
  )
}

export default AllProviders
