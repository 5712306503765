import { ClaimAuctionStatus } from "components/offer/OfferAuctionRace/ClaimAuctionItem/ClaimAuctionItem.types";
import { KYCRequiredStatus } from "components/offer/OfferAuctionRace/KYCActionWrapper/KYCActionWrapper.types";
import { PREDEFINED_DEADLINES } from "pages/Authenticated/Marketplace/CreateOffer/CreateOffer.types";
import { TranslationShape } from "./rarum.pt";

const messages: { "es-ES": TranslationShape } = {
  "es-ES": {
    translations: {
      features: {
        challenge: {
          claim: {
            claiming: {
              title: "Por favor, espere",
              description: "Estamos procesando la operación de redención del artículo."
            },
            confirmation: {
              actions: {
                viewDetails: `Ver datos del reclamo`,
                goToClaim: `Ir al reclamo`,
                approve: "Aprobar",
              },
              approve_gallery: `Es necesario autorizar la manipulación de los tokens de la galería {{galleryName}}.`,
              title_claiming: `Reclamando ítem`,
              deplete: `El item {{itemName}} ya no estará disponible en su colección, ya que todas las unidades están siendo utilizadas para el reclamo.`,
              disclaimer: `Una vez que confirmes el reclamo, la operación no se podrá deshacer.`,
              warning: `Los artículos seleccionados para el reclamo se transformarán en un nuevo item y la cantidad disponible se reducirá en "Mi colección".`,
              title: "Confirmación del reclamo",
            },
            authRequired: `Para ver su progreso, es necesario entrar con su cuenta`,
            requirement: {
              available: `{{available}} de {{required}} item(s) disponibles para reclamar`,
              status: {
                incomplete: `Reclamo pendiente`,
                complete: "Completo",
              },
            },
            loading: {
              description: `Estamos cargando la información del reclamo`,
              title: "Espere un momento",
            },
            follow_instructions: `¿Qué es necesario para reclamar esta recompensa?`,
            title: "Reclamación del item",
            reward_rules: "Reglas del reclamo",
            requirements: "Requisitos para reclamar",
            more_details: "Más detalles",
            actions: {
              seeItem: `Ver ítem`,
              status: {
                claiming: 'Reclamando',
                claimable: `Reclamar`,
                unavailable: "Complete los ítem para reclamar",
              },
            },
            status: {
              claimable: "Disponible para reclamar",
              unavailable: "Indisponible para reclamar",
            },
          },
          section: {
            title: "Recompensas destacadas",
          },
          banner: {
            amount_of_type: "{{count}} artículos {{itemType}}",
            to_claim: "Para recobrar esta recompensa, necesitas:",
          },
        },
      },
      walletRequiredControl: {
        connectWallet: "Conectar billetera",
        connectWalletDescription:
          "Para comenzar este proceso, primero debes conectar tu billetera Metamask.",
        connectWalletLink: "Para saber cómo hacerlo, ingresa aquí.",
        transferWallet: {
          intro: {
            actions: {
              cancel: "Cancelar",
              startOperation: "Iniciar operación",
            },
          },
          waitingConfirmation: {
            title: "Confirmación necesaria",
            description:
              "Para seguir el proceso, confirma el enlace que acabamos de enviar a tu correo electrónico. Se requiere confirmación para conectar sus NFT a la billetera conectada en su perfil.",
            actions: {
              understood: "Entendí",
            },
          },
          fromEmailLink: {
            title: "Confirmar operación",
            description:
              "Vamos a transferir todos tus NFT a tu billetera conectada.:",
            actions: {
              understoodLimitations:
                "Entiendo que este proceso se realizará una sola vez y no se puede deshacer.",
              understoodDanger:
                "Soy consciente de los riesgos y beneficios de utilizar una billetera externa y de que Rarum ya no tendrá acceso a mis NFT una vez completada la migración.",
              cancel: "Cancelar",
              confirm: "Confirmar",
            },
          },
          operationStarted: {
            title: "Operación confirmada",
            description:
              "La operación está en curso y en unos minutos tus artículos serán transferidos a tu billetera.",
            actions: {
              understood: "Entendí",
            },
          },
        },
        migrationRequired: {
          title: "Conectar NFTs a la Cartera",
          description: "Para proceder con esta operación, primero necesitas conectar los elementos de tu colección a tu cartera.",
          actions: {
            goToMigration: "Ir a la migración"
          }
        },
        wrongWallet: {
          title: "Error conectando la billetera",
          description1: "No es posible conectarse usando la billetera:",
          description2:
            "Inténtalo de nuevo con la billetera asociada a tu cuenta:",
        },
        actions: {
          connectWalletMetamask: 'Conectar billetera Metamask',
          reloadWindow: 'Verificar instalación',
          switchNetwork: 'Conéctate a la red {{name}}',
        },
        switchNetwork: 'Conéctate a la red {{name}}',
        switchNetworkDescription:
          'Asegúrate de estar en la red {{name}} en tu billetera Metamask ({{currency}}). Si no es así, reemplázalo antes de comenzar el proceso.',
        connecting: 'Conectando',
        connected: 'Conectado',
        metamaskGuideTarget: '',
      },
      generic: {
        knowMore: `Sepa mas`,
        save: "Guardar",
        close: "Cerrar",
        retryBtn: "Reintentar nuevamente",
        soon: "¡próximamente!!",
        buy: "COMPRAR",
        cancel: "Cancelar",
        continue: "Continuar",
        errorMsg: "Un error ocurrió",
        contactUs: "Contáctanos",
        share: "Comparte:",
        attention: "¡Atención!",
        goBack: "Volver",
        next: "Siguiente",
        notNow: "Ahora no",
        optional: "Opcional",
        or: "o",
        understood: "Entendido",
        notFound: {
          title: "Recurso no encontrado.",
          actions: {
            backToHome: "Volver a la página principal",
          },
        },
        fieldRequired: "Este campo es obligatorio",
        invalidPhone: "Número de teléfono no válido",
        invalidDate: "Fecha no válida",
        day: "{{count}} día",
        day_plural: "{{count}} días",
        today: "Hoy",
      },
      marketplace: {
        buyOffer: {
          whatIsRarum: "¿Qué es Rarum?",
          details: "Detalles de la compra",
          destinatary: "Destinatario (tú):",
          itemName: "Nombre del elemento:",
          value: "Valor:",
          balanceWarning:
            "Tu saldo en {{currency}} ({{amount}}) es insuficiente",
          boughtItem: "¡Compra realizada con éxito!",
          approveTokens: "Aprobar movimentación de los tokens",
          final: "Confirmar compra",
          title: "Finalizar compra",
          description: "Sigue estos pasos para finalizar tu compra",
          actions: {
            seeItem: "Ver elemento",
            retry: "Intentar otra vez",
            approve: "Aprobar",
            cancel: "Cancelar",
            confirm: "Confirmar",
          },
        },
        assetDetails: {
          available: "{{count}} oferta disponible",
          available_plural: "{{count}} ofertas disponibles",
          offers: "Ofertas",
          lowestPriceOffer: "Oferta más baja",
          marketplaceValue: "Valor de mercado",
          seller: "Vendedor",
          you: "Usted",
          noOffers: "No hay ofertas para la moneda seleccionada ({{currency}})",
          actions: {
            listOffers: "Ver ofertas",
            manageMyITem: "Configurar oferta",
          },
        },
        offerCreation: {
          title: "Anunciar elemento",
          description: "Configura tus datos de venta para activar tu anuncio.",
          actions: {
            backToHome: "Regresar al elemento",
            cancel: "Cancelar",
            confirm: "Confirmar y anunciar",
          },
          sections: {
            preview: {
              title: "Previa del anuncio:",
            },
            type: {
              title: "Tipo de venta",
              direct: "Venta directa",
              auction: "Subasta",
            },
            currency: {
              title: "Moneda y valor del elemento",
              description: "Elige el precio por el que deseas vender.",
              description_plural:
                "Elige la moneda y el precio por el que deseas vender.",
              chooseYourCurrency: "Elegir Moneda",
              defineAmount: "Informar el valor de venta",
              disclaimer: {
                youReceive: "(Tu recebes: {{ amount }})",
                tax: "(Tasa: {{ amount }}%)",
              },
            },
            itemAmount: {
              title: "Cantidad de elementos",
              description: "Ingresa la cantidad del mismo elemento",
            },
            deadline: {
              title: "Período",
              description:
                "Ingresa el período máximo que el anuncio estará activo",
              disclaimer: "mínimo: 1 mes",
              predefined: {
                one_month: "1 mes",
                two_months: "2 meses",
                three_months: "3 meses",
              } as {
                [k in PREDEFINED_DEADLINES]: string;
              },
            },
          },
          creation: {
            error: {
              title: "Oops, su solicitud ha fallado :(",
              description:
                "Por favor, inténtalo de nuevo. Si el error persiste, ayúdanos a mejorar, contáctanos en contact@onepercent.io",
            },
            inProgress: {
              title: "Creando pedido de oferta",
              description:
                "En breve, su anuncio estará listo. ¡Te deseamos éxito en tus ventas!",
              actions: {
                wait: "Espere",
              },
            },
            finished: {
              title: "Pedido de oferta creado",
              description: "Tu anuncio esta listo",
              actions: {
                seeItem: "Ver elemento",
              },
            },
          },
          edition: {
            error: {
              title: "Oops, su solicitud ha fallado :(",
              description:
                "Por favor, inténtalo de nuevo. Si el error persiste, ayúdanos a mejorar, contáctanos en contact@onepercent.io",
            },
            inProgress: {
              title: "Actualizando tu oferta",
              description: "Tu anuncio será actualizado pronto.",
              actions: {
                wait: "Espere",
              },
            },
            finished: {
              title: "Oferta actualizada",
              description: "Tu anuncio será actualizado pronto.",
              actions: {
                seeItem: "Ver elemento",
              },
            },
          },
          removal: {
            error: {
              title: "Oops, su solicitud ha fallado :(",
              description:
                "Por favor, inténtalo de nuevo. Si el error persiste, ayúdanos a mejorar, contáctanos en contact@onepercent.io",
            },
            inProgress: {
              title: "Eliminar anuncio",
              description:
                "¿Está seguro de que desea eliminar este anuncio? Después de la confirmación, la operación no se puede deshacer..",
              actions: {
                wait: "Confirmar y eliminar",
              },
            },
            finished: {
              title: "¡Su anuncio ha sido eliminado con éxito!",
              description:
                "Estamos realizando la transacción. Pronto se completará.",
              actions: {
                seeItem: "Ok",
              },
            },
          },
          confirmation: {
            title: "Finalizar el anuncio",
            description:
              "Siga los siguientes pasos para completar la creación del anuncio",
            configure: "Configure su anuncio",
            approve: "Aprobar Metamask",
            confirm: "Confirmar operación",
            actions: {
              approve: "Aprobar",
              confirm: "Confirmar",
              cancel: "Cancelar creación",
            },
          },
        },
        offerEdition: {
          actions: {
            confirm: "Confirmar y actualizar",
          },
        },
        banner: {
          header: {
            title: "Mercado",
            description:
              "Descubre los artículos a la venta y todas las tarjetas que pueden formar parte de tu colleción",
            connectedWallet: "Billetera conectada",
            actions: {
              connectWallet: "Conectar billetera",
              disconnectWallet: "Desconectar",
              dismiss: "Continuar",
            },
          },
          faq: {
            title:
              "<b>Ver cómo vender</b> los elementos disponibles en su colleción",
            action: "Saber mas",
            targetGuide: "",
          },
        },
        selectUnitsOnSale: "Seleccione la cantidad de elementos",
        unitsOnSale: "{{count}} unidade",
        unitsOnSale_plural: "{{count}} unidades",
        salePrice: "Valor anunciado",
        totalUnits: "unidades: {{units}}",
        remainingUnits: "restantes: {{units}}",
        remainingHighlight: "{{count}} elemento en oferta ahora",
        remainingHighlight_plural: "{{count}} elementos en oferta ahora",
        lowestPrice: "Precio más bajo",
        buyNow: "Comprar ahora",
        filters: {
          order: {
            lowestToHighest: "De menor a mayor",
            highestToLowest: "De mayor a menor",
          },
          assets: {
            showAll: "Todos los packs",
            cardsOnSale: "Packs a la venta",
          },
          currency: "Moneda",
          clear: "Limpiar filtros",
          rarity: {
            label: "Raridad",
            types: {
              COMMON: "Common",
              EPIC: "Epic",
              LEGENDARY: "Legendary",
              RARE: "Rare",
            } as {
              [k in typeof import("core/constants")["RARITIES"][number]]: string;
            },
          },
        },
      },
      highlights: {
        marketplace: {
          title:
            "Ahora podés comprar y vender tarjetas de otros coleccionistas",
          description:
            "Aumenta tu colección, vendé tarjetas duplicadas y completa tu colección.",
          action: "Ver mercado",
        },
        marketplaceSoon: {
          title:
            "Muy pronto podrás comprar y vender tarjetas de otros coleccionistas",
          action: "Avisame",
        },
      },
      app: {
        cookiesConsent: {
          understand: "Entiendo y acepto",
          message:
            "Este sitio web utiliza cookies para mejorar la experiencia del usuario.",
        },
      },
      wallet: {
        connect: "Conectar billetera",
        switchingAccount: {
          warning: "Al cambiar de cuenta, debe iniciar sesión nuevamente.",
        },
      },
      unauthenticated: {
        terms: {
          termOfUse: "Términos de Uso y Política de Privacidad",
          return: "Volver",
        },
        risks: {
          title: 'Aviso de Riesgo',
          description: 'El inversionista declara que:',
          list: [
            'tiene pleno conocimiento de los riesgos asociados con las inversiones realizadas en activos financieros ofrecidos en la Plataforma Oken, así como la posibilidad de pérdida total o parcial de su inversión;',
            'es consciente de que cualquier pérdida sufrida por él como resultado de sus decisiones de invertir o redimir sus inversiones es completamente su responsabilidad;',
            'asume plena responsabilidad ante la Plataforma Oken y terceros por la información proporcionada a la Plataforma, así como por la legitimidad de los documentos presentados, siendo responsable de cualquier daño eventualmente causado a la Plataforma Oken o a terceros debido a la legitimidad de los mismos;',
          ],
        },
        galleryCards: {
          title: "Cards",
          text1:
            "¡Consigue estas cartas a través de los paquetes disponibles en la plataforma!",
          filter: {
            COMMON: "Common",
            RARE: "Rare",
            EPIC: "Epic",
            LEGENDARY: "Legendary",
            all: "All",
          },
          filterNoResult:
            "No se encontró resultados con el filtro seleccionado",
          actions: {
            clearFilters: "Limpiar filtros",
          },
        },
        socialSign: {
          rarumInscription:
            "Al registrarse en Rarum, reconoce que ha leído y está de acuerdo con los términos",
          termOfUse: "Términos de uso y Políticas de Privacidad",
          alert:
            "Este correo electrónico ya se ha utilizado en esta cuenta {{varia}}, inicie sesión en esta plataforma para terminar su sesión",
          continue: "Continuar",
          continueWith: "Continuar con",
          dataConsent:
            "Acepto compartir mis datos personales con {{tenantName, uppercase}} con fines de marketing",
          noAuth: {
            title: "Hola",
            description:
              "Acceda a nuestro sitio utilizando su navegador web para una experiencia completa.",
            goBack: "Volver",
          },
        },
        identification: {
          signIn: "Ingresar",
          signInUsing: "O inicia sesión con:",
          signUp: "Registrarse",
          forgotPassword: "¿Olvidaste tu contraseña?",
          cancel: "Cancelar",
        },
        galleryDetail: {
          backToCards: "Volver a las cartas",
          itemsDetail: "Detalle de artículos",
          description: "Descripción",
          drawnIn: "Dibujado en",
        },
        galleries: {
          description: "Galerías destacadas",
          title: "Accede a las galerías temáticas para descubrir las ofertas",
        },
        drops: {
          list: {
            collectablePackages: "Paquetes coleccionables",
            featuredOffers: "Paquetes destacados",
            featuredDescription:
              "Aquí encontrarás los mejores paquetes para comprar",
            description:
              "Aquí puedes encontrar todos los paquetes disponibles para comprar en la tienda",
            notAvailable:
              "Estos paquetes ya no se encuentran disponibles, pero no te preocupes, pronto podrás encontrar momentos específicos en la tienda",
            previousPackages: "Paquetes anteriores",
            stickyDrop: {
              saleNow: "¡En venta ahora!",
            },
            soonDropItem: {
              comingSoon: "¡Próximamente!",
            },
            dropItem: {
              liveBadge: {
                liveNow: "¡Disponibles ahora!",
              },
            },
          },
          details: {
            info: {
              bookPackage: "Reservar paquete",
              notAvailable:
                "No hay más unidades disponibles para este paquete. Seleccione otro para recolectar más artículos con Rarum.",
              waitEvent: "¡Espera a que comience el evento!",
              ctaLabelBuy: "Comprar",
              ctaLabelApprove: "Aprobar",
              missingProvider:
                "Para comprar este NFT, necesitas una billetera como Metamask. Instale una billetera.",
              approvalFailMessage:
                "No se pudo confirmar su transacción, actualice esta pantalla, verifique su historial de transacciones e intente nuevamente",
              approvalSuccessMessage: "¡Éxito! Ahora puedes comprar este NFT.",
              purchaseFailMessage:
                "No se puede confirmar su transacción, verifique su historial de transacciones antes de volver a intentarlo.",
              purchaseSuccessMessage:
                "¡Éxito! Pronto aparecerá su NFT en la galería, espere unos minutos.",
            },
            availability: {
              packageDetail: "Detalles",
              packageAvailable:
                "Este paquete está disponible a la venta en este momento:",
            },
            packages: "paquetes",
          },
        },
        deletion: {
          return: "Volver",
          remotionInstruction:
            "Instrucciones para eliminar tu información personal de la aplicación",
        },
        connect: {
          createAccount: "Crea tu cuenta en Rarum",
          login: "Iniciar sesión en Rarum",
        },
        faqview: {
          faq: {
            1: "<b>Registrate </b>usando tu cuenta de Google o Facebook.",
            2: `Descubre todas las colecciones disponibles. Cada colección tiene un número determinado de elementos, que varían en cantidad y están organizados en tres niveles de rareza: <b>Hero, Premium y Legend</b>.
            Las cartas se han distribuido y nombrado de acuerdo a su nivel de rareza.`,
            3: `Las cartas se ofrecen de forma aleatoria, a través de paquetes. Elige uno de los paquetes de las distintas colecciones disponibles. Cada paquete contiene una cierta cantidad de cartas aleatorias de ese conjunto. Los paquetes se distinguen por su rareza y deseo. Cuanto más raro, mayor será el deseo de obtención y menor la disponibilidad.`,
            4: `Después de seleccionar un paquete, serás llevado a la pantalla de compra. Elige un método de pago e introduce los datos solicitados. Una vez aprobado el pago, nuestro sistema entrega los NFT a tu colección de forma aleatoria y según la rareza elegida: hero, premium y legend.`,
            5: `Listo, recibirás el paquete en tu colección. Haz clic en "abrir paquete" y revela tus cartas. Ahora es posible verificar su propiedad en blockchain, con transparencia e inmutabilidad. Las cartas se almacenarán en tu colección y pronto podrás intercambiarlas con otros coleccionistas.`,
            accordion: {
              title: {
                1: '¿Qué es un NFT?',
                2: '¿Por qué el NFT es un artículo coleccionable?',
                3: '¿Qué puedo hacer con mi NFT?',
                4: '¿Cuánto vale mi NFT?',
                5: '¿Qué es un token?',
                6: '¿Qué es el criptoarte?',
                7: '¿Qué es una billetera digital de criptoactivos?',
                8: '¿Qué es Blockchain?',
                9: '¿En qué blockchain se crearon los NFT?',
                10: '¿Por qué eligieron {{name}}?',
                11: '¿Cómo verifico mi NFT en la red {{name}}?',
                12: '¿Qué son los metadatos y dónde están siendo almacenadas estas obras de arte digitales?',
                13: '¿Cómo funciona la subasta?',
                14: '¿Cómo funciona la transferencia de NFT? ',
                last: '¿Más dudas?',
              },
              content: {
                1: `El acrónimo NFT significa Non Fungible Tokens o, en español: "tokens no fungibles", es decir, no reemplazables. No se pueden cambiar por otro artículo equivalente. Así, NFT es un tipo de token con la característica de ser único, registrado en una cadena de bloques (Blockchain), representando un activo digital o físico nativo en el mundo digital.

                Aquí hay una diferencia importante. Somos la primera plataforma con NFTs sustentables, es decir, Carbono Neutro. Todas las emisiones de gases de efecto invernadero procedentes de la creación de los NFT son compensados utilizando tokens de carbono de diversos proyectos del ecosistema cripto.
                `,
                2: `Imagina una valiosa obra de arte que se exhibe en un museo famoso.

                Esta obra de arte puede ser vista por miles de visitantes. También es posible tomarle fotografías e incluso comprar reproducciones para exhibirla luego en tu hogar.

                No importa cuántas reproducciones o copias exactas se hagan de la obra, el valor siempre estará en la obra original.

                Esta misma lógica llega al entorno digital con la aparición de los NFT, estos tokens únicos que están revolucionando el mundo de las artes y los apasionados por los coleccionables, ya que permiten certificar la propiedad original del objeto único, ya sea físico o 100% digitales.
                `,
                3: `A partir de esto, las posibilidades son infinitas, pudiendo vender, almacenar, exhibir, garantizando siempre el registro de propiedad desde el Blockchain, dando confianza, trazabilidad, seguridad y transparencia en el proceso.
                <br />
                <br />
                Por ejemplo:
                <br />
                1. Guardarlo como recuerdo.<br />
                2. Regalarlo a un familiar o un amigo.<br />
                3. Venderlo directamente a algún coleccionista de objetos únicos.<br />
                4. Ponerlo a la venta en una de las plataformas de compra y venta de NFT (por ejemplo, Rarum).<br />
                `,
                4: `El valor de un NFT lo dicta básicamente el mercado, no es posible predecir de antemano si este activo digital tendrá un valor monetario en algún momento en el futuro. Dependerá únicamente de la oferta y la demanda del artículo.

                El reciente movimiento del mercado ha presentado grandes oportunidades para los propietarios de coleccionables y NFT de todo el mundo.
                `,
                5: `Desde un punto de vista financiero, un token aplicado a Blockchain es la representación digital de un activo financiero.

                Esta representación puede ocurrir de dos maneras.

                Puede ser un token que representa un activo que tiene una versión en el mundo físico: se denominan “asset-backed tokens”.

                O también pueden ser tokens que no tienen relación con los activos tradicionales a los que estamos acostumbrados y que solo representan un activo 100% digital, como es el caso de este NFT que estamos usando en esta plataforma.
                `,
                6: `Criptoarte es un concepto de obra de arte que tiene su representación en una Blockchain y ha pasado por el proceso de convertirse en token, tal como lo son las criptomonedas para el mercado financiero.

                A partir de la posibilidad de tokenización de activos, surgieron tokens coleccionables que se hicieron famosos a través de las siglas: NFT (Non Fungible Tokens).

                Estos tokens tienen la característica de ser únicos en el entorno digital, por lo tanto, tienen la capacidad de ser también coleccionables.

                El movimiento criptoarte comenzó a ganar cierta relevancia en 2017, pero se ha disparado en los últimos años con la maduración de la tecnología y la aparición de varias plataformas de compra y venta, así como de artistas enfocados en este mercado y consumidores interesados ​​en coleccionar.
                `,
                7: `Las billeteras digitales son aplicaciones en las que los activos pueden ser administrados de manera simple y autónoma por su propietario.

                En este entorno, puedes almacenar tus activos criptográficos, representados aquí como "Mi Colección", o transferirlos a otra billetera de tu propiedad.

                Cuando se trata de activos, tu billetera es el entorno al que debes prestar mucha atención ya que esos activos son tu seguridad de acceso y propiedad.

                Al transferir a otra billetera, le sugerimos que verifique la reputación de la empresa que realiza la intermediación y siga las recomendaciones de seguridad.
                `,
                8: `Blockchain es esencialmente un libro de contabilidad digital de transacciones que se duplica y distribuye a través de toda la red de sistemas informáticos de la cadena. Blockchain es un término que ha sido muy utilizado para referirse a una nueva infraestructura tecnológica de la Web3 (nueva fase de internet) cuyas principales características son: descentralizada, segura y transparente. También hace posible crear e intercambiar activos digitales entre personas de todo el mundo, sin necesidad de un intermediario.

                Esta tecnología permitió la creación de criptomonedas, como por ejemplo Bitcoin.

                En los últimos años, el tema ha ido cobrando cada vez más relevancia con la maduración de otras redes de Blockchain como Ethereum, con la aparición de los smart contracts.

                Los principales usos de estas tecnologías son para soluciones de pago, para la tokenización de activos de diversa índole (obras de arte, acciones, inmuebles, créditos de carbono, entre otros), registro y autenticación de contratos y documentos en general hasta el surgimiento de los NFT, como las que estamos viendo en esta plataforma.
                `,
                9: `Los tokens NFT se crearon en la red {{name}}.`,
                10: '',
                11: `Existen varias herramientas que permiten la verificación de información en una Blockchain, estas herramientas son conocidas como Block Explorer.

                Uno de los más populares en la red {{name}} es <a href="{{explorerUrl}}" target="_blank">{{explorerName}}</a>.

                Al copiar y pegar la dirección de tu NFT en {{explorerName}}, podrás acceder a los metadatos que componen el mismo.
                `,
                12: `Los metadatos son toda la información que describe lo que un activo digital representa.

                Para garantizar el almacenamiento de archivos también de forma descentralizada, utilizamos los servicios de <a href="https://ipfs.io/" target="_blank">IPFS</a> para almacenar tanto el arte en sí como el archivo que contiene dicha información.

                A diferencia de los formatos de almacenamiento en la nube más populares, donde elegimos una sola empresa para que “se encargue” de la custodia de esta información, con IPFS esta custodia también es descentralizada, brindando así más resiliencia y seguridad de la información.

                De esta manera, incluso si el sitio deja de funcionar, siempre puedes tener acceso a tus NFT, siempre y cuando lo hayas canjeado correctamente.
                `,
                13: `Puede acceder a un solo elemento en formato NFT (activo digital) desde la subasta. Sólo hay que seguir los siguientes criterios para el éxito de su oferta:
                El importe de la oferta debe ser superior al incremento mínimo;
                Debe ser inferior al incremento máximo en relación con la última oferta ya realizada, si la hubiera;
                Introducir un número de teléfono móvil válido;
                Enviar documentación que le será solicitada a un contacto del equipo de la plataforma para validar como ganador de la subasta;
                Realizar el pago, eligiendo uno de los formatos disponibles en la plataforma: PIX, tarjeta de crédito o criptomoneda;
                Tras la aprobación de la documentación y el pago. ¡Listo! Recibirá el NFT exclusivo en su Colección.`,
                14: `
                Para los pagos en PIX o criptomoneda, la transferencia estará disponible automáticamente. Para los pagos en tarjeta de crédito, la transferencia sólo estará disponible 30 días después del registro de los datos de la misma tarjeta de crédito. Esto es para garantizar la seguridad de la plataforma y la seguridad de su experiencia con nosotros. Si cambia su tarjeta de crédito, este proceso comenzará a contar de nuevo los 30 días de seguridad del sistema. Para realizar esta operación, deberás seguir los siguientes pasos. ¿Acceder al instructivo?.
                <br/>
                <br/>
                Rellena los campos a continuación con la cantidad de NFTs a transferir, junto con la dirección de tu billetera digital para realizar la transferencia.
                <br/>
                Etapa 1 - Descarga, instala y crea una cuenta de billetera <a href="https://metamask.io" target="_blank">MetaMask</a> en tu móvil, disponible en Play Store, Apple Store o en su computadora;<br/>
                Etapa 2 - Cuando abras tu billetera, encontrarás tu dirección de recepción debajo del nombre de tu cuenta. Copia la dirección. Será usada luego durante el proceso indicado en Rarum.
                <br/>
                <br/>
                Ver tu NFT
                <br/>
                Listo, ya tienes tu NFT en tu billetera digital. Ahora podrá verlo en otra plataforma de su elección.
                `,
                last: `Si necesita más información o tiene preguntas sobre el proceso, haga su solicitud a través del correo electrónico: contact@onepercent.io
                Próximamente, volveremos a dar servicio a través de este canal de comunicación.
                ¡Agradecemos la confianza!
                `,
              },
            },
          },
        },
        claim: {
          soldOff: {
            title: "Producto Agotado",
            description:
              "Lamentamos, pero este producto ha alcanzado el número máximo de redenciones.",
          },
          signup: {
            main: {
              title:
                "<b>Ingrese su mejor correo electrónico</b> para recibir acceso a su NFT exclusivo",
            },
            result: {
              title:
                "<b>You rock!</b><br/>¡Con su participación, usted y cientos más recibieron un NFT exclusivo!",
              description:
                "<b>Ahora solo espera</b><br/>¡Pronto, recibirá instrucciones en su correo electrónico sobre cómo acceder a su NFT exclusivo de Curta Consciente!",
            },
          },
          toClaim: {
            title: "¡Reclama tu NFT!",
          },
          claiming: {
            title: "Reclamando",
          },
          errors: {
            alreadyClaimed: {
              title: "Este artículo ya fue reclamado",
              actions: {
                showItem: "Mostrar en la colección",
              },
            },
          },
          actions: {
            claim: "RECLAMAR",
          },
        },
      },
      authenticated: {
        userProfile: {
          purchases: "{{count}} compra",
          purchases_zero: "Todavía no se han realizado compras",
          purchases_plural: "{{count}} compras",
          digitalWallet: "Cartera digital",
          digitalWalletNotYetConfigured:
            "Cartera no disponible, esperando la primera compra o vinculación con la cartera Metamask",
          profileUpdate: "Actualización de perfil",
          actions: {
            history: "Ver historial",
            seeStore: "Volver a la tienda",
            setup: "Configurar",
            complete: "Completo",
            incomplete: "Incompleto",
            changePersonalData: "Cambiar datos personales",
            changeDocuments: "Cambiar documentos",
            changeInvestorProfile: "Cambiar perfil de inversor",
            changeAdditionalData: "Cambiar datos adicionales",
          },
          sections: {
            personalData: [
              "Mis datos personales",
              "Administre sus datos personales.",
            ],
            myDocuments: [
              "Mis documentos",
              "Administre y actualice sus documentos",
            ],
            investorProfile: [
              "Perfil de inversor",
              "Actualice su perfil de inversor",
            ],
            paymentData: [
              "Datos de pago",
              "Agregue o cambie sus datos de pago e información de la cartera digital.",
            ],
            communication: [
              "Comunicación y privacidad",
              "Administre el uso de sus comunicaciones e información personal.",
            ],
          },
          communication: {
            sections: ["Comunicación", "Privacidad"],
            dataUsage: "Usar datos personales para comunicaciones y publicidad",
            acceptCookies: "Aceptar cookies",
            cancel: {
              title: "Cancelar cuenta",
              description:
                "Cancele su cuenta y elimine su información de la plataforma Rarum",
              actions: {
                cancel: "Cancelar cuenta",
              },
            },
          },
          sucessClear: "¡Sus datos han sido eliminados con éxito!",
          kyc: {
            pending: {
              title: "¡Termine su registro y manténgase seguro!",
              description:
                "Por su seguridad y la de la plataforma, es necesario completar el registro con el paso de reconocer y confirmar su identidad. Haga clic en el botón para continuar.",
            },
            awaiting_metamap: {
              title: "Validando tus datos",
              description:
                "Estamos validando tus datos y pronto podrás participar en las subastas",
            },
            rejected: {
              title: "Ufff, algo salió mal",
              description:
                "Contáctenos usando el botón al costado para verificar lo que sucedió",
            },
            active: {
              title: "Todo está bien con tus datos",
              description:
                "Hemos validado exitosamente tus datos y ahora estás listo para participar en la subasta",
            },
          },
          myData: "Mis datos",
          dataBelow:
            "Los datos a continuación se refieren al titular del medio de pago que se utilizará para realizar las compras en la tienda. La información se puede almacenar aquí para agilizar sus próximas compras.",
          saveData: "Guardar datos",
          deleteData: "Eliminar mi información de pago",
          wantToDelete: "¿Quieres borrar la información de pago?",
          sureDelete:
            "¿Está seguro de que desea borrar todos sus datos de pago? Al realizar su próxima compra, deberá informarles nuevamente.",
          eraseData: "BORRAR INFORMACIÓN DE PAGO",
          cancel: "Cancelar",
          sucess: "¡Su información de pago se ha guardado con éxito!",
          error: "Ha ocurrido un error. Por favor intente de nuevo.",
          profileForm: {
            identification: "Identificación",
            fullName: "Nombre Completo",
            cpf: "ID",
            address: "Dirección",
            cep: "Código Postal",
            state: "Estado",
            city: "Ciudad",
            place: "Lugar",
            number: "Número",
            complement: "Información adicional (opcional)",
            neighbourhood: "Barrio",
          },
        },
        checkout: {
          dropDetails: {
            details: 'Detalles de la compra',
            recipient: 'Destinatario',
            itemName: 'Nombre del artículo',
            value: 'Valor',
            cvm: {
              footer: `Cualquier inversión en pequeñas empresas (startups) conlleva riesgos financieros y desafíos significativos. Las inversiones en startups deben representar solo una pequeña parte de una cartera de inversiones diversificada. Usted corre el riesgo de perder parte o la totalidad del dinero invertido en empresas a través de la plataforma {{company}}. No hay garantía de obtener un retorno de la inversión realizada. Solo invierta en negocios publicados en la plataforma {{company}} si tiene suficiente conocimiento para comprender estos riesgos y tomar sus propias decisiones de inversión. Por favor, consulte nuestro <a href="/risk" target="_blank">Aviso de Riesgo</a> antes de tomar una decisión de inversión.

Las pequeñas empresas y las ofertas presentadas en esta plataforma están automáticamente exentas de registro ante la Comisión de Valores Mobiliarios de Brasil - CVM.
La CVM no revisa las ofertas de antemano.
Las ofertas realizadas no implican, por parte de la CVM, una garantía de la precisión de la información proporcionada, el cumplimiento de la legislación vigente o un juicio sobre la calidad de la empresa de pequeño tamaño.
Antes de aceptar una oferta, lea detenidamente la información esencial sobre la oferta, especialmente la sección de advertencias sobre riesgos.

{{company}} proporciona un canal de comunicación a través de la dirección de correo electrónico <a href="mailto:{{cvmMail}}">{{cvmMail}}</a>, donde su responsable de protección de datos personales recibirá cualquier solicitud, consulta, comunicación y/o manifestación de los titulares de datos personales para ejercer los derechos establecidos en la Ley 13,709/2018 (Ley General de Protección de Datos), de conformidad con su <a href="/terms" target="_blank">Política de Privacidad y Cookies</a>.`,
            },
          },
          continue: "Continuar",
          cancel: "Cancelar",
        },
        myCollection: {
          open: 'Abrir paquete',
          cancel: 'Cancelar',
          timeLeft: 'Tiempo restante:',
          proceed: 'Haz click aquí para proceder con el pago del paquete.',
          clickToOpen: 'Haz click aquí para abrir el paquete',
          clickToClaim: 'Haz click aquí para reclamar el artículo',
          myCollection: 'MI COLECCIÓN',
          accessDetails:
            'Accede y consulta los detalles de los artículos de tu colección.',
          wantToProceed: '¿Desea continuar con la apertura del paquete?',
          wantToProceedWithClaim:
            '¿Quieres continuar con la reclamación de tu artículo?',
          collectionItem: {
            unitsNew: "{{count}} unidade",
            unitsNew_plural: "{{count}} unidades",
            unity: "unidade",
            units: "unidades",
            announcePrice: "Valor anunciado",
            active: "Anuncio activo",
          },
          collectionItemDetails: {
            noBalance: {
              title: "Parece que no tienes este artículo en tu billetera",
              action: {
                backToMyCollection: "Ver mi colección",
              },
            },
            goBack: "Regresar a los paquetes",
            fileFormatImpossibleToView:
              "El formato de archivo no se puede previsualizar. Descargue el archivo para verlo en su dispositivo.",
            download: "Descargar",
            itemDetails: "Detalles del artículo",
            owner: "Propietario",
            description: "Descripción",
            nftInfo: {
              blockchainRecords: "Registro de Blockchain",
              metadate: "IPFS Metadata:",
              wallet: "Billetera de {{nombre}}: ",
            },
            purchaseData: {
              itemDetails: "Detalles del artículo",
              date: "Fecha de compra de paquete:",
            },
            enabledOffer: {
              title: "¡Elemento con anuncio activo!",
              description:
                "¡Atención! Este elemento puede ser vendido en cualquier momento",
              actions: {
                seeOffer: "Ver anuncio",
                options: "Opciones",
                subActions: {
                  edit: "Editar",
                  remove: "Cancelar anuncio",
                },
              },
            },
            sellItem: "Anunciar elemento",
            transferDisabled:
              "Por razones de seguridad, la transferencia de NFT a billeteras externas solo estará disponible 30 días después de la confirmación del pago. Su NFT estará disponible en:",
            sellDisabledExplanation:
              "Elemento no disponible para la venta. Espera el período de análisis de tu última tarjeta de crédito registrada (período equivalente a 30 días desde la última compra con la tarjeta en la plataforma). Una vez que se completa este proceso, puedes vender el elemento.",
          },
          emptyCollection: {
            empty: "Aún no tienes ningún artículo en tu colección.",
            goToStore: "Ir a la tienda",
          },
          filter: {
            showAll: "Todos los cards ({{amount}})",
            cardsOnSale: "Anuncios activos",
          },
        },
        openDrop: {
          close: "Cerrar",
          openNow: "Abrir ahora",
          dropDetails: {
            success: "Compra realizada éxitosamente",
            recipient: "Destinatario (tú).:",
            itemName: "Nombre del artículo:",
            value: "Valor:",
          },
        },
        openingDrop: {
          title1: "Creación del certificado de origen...",
          message1:
            "Esto puede tardar un poco más de lo esperado. Si lo necesita, le sugerimos que vuelva más tarde para comprobar la operación",
          title2: "Compensación de la huella de carbono...",
          message2:
            "Aquí nos preocupamos por la sostenibilidad. Por eso compensamos la huella de carbono de todos los artículos producidos en la tienda",
          title3: "Transmitiendo datos...",
          message3:
            "Puedes ver todos los artículos de tu colección directamente en la blockchain. Si tiene dudas sobre dónde obtener los datos, visite nuestras FAQ o envíanos un email",
          inProgress: {
            exit: "Salir",
            opening: "Paquete de apertura",
            claiming: "Reclamando elemento",
          },
          modals: {
            errorModal: {
              myCollection: "Mi Colección",
              openingError: "Algo salió mal al abrir el paquete.",
              tryAgain:
                "Vuelva a intentarlo más tarde o comuníquese con Rarum a través de contact@rarum.io.",
              openingErrorInfoModal: "Error al abrir el paquete",
            },
            exitModal: {
              confirm: "Confirmar",
              cancel: "Cancelar",
              confirmToExit: "Confirmar para salir apertura del paquete.",
              confirmToExitMessage:
                "Una vez que se complete el proceso de carga, los elementos del paquete estarán disponibles en Mi Colección. ",
              wantExit: "¿Deseas salir?",
            },
            timeOutModal: {
              myCollection: "VOLVER A MI COLECCIÓN",
              waitMore: "Espera un poco más",
              waitingMessage1:
                "Tenga la seguridad de que sus artículos estarán disponibles en su colección en breve.",
              waitingMessage2:
                "¿Por qué puede producirse este retraso? El proceso de apertura se ejecuta en la blockchain y puede variar en función del volumen de transacciones en la red en ese momento. Por favor, espere unos minutos o vuelva pronto a su colección.",
              timeOutMessage:
                "¡Oops! ¡La apertura está tardando más de lo esperado!",
            },
          },
          claimed: {
            claimed: "Su elemento está disponible",
          },
          opened: {
            opened: "Paquete {{ Título paquete }} abierto!",
            checkOut: "Verifica el siguiente artículo:",
            checkOut_plural: "Verifica los siguientes artículos:",
            myCollection: "Mi Colección",
          },
        },
        payment: {
          exitProptMessage: "¿Estás seguro de que deseas cancelar la compra?",
          confirm: "Confirmar",
          cancel: "Cancelar",
          goBack: "Regresar",
          purchaseData: "Datos de la compra",
          fillInTheData:
            "Rellene los datos a continuación para completar la compra",
          confirmTheData: "Confirmar la información.",
          continue: "Continuar",
          problemMessage: "Ha ocurrido un problema.",
          wantExit: "¿Deseas salir?",
          logic: {
            error:
              "Se ha producido un error: compruebe su información o vuelva a intentarlo más tarde",
          },
          data: {
            pix: "Pix",
            creditCard: "Tarjeta de crédito",
            cryptoCoin: " Criptomoneda",
            personalData: "Información Personal",
            revision: "Revisión",
            payment: "Pago",
            personal: "Personal",
            address: "Dirección",
            coinbaseStep: "Coinbase",
            nameIsMandatory: "Nombre es requerido",
            invalidEmail: "Correo electrónico inválido",
            emailIsMandatory: "Correo electrónico es requerido",
            cpf: "ID",
            invalidCpf: "CPF inválido",
            invalidId: "Identificación invalida",
            cep: "Código Postal",
            invalidCep: "CEP inválido",
            stateIsMandatory: "Estado es requerido",
            cityIsMandatory: "Ciudad es requerida",
            patioIsMandatory: "Dirección es requerida",
            numberIsMandatory: "Número es requerido",
            confirmAndPay: "Confirmar y pagar",
            creditCardSubtitle:
              "Rellena los datos de pago para realizar la compra",
            countryIsMandatory: "País es requerida",
            zipIsMandatory: "Zip code es requerida",
          },
          dropInfoMobile: {
            purchaseDetails: "Detalles de la Compra",
          },
          review: {
            dataReview: "Revisar y confirmar la información de compra",
            holderData: "Información del titular del pago:",
            cpf: "ID",
            addressData: "Datos de la Dirección",
            cep: "Código Postal",
            formOfPayment: "Forma de Pago",
          },
          safetyLabel: {
            safePurchase: "Compra segura",
            safePurchaseBy: "Compra segura",
          },
          steps: {
            stepAddress: {
              addressData: "Complete la información del domicilio",
              cep: "Código Postal",
              state: "Estado",
              city: "Ciudad",
              patio: "Calle/Avenida",
              number: "Número",
              nonResident: "No resido en Brasil",
              postalCode: "Código postal",
              country: "País",
            },
            stepMethod: {
              selectFormOfPayment: "Seleccionar método de pago",
              pixBRL: "Pix",
              creditCard: "Tarjeta de crédito",
              creditCardBRL: "Tarjeta de crédito (emitido en Brasil)",
              creditCardUSD: "Tarjeta de crédito",
              creditCardAny: "Credit Card ({{currency}})",
              cryptoBRL: "Criptomonedas (solamente en red Ethereum)",
              cryptoUSD: "Criptomonedas (solamente en red Ethereum)",
              cryptoCoin: "Criptomonedas (solomente red Ethereum)",
            },
            stepReview: {
              dataReview: "Revisar y confirmar la información de compra",
              confirmMessage1:
                "Al confirmar su información, será redirigido a la pantalla de pago.",
              confirmMessage2:
                "En caso de errores en la siguiente información, será necesario rehacer la compra.",
              holderData: "Información del propietario de la compra:",
              cpf: "ID",
              foreignerId: "Identificación",
              edit: "Editar",
              addressData: "Datos del domicilio",
              cep: "Código Postal",
              formOfPayment: "Forma de pago:",
              update: "Actualizar información de registro para futuras compras",
            },
            stepUserData: {
              personalData: "Completa la información personal",
              name: "Nombre",
              cpf: "ID",
              foreignerId: "Identificación",
              foreigner: "Soy extranjero",
            },
          },
        },
        pendingPayment: {
          expiredPayment: "Pago Vencido",
          expiredPurchase: "Lo sentimos, esta compra ha caducado.",
          tryAgain: "Por favor, intente realizar su compra de nuevo.",
          timeLeft: "Tiempo restante",
          myCollection: "Mi Colección",
          problem: "Ha ocurrido un problema",
          payWithPix: "Pagar con Pix",
          payWithCreditCard: "Pagar con Tarjeta de Crédito",
          payWithCryptoCoin: "Pagar con CriptoMoneda",
          data: {
            mandatoryNumber: "Número obligatorio",
            invalidNumber: "Número inválido",
            mandatoryCode: "Código Obligatorio",
            invalidCode: "Código Inválido",
            mandatoryShelfLife: "Se requiere fecha de caducidad",
            invalidShelfLife: "Fecha de caducidad inválida",
            nameIsMandatory: "Se requiere el nombre",
          },
          methods: {
            creditCard: {
              creditCardData:
                "Completa la información de la tarjeta de crédito",
              creditCardNumber: "Número de tarjeta de crédito",
              holderName: "Nombre del propietario",
              securityCode: "Código de seguridad",
              shelfLife: "Fecha de caducidad (mes/año)",
              finishPurchase: "Finalizar compra",
            },
            pix: {
              copyCode: "Copiar código",
              pixCodeCopied: "El código Pix ha sido copiado",
              success: "El código Pix se ha copiado con éxito",
            },
          },
          pixInfo: {
            howToPay: "Cómo pagar con Pix",
            qrCode:
              "Use la aplicación de su banco para escanear el código QR generado o copiar el código.",
            checkOutData:
              "Consulta la información y confirma el pago a través de la aplicación de tu banco o billetera digital.",
            waitingMessage:
              "Si todo está bien, su pago será aprobado en unos momentos.",
          },
        },
        transfer: {
          pendingExternalTransfer: {
            actions: {
              goToTransfer: "Iniciar migración",
            },
            description: `Has conectado tu billetera, pero aún no has migrado tus saldos desde tu cuenta. Para hacerlo, por favor inicia el proceso de migración haciendo clic en el botón de abajo. Recibirás un enlace en tu correo electrónico para confirmar la migración.
Ten en cuenta que todos los saldos de tu cuenta en Rarum serán transferidos, así que por favor verifica si tienes acceso a tu billetera conectada. Una vez transferidos, ya no podremos moverlos en tu nombre.`,
            subtitle: "Aún no ha transferido los saldos a su cuenta",
          },
          errors: {
            amountRequired: "La cantidad es obligatoria",
            invalidAmount: "Cantidad inválida",
            walletRequired: "Se requiere una dirección de billetera",
            invalidWallet: "Billetera no válida",
            unexpected:
              "Ocurrió un error durante el proceso. Vuelva a intentarlo o póngase en contacto con nosotros.",
          },
          title: "Transferir NFT",
          request: {
            ongoing: {
              loading: "Una transferencia esta cerrando, esperá",
            },
            description:
              "Esta opción le permite enviar su NFT a una billetera externa. Esto hará que su artículo salga de Rarum y sea completamente controlado por esa billetera. Complete los campos a continuación u obtenga más información en el siguiente enlace.",
            more: "Obtenga más información sobre la transferencia NFT",
            amount: {
              description:
                "1. Ingrese la cantidad de paquetes que desea transferir",
              label: "Cantidad",
              of: "del",
            },
            wallet: {
              description: "2. Ingrese la dirección de la billetera de destino",
              label: "Billetera",
            },
            warning:
              "AVISO: Para su seguridad, confirme que la billetera de destino acepta tokens estándar ERC-1155 en la red de polígonos. Si se envían tokens a una billetera que no acepta ERC-1155, es posible que pierda el monto enviado.",
            confirm: {
              label:
                "Confirmo que he leído y entendido los riesgos que se muestran arriba",
              modal: {
                title: "Iniciar transferencia NFT",
                description:
                  'Al hacer clic en "Proceder", se enviará un correo electrónico a {{email}}, donde confirmará la transferencia de {{amount}} token{{plural}} a la billetera {{wallet}}.',
                proceed: "Proceder",
                cancel: "Cancelar",
                metamask: {
                  description:
                    'Al hacer clic en "Proceder", deberás aprobar la transferencia en tu proveedor de billetera. Ten en cuenta que esta transferencia requiere GAS y debes tener MATIC en tu billetera para completar la transacción. Una vez confirmado, se transferirán {{amount}} token{{plural}} a la billetera {{wallet}}.',
                  finished:
                    "La transferencia de {{amount}} token{{plural}} para la billetera {{wallet}} ha terminado",
                  actions: {
                    close: "Cerrar",
                  },
                },
              },
            },
            submit: {
              label: "Transferir",
            },
          },
          waiting: {
            title: "Esperando confirmación por correo electrónico",
            description:
              "Se ha enviado un correo electrónico a {{email}} que contiene un enlace para continuar con el proceso de transferencia.",
          },
          confirmation: {
            warning:
              "La transferencia puede tardar unos minutos. No es necesario esperar a la finalización, recibirás un correo electrónico informándote de la finalización del proceso.",
            submit: "Transferir",
            cancel: "Cancelar",
            description: {
              part1:
                "Al hacer clic en Confirmar, se realizará la transferencia solicitada. El proceso puede tardar unos minutos.",
              part2:
                "Los tokens enviados a la dirección se debitarán de su colección una vez que se confirme la transacción en la cadena de bloques.",
              part3: "Esta operación no se puede deshacer.",
            },
          },
          finished: {
            overview: "Su NFT estará disponible en su billetera",
            attention: "Aviso:",
            waitTime:
              "Es posible que el paquete aún tarde unos minutos en ingresar a su cuenta, debido al tiempo de procesamiento de Blockchain (haga clic en",
            here: "aqui",
            forMore: "para saber mas);",
            wheneverAvailable: "Cuando esté disponible, puede verlo en su",
            openseaAccount: "Cuenta OpenSea",
            submit: "Confirmar transferencia",
            finished: "NFT transferido con éxito",
          },
        },
        waitingRoom: {
          descriptionMessage:
            "No pasa mucho tiempo antes de que obtengas el artículo que deseas. Esta sala es un espacio especial para que recibas el enlace de compra tan pronto como esté disponible.",
          waitingRoom: "Sala de espera",
          eventStart: "El evento comenzará a las 02:00 PM",
          lastUpdate: "Última actualización de estado a las: 01:55 PM",
          id: "ID de cola: 785639418e2-40bft-655eea88ad08",
          exit: "Salir",
          media: {
            waitingRoom: "Sala de espera",
          },
        },
      },
      components: {
        offerRequirements: {
          regulation: {
            check:
              "Antes de {{offer_type_action}}, por favor revise el reglamento de esta oferta",
            checkTypes: {
              auction: "participar en la subasta",
              off_chain_auction: "participar en la subasta",
              onchain: "comprar el paquete",
              pack: "comprar el paquete",
              single: "comprar el artículo",
              cvm88: "Invertir",
            },
            action: {
              check:
                "Ya he revisado el reglamento y estoy de acuerdo con las reglas definidas",
              continue: {
                auction: "Continuar a la subasta",
                off_chain_auction: "Continuar a la subasta",
                onchain: "Continuar comprando",
                pack: "Continuar comprando",
                single: "Continuar comprando",
                cvm88: "Continuar con la inversión",
              },
              viewRegulation: "Ver regulación",
            },
          },
        },
        offerPrice: {
          noPrice: "--",
        },
        offerCountdown: {
          remaningTime:
            "Tiempo restante: {{days}}d {{hours}}h {{minutes}}min {{seconds}}s",
        },
        kycActionWrapper: {
          [KYCRequiredStatus.LOADING_KYC]: "Procesando KYC",
          [KYCRequiredStatus.PENDING_KYC]: "Realizar KYC",
          [KYCRequiredStatus.ERROR_KYC]: "KYC falló, contáctenos",
          [KYCRequiredStatus.AUTHENTICATE_REQUIRED]: "Ingresar",
        },
        offerAuctionRace: {
          result: {
            status: {
              [ClaimAuctionStatus.CLAIMABLE]: "Rescatar",
              [ClaimAuctionStatus.NOT_WINNER]: "El artículo ya ha sido vendido",
              [ClaimAuctionStatus.PENDING_ALLOWANCE]: "Aprobar",
              [ClaimAuctionStatus.CLAIMED]: "Ya has canjeado el artículo",
              [ClaimAuctionStatus.DISCONNECTED_WALLET]: "Conectar billetera",
            },
          },
          initialBid: "Primera oferta",
          lastBid: "Última oferta",
          bid: "Hacer una oferta",
          actions: {
            yourLastBid: "Su oferta esta ganando",
          },
        },
        offerAuctionBidList: {
          myBid: "Mi oferta",
          wallet: "billetera",
          totalBids: "Ofertas",
          bids: "Ofertas",
          date: "Fecha",
          user: "Mis ofertas",
        },
        offerAuctionPhoneConfirmation: {
          confirmPhone: "Confirmá tu número de teléfono",
          whyConfirmPhone:
            "Por seguridad, necesitamos verificar tu número de teléfono. Seguí los pasos a continuación para avanzar.",
          phoneInputLabel: "1. Número de teléfono",
          sendCodeViaSMS: "Enviar código SMS",
          informCodeLabel: "2. Insert the verification code",
          whyInformCode:
            "Ingresá el código de verificación que has recibido en tu teléfono",
          errors: {
            invalidConfirmationCode: "Código de verificación incorrecto",
            genericError: "Un error ocurrió",
            action: {
              tryAgain: "Intentar otra vez",
            },
          },
          actions: {
            cancel: "Cancelar",
            validateAndContinue: "Validar y continuar",
          },
        },
        offerAuctionBidModal: {
          bidSettleConfirmation: {
            onChain: {
              disclaimer:
                'Yo aceptó los <a href="/terms" target="_blank">términos y condiciones</a> y estoy dispuesto a probar mi identidad si yo ganar esta subasta',
              errors: {
                insufficientTokenBalance:
                  "El monto {{amount}} es mayor que el monto disponible ({{available}})",
              },
              title: "Hacer una oferta",
              approveTokens: "Aprobar la movimentación de los tokens",
              confirmBid: "Crear oferta",
              actions: {
                approve: "Aprobar",
                confirm: "Confirmar",
              },
            },
          },
          newBid: "Hacer una oferta",
          bidAmount: "Valor de tu oferta",
          instruction:
            "El valor de la oferta debe ser superior al {{min_pct}}% e inferior al {{max_pct}}% de la oferta anterior.",
          phone: "Número de teléfono",
          itemDetail: "Detalles",
          minimumBid: "Oferta mínima",
          tipMinimumAndMaximumBid:
            "Oferta mínima de {{min}} y máxima de {{max}}",
          lowerThanMin: "La oferta no puede ser inferior a ({{min}})",
          biggerThanMax: "Oferta es mayor que ({{max}})",
          required: "Oferta es requerida",
          incorrectPhone: "Teléfono es incorrecto",
          currentBid: "Última oferta",
          initialBid: "Primera oferta",
          confirmBidTitle: "Confirma tu oferta",
          youtBid: "Tu oferta",
          agreeWithTerms:
            "Si. Yo he leído y estoy de acuerdo con todas las reglas de la subasta",
          success: "¡Oferta enviada con éxito!",
          congratulation:
            "¡Felicidades! Podrá ver la evolución de la subasta en la pantalla del elemento.",
          error: "Algo salió mal.",
          tryLater:
            "Hubo un error al procesar tu oferta. Esperá un momento y intentalo de nuevo más tarde.",
          gotIt: "Entiendo",
          continue: "Continuar",
          cancel: "Cancelar",
          errorMap: {
            failed_sequential_bid:
              "Los usuarios no pueden hacer ofertas secuenciales, tienen que esperar a que otro usuario haga uma oferta antes de enviar otra oferta.",
          },
        },
        approvalStatus: {
          approved: "Approved",
          approvedTooltip:
            "The allowance is enough to cover one purchase at least",
          notApproved: "Waiting for approval",
          notApprovedTooltip:
            "Before buying you need to approve (update the allowance)",
        },
        footer: {
          view: {
            sustainableNFT: "NFT Sustentables Coleccionables",
            oneAndMoss: "Hecho con 🤘🏾 por OnePercent.",
            officialProduct:
              "Producto oficial con licencia, todos los derechos reservados.",
          },
          data: {
            collection: 'Colección',
            termOfUse: 'Términos de uso y política de privacidad',
            conductCode: 'Código de Conducta',
            material: 'Material Educativo',
          },
        },
        availability: {
          remaining: "restante",
          units: "unidades",
        },
        topBar: {
          signUp: "REGISTRARSE",
          signIn: "INICIAR SESIÓN",
          myData: "Mis datos",
          myCollection: "Mi Colección",
          marketplace: "Mercado",
          exit: "Salir",
          data: {
            packages: "Paquetes",
            cards: "Cards",
          },
        },
        certificate:
          "Cada artículo en la plataforma tiene su consumo de carbono (emisiones de dióxido de carbono, que son extremadamente contaminantes, generadas durante la producción y entrega de artículos) automáticamente compensado, utilizando Carbon-Pegged Crypto Tokens.",
        rarumHeader: {
          whatIsRarum: "¿Qué es Rarum?",
        },
        chip: {
          chipLabelSoon: "¡Próximamente!",
          chipLabelSale: "¡En venta ahora!",
          chipLabelSaleFinished: "venta finalizada",
          dateScheduled: " {{mes}}, {{día}} at {{horas}}",
          dateExpired: "{{mes}}, {{día}}",
          of: "de",
          at: "en",
        },
        credentials: {
          agree: "Estoy de acuerdo con los",
          serviceTerms: "Términos de servicio",
        },
        dropCard: {
          isOn: "ESTÁ ENCENDIDO",
          details: "Detalles",
        },
        dropUnavailable: {
          notAvailable: "Este paquete ya no está disponible.",
        },
      },
      core: {
        constants: {
          regulament: "",
          rarumDescription: [
            "Rarum es una plataforma de coleccionables digitales que permite a las marcas tener su propio espacio para contactar con su audiencia. Nuestra plataforma gestiona desde la publicación de artículos hasta el proceso de compra que realizan los fans",
            "Cada artículo en la plataforma tiene su consumo de carbono (emisiones de dióxido de carbono, que son extremadamente contaminantes, generadas durante la producción y entrega de artículos) automáticamente compensado, utilizando Carbon-Pegged Crypto Tokens.",
            "Nuestra plataforma también es responsable de la seguridad de tus datos y activos. Es por este motivo, que utilizamos la tecnología de blockchain para garantizar la validez de los artículos negociados en la plataforma. Cada artículo generado corresponde a un registro único, que sirve como una especie de certificado de origen, con una firma criptográfica de la persona que creó cada artículo. De esa manera, puedes estar seguro de que implementamos la última tecnología para garantizar que los datos sean válidos y que las transacciones sean seguras",
            "Si deseas saber más sobre Rarum, contactanos a contact@rarum.io",
          ],
          dataDeletionInstructions: [
            "Si deseas eliminar tus datos de nuestros servidores, puedes hacerlo siguiendo las instrucciones a continuación:",
            '1. Dirigite a “Configuración y Privacidad” > "Configuración";',
            '2. Luego haz click en “Aplicaciones y Sitios Web";',
            "3. Elimina “Rarum” de tus apps;",
            "4. Envía un correo electrónico con el asunto “ELIMINAR MIS DATOS” a contact@onepercent.io desde el correo electrónico con el que creaste tu cuenta. Tus datos serán eliminados de nuestros servidores en 1 día hábil.",
          ],
          externalEnvironmentAlert:
            "Serás redirigido a un enlace externo, desde el proveedor de pagos asociado de Rarum. Sigue las instrucciones para el método de pago seleccionado en la siguiente pantalla.",
          shareText: "Los momentos memorables ahora son coleccionables",
          shareTitle: "Rarum - NFT Coleccionables",
          longDateFormat: "EEEE, dd 'de' MMMM 'às' H:mm",
          scheduledDateFormat: "dd 'de' MMMM 'às' H:mm",
          expiredDateFormat: "dd 'de' MMMM",
        },
      },
    },
  },
};

export { messages };
