import React from 'react'
import View from './AllGalleries.view'
import { useGallery } from 'context/Gallery'
import Footer from 'components/Footer'

export default function AllGalleriesLogic() {
  const { galleries, filter } = useGallery()
  return (
    <>
      <View galleries={galleries} filter={filter} />
      <Footer />
    </>
  )
}
