import { i18n } from 'translate/i18n'
import { useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import { Hidden } from 'augmentation/mui'
import Snackbar from '@material-ui/core/Snackbar'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import clsx from 'clsx'
import { PAGE_TRANSITION_DURATION } from 'core/constants'
import { useOnce } from 'core/helpers/useOnce.hook'
import { analyticsEvent } from 'core/modules/analytics/events'
import ActionButton from 'components/ActionButton'
import BackButton from 'components/BackButton'
import IconRarum from 'components/IconRarum'
import InfoModal from 'components/InfoModal'
import { Action, Location } from 'history'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import DropInfo from './DropInfo'
import DropInfoMobile from './DropInfoMobile'
import { steps } from './Payment.data'
import useStyles, { LineConnector } from './Payment.style'
import { IPaymentInputs, PaymentViewProps } from './Payment.types'
import SafetyLabel from './SafetyLabel'
import StepMethod from './Steps/StepMethod.view'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { CurrenciesEnum } from 'core/types/CurrenciesEnum'
export type { PaymentViewProps } from './Payment.types'

const EXIT_PROMPT_MESSAGE = i18n.t('authenticated.payment.exitProptMessage')

interface ActionsProps {
  onStay: () => void
  onLeave: () => void
}

export enum PaymentTestIDs {
  PAY_BTN = 'pay_drop_btn',
}

// Close modal actions area component
const Actions: React.FC<ActionsProps> = ({ onStay, onLeave }) => {
  const { actionsWrapper, actionButton, cancelButton } = useStyles()
  return (
    <Box className={actionsWrapper}>
      <ActionButton
        text={i18n.t('authenticated.payment.confirm')}
        className={actionButton}
        onClick={onLeave}
      />
      <BackButton
        icon='close'
        text={i18n.t('authenticated.payment.cancel')}
        className={cancelButton}
        onClick={onStay}
      />
    </Box>
  )
}

const PaymentView: React.FC<PaymentViewProps> = ({
  drop,
  onBack: onExit,
  onPay,
  doingPurchase = false,
  purchaseError,
  profileData,
  paymentMethods,
  selectedPaymentMethod,
  onChangePaymentMethod,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  const inputPaneNodeRef = useRef(null)
  const stepNodeRef = useRef(null)

  const [activeIndex, setActiveIndex] = useState(0)
  const [editing, setEditing] = useState(false)

  const [snackOpen, setSnackOpen] = useState(false)
  const [exitConfirmation, setExitConfirmation] = useState(false)

  const { tenant } = useTenant()

  const [formData, setFormData] = useState<IPaymentInputs>({
    userName: profileData?.name || '',
    email: profileData?.email || '',
    cpf: profileData?.cpf || '',
    cep: profileData?.cep || '',
    estado: profileData?.state || '',
    cidade: profileData?.city || '',
    logradouro: profileData?.address || '',
    numero: profileData?.addressNumber || '',
  })

  useOnce(() => {
    if (drop) analyticsEvent.doCheckout({ offer: drop })
  }, !!drop)

  const isOnReview =
    formData.metodo && activeIndex === steps[formData.metodo].length

  const onSubmit = (data: IPaymentInputs) => {
    if (drop && !editing)
      analyticsEvent.checkoutStep({
        step: activeIndex,
        metodo: data.metodo,
        offer: drop,
      })
    const newData = { ...formData, ...data }
    setFormData(newData)
    if (newData.metodo && steps[newData.metodo].length === 0) {
      onPay(newData)
    } else if (!isOnReview) {
      if (formData.metodo && editing) {
        setEditing(false)
        setActiveIndex(steps[formData.metodo].length)
      } else {
        setActiveIndex(activeIndex + 1)
      }
    } else {
      onPay(newData)
    }
  }

  const onBack = useCallback(() => {
    if (activeIndex === 0) {
      setExitConfirmation(true)
    } else {
      setActiveIndex(activeIndex - 1)
    }
  }, [activeIndex])

  const onEdit = (step: number) => {
    // Will not set editing to true in 0, because changing the payment method will change the information required
    if (step > 0) setEditing(true)
    setActiveIndex(step)
  }

  useEffect(() => {
    if (purchaseError) {
      setSnackOpen(true)
    }
  }, [purchaseError])

  const history = useHistory()
  useEffect(() => {
    const unblock = history.block((_location: Location, action: Action) => {
      if (action === 'POP' && !exitConfirmation) {
        if (activeIndex > 0) {
          onBack()
          return false
        } else {
          setExitConfirmation(true)
          return false
        }
      }
      return
    })

    return () => {
      unblock()
    }
  }, [history, activeIndex, onBack, exitConfirmation])

  const backText =
    activeIndex === 0
      ? i18n.t('authenticated.payment.cancel')
      : i18n.t('authenticated.payment.goBack')

  return (
    <>
      <Container className={classes.container}>
        <main style={{ display: 'flex' }}>
          <div className={classes.inputPane}>
            <Box className={classes.spacing}>
              <Typography variant='h2' component='h1'>
                {i18n.t('authenticated.payment.purchaseData')}
              </Typography>
            </Box>
            <Box className={classes.spacing}>
              <Typography variant='body1' component='p'>
                {i18n.t('authenticated.payment.fillInTheData')}
              </Typography>
            </Box>
            <Hidden mdUp>
              <DropInfoMobile drop={drop} />
            </Hidden>
            <SwitchTransition>
              <CSSTransition
                nodeRef={inputPaneNodeRef}
                key={activeIndex === 0 ? 'method' : 'forms'}
                classNames='my-fade'
                timeout={PAGE_TRANSITION_DURATION}>
                <div ref={inputPaneNodeRef}>
                  {activeIndex === 0 ? (
                    <StepMethod
                      paymentMethods={paymentMethods}
                      data={formData}
                      onNext={onSubmit}
                      drop={drop}
                      acceptedCurrencies={
                        tenant?.currencies?.accepted || [CurrenciesEnum.BRL]
                      }
                      selectedPaymentMethod={selectedPaymentMethod}
                      onSelectedPaymentMethod={onChangePaymentMethod}
                    />
                  ) : (
                    <>
                      <Stepper
                        alternativeLabel
                        activeStep={activeIndex - 1}
                        connector={<LineConnector />}>
                        {formData.metodo &&
                          steps[formData.metodo].map(({ title, step }) => (
                            <Step key={`step-${step}`}>
                              <StepLabel>{title}</StepLabel>
                            </Step>
                          ))}
                        <Step key={0}>
                          <StepLabel>
                            {i18n.t('authenticated.payment.data.payment')}
                          </StepLabel>
                        </Step>
                      </Stepper>
                      <div className={classes.formContainer}>
                        <SwitchTransition>
                          <CSSTransition
                            nodeRef={stepNodeRef}
                            key={activeIndex}
                            classNames='my-fade'
                            timeout={PAGE_TRANSITION_DURATION}>
                            <div ref={stepNodeRef}>
                              {formData.metodo &&
                                steps[formData.metodo].map(
                                  ({ step, Component }) => {
                                    return (
                                      activeIndex === step && (
                                        <Component
                                          key={`component-${step}`}
                                          data={formData}
                                          onNext={onSubmit}
                                          onEdit={onEdit}
                                        />
                                      )
                                    )
                                  }
                                )}
                            </div>
                          </CSSTransition>
                        </SwitchTransition>
                      </div>
                    </>
                  )}
                </div>
              </CSSTransition>
            </SwitchTransition>
            <Box
              display='flex'
              flexGrow={1}
              flexDirection='column'
              justifyContent='flex-end'>
              <Box className={classes.buttons}>
                {!editing ? (
                  <BackButton
                    text={backText}
                    onClick={onBack}
                    className={classes.spacing}
                  />
                ) : (
                  <Box />
                )}
                {!doingPurchase ? (
                  <ActionButton
                    disabled={!selectedPaymentMethod}
                    text={
                      isOnReview
                        ? i18n.t('authenticated.payment.confirmTheData')
                        : i18n.t('authenticated.payment.continue')
                    }
                    type='submit'
                    form='payment-form'
                    data-testid={PaymentTestIDs.PAY_BTN}
                    className={classes.spacing}
                  />
                ) : (
                  <Box mr={17.5}>
                    <CircularProgress />
                  </Box>
                )}
                <Hidden mdUp>
                  <SafetyLabel
                    className={clsx(classes.junoLabel, classes.spacing)}
                  />
                </Hidden>
              </Box>
            </Box>
          </div>
          <Hidden smDown>
            <div style={{ maxWidth: 350 }}>
              <DropInfo drop={drop} />
            </div>
          </Hidden>
        </main>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackOpen}
        onClose={() => setSnackOpen(false)}
        message={i18n.t('authenticated.payment.problemMessage')}
        key='snack-error-purchase'
        autoHideDuration={4000}>
        <Alert
          onClose={() => setSnackOpen(false)}
          severity='error'
          classes={{ root: classes.alert }}
          icon={
            <IconRarum
              icon='alert'
              color={theme.palette.error.main}
              size={24}
            />
          }>
          {purchaseError}
        </Alert>
      </Snackbar>
      <InfoModal
        title={i18n.t('authenticated.payment.wantExit')}
        open={exitConfirmation}
        handleClose={() => setExitConfirmation(false)}
        content={EXIT_PROMPT_MESSAGE}
        actions={
          <Actions onLeave={onExit} onStay={() => setExitConfirmation(false)} />
        }
      />
    </>
  )
}

export default PaymentView
