import React, { useMemo } from 'react'
import { DropItemViewProps } from './DropItem.types'
import Styles from './DropItem.module.scss'
import useHero from '@onepercentio/one-ui/dist/hooks/useHero'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { i18n, tO } from 'translate/i18n'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import OfferPrice, { offerPriceParameters } from 'components/offer/OfferPrice'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import Loader from '@onepercentio/one-ui/dist/components/Loader'

const CONTAINER_MDOES = [Styles.expanded, Styles.short, Styles.image]

/**
 * Shows a drop item
 **/
export default function DropItemView(props: DropItemViewProps) {
  const { heroRef } = useHero(
    `drop-${props.drop.id}-${props.heroSuffix || ''}`,
    undefined,
    {
      onHeroDetect: (h) => {
        if (
          document.querySelectorAll('[data-orderableid="orderable-list-clone"]')
            .length
        )
          return false
        return true
      },
      onHeroStart: (clone) => {
        clone.style.margin = '0px'
        const toAdd = Styles[props.mode]
        const toRemove = CONTAINER_MDOES.filter((a) => a !== toAdd)
        for (let cls of toRemove) clone.classList.remove(cls)
        clone.classList.add(toAdd)
      },
    }
  )
  const intl = props.drop[i18n.language as LanguageCodes] || props.drop
  const image =
    'media' in props.drop ? props.drop.media.imageUrl : props.drop.image
  const { currency, price, prices } = useMemo(() => {
    let currency: string | undefined
    let price: number | undefined
    let prices: { [c: string]: number } | undefined
    if ('unitPrice' in props.drop) {
      currency = props.drop.currency
      price = props.drop.unitPrice
      prices = props.drop.prices!
    } else if ('price' in props.drop) {
      currency = Object.keys(props.drop.price)[0]
      price = props.drop.price[currency]!.lowest!
      prices = {
        [currency]: price,
      }
    }
    return {
      currency,
      price,
      prices,
    }
  }, [])
  return (
    <div
      ref={heroRef}
      className={`${Styles.asset} ${Styles[props.mode]}`}
      style={{ backgroundImage: `url(${image})` }}
      onClick={props.onClick}>
      <div className={Styles.content}>
        <div>
          <Text type={FigmaTypo.H4}>
            {'name' in intl ? intl.name : intl.title}
          </Text>
          {prices && (
            <>
              <Text type={FigmaTypo.P16}>
                <OfferPrice
                  {...offerPriceParameters({
                    currency,
                    unitPrice: price,
                    prices,
                  })}
                  size='md'
                  acceptedCurrencies={props.tenant.currencies!.accepted}
                  preferredCurrency={currency as any}
                  mode='text'
                />
              </Text>
              {!props.counters ? null : (
                <UncontrolledTransition
                  transitionType={TransitionAnimationTypes.COIN_FLIP}>
                  {props.counters.remaining === undefined ? (
                    <Loader key='loading' />
                  ) : (
                    <Text key={props.counters.remaining} type={FigmaTypo.P14}>
                      {tO(
                        props.counters.remaining === 0
                          ? 'components.drop.soldout'
                          : 'components.drop.remaining',
                        {
                          count: props.counters.remaining,
                        }
                      )}
                    </Text>
                  )}
                </UncontrolledTransition>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export const BaseExpandedWidth = Number(Styles.baseExpandedWidth)
export const BaseAssetWidth = Number(Styles.baseAssetWidth)
