import React from 'react'
import View from './ChatThreadCreator.view'
import { ChatThreadCreatorProps } from './ChatThreadCreator.types'
import { useChatRoom } from 'context/ChatRoom'
import { useUser } from 'core/logic/user'

export default function ChatThreadCreatorLogic(props: ChatThreadCreatorProps) {
  const { postThread, loading, error } = useChatRoom()
  const { profile, detail } = useUser()
  return (
    <View
      loading={loading}
      error={error}
      onPost={(t) =>
        postThread({
          createdAt: new Date(),
          replies: [],
          text: t,
          user: {
            img: detail!.photoURL! || '',
            name: profile!.name,
          },
        })
      }
    />
  )
}
