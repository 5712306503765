import Styles from 'openspace/assets/styles/TextStyles.module.scss'
import { MenuOptions } from 'openspace/components/TopBar/TopBar.types'
import { ConceptTranslations, OpenspaceIntlShape } from './pt'

const menus: {
  [k in MenuOptions]: string
} = {
  [MenuOptions.ABOUT]: 'ACERCA DE RARUM',
  [MenuOptions.FAQ]: 'FAQ',
  [MenuOptions.GALLERIES]: 'GALERÍAS',
  [MenuOptions.MARKET]: 'MERCADO',
  [MenuOptions.HOW_IT_WORKS]: 'CÓMO FUNCIONA',
  [MenuOptions.BRAND]: 'MARCAS',
  [MenuOptions.CREATORS]: 'CREADORES',
}

const conceptDetails = {
  loyalty_coins: {
    description:
      '¿Quieres tener tu propia moneda y usarla como programa de lealtad para tus usuarios? ¡Esta es la solución perfecta para ti!',
    features: [
      {
        name: 'Moneda Propia',
        description: 'Tu token en formato ERC-20 para lealtad.',
      },
      {
        name: 'Distribución Programada',
        description:
          'Establece criterios para distribuir tokens a tus usuarios.',
      },
      {
        name: 'Integración con Socios',
        description:
          'Permite que tus tokens sean utilizados por socios para brindar beneficios y agregar valor.',
      },
    ],
    cases: [
      {
        title: 'MercadoPago*',
        subtitle: 'Mercado Coin llega al mercado.',
        description:
          'Como parte de su programa de lealtad, MercadoPago lanza su propia moneda. Se distribuye en forma de reembolso a los usuarios, incentivando el uso de la plataforma y permitiendo su uso para comprar productos y operar en el mercado de criptomonedas.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Floyalty%20marcadopago.png?alt=media&token=f756689e-a8f2-44e5-8e25-ab0cf98704eb',
        link: 'https://meubolso.mercadopago.com.br/mercado-coin-criptomoeda-mercado-livre',
        highlights: ['Lealtad', 'Reembolso', 'Comercio Electrónico'],
      },
      {
        title: 'Nubank*',
        subtitle: 'Nucoin revoluciona el mercado.',
        description:
          'Creado para fomentar la lealtad de los usuarios a la plataforma de Nubank, Nucoin funciona como un sistema de reembolso donde cuanto más uses, más ganas. El proyecto incluye beneficios para los titulares de tokens y se desarrolló con una comunidad inicial de 2,000 entusiastas de la marca comprometidos en dar forma al proyecto.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Floyalty%20nubank.png?alt=media&token=856048df-0676-4f2e-9669-d4a7cb89183e',
        link: 'https://blog.nubank.com.br/nubank-cria-nucoin-moeda-digital-propria/',
        highlights: ['Lealtad', 'Banco', 'Reembolso'],
      },
    ],
  },
  marketplace: {
    description:
      'Con un marketplace de tokens, podrás realizar ventas en el mercado primario y secundario de forma sencilla y rápida, integrado con métodos de pago tradicionales como PIX y tarjeta de crédito.',
    features: [
      {
        name: "Galerías de NFT's",
        description: 'Para organizar tus ofertas por temas y áreas de interés.',
      },
      {
        name: 'Mi colección',
        description:
          'Tus usuarios tendrán sus artículos organizados y fáciles de gestionar.',
      },
      {
        name: 'Ventas',
        description:
          'Diversas formas de oferta (paquetes, venta directa, airdrops), métodos de pago, mercado primario (venta directa) y secundario (venta entre usuarios), con prevención de fraudes.',
      },
      {
        name: 'Integración con billeteras',
        description:
          'Los usuarios pueden conectar sus billeteras de criptomonedas al producto y acceder a lo mejor del mundo Web3 desde tu tienda.',
      },
      {
        name: 'Subastas',
        description:
          'Una forma diferente de involucrar a tu audiencia y convertir artículos únicos en elementos de alto compromiso y generadores de ingresos.',
      },
    ],
    cases: [
      {
        title: 'Digital Garage Volkswagen',
        subtitle:
          'Una gran marca acercándose a su público de manera innovadora',
        description:
          'Lanzada en 2022, Digital Garage es un marketplace para la venta de artículos únicos relacionados con la marca Volkswagen. El proyecto ha sido un éxito desde su lanzamiento, con ventas en paquetes y subastas de piezas únicas, tanto físicas como digitales.',
        link: 'https://digitalgaragevw.com',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmarket%20volks.png?alt=media&token=544a59bf-3ec8-4a49-b2ee-ab95bf2a4df4',
        highlights: ["Galería de NFT's", 'Ventas', 'Subastas'],
      },
      {
        title: 'Dia do Batom',
        subtitle: 'Para una fecha especial, innovación y empoderamiento',
        description:
          'Las marcas del Grupo Boticário se unieron al Día del Labial con una campaña en la que cada comprador tendría su propia boca inmortalizada en un arte NFT (física y digital), además de una gran variedad de beneficios como descuentos, contenido exclusivo e incluso una visita a la fábrica.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmarket%20dia%20batom.png?alt=media&token=35797b8e-471e-4e69-bd28-b31a9671a9d0',
        link: 'https://nft.diadobatom.com.br/',
        highlights: ["Galería de NFT's", 'Ventas', 'Subastas', 'Campaña'],
      },
    ],
  },
  membership: {
    description:
      'Utiliza tus tokens como forma de acercar al público y representar la pertenencia a tu comunidad con beneficios exclusivos.',
    features: [
      {
        name: 'Airdrops',
        description:
          'Lanzamiento de tokens para incentivar el registro de nuevos usuarios en la comunidad.',
      },
      {
        name: 'Contenido exclusivo',
        description:
          'Acceso a contenido exclusivo para los miembros de tu comunidad, utilizando el token como clave de acceso a los beneficios.',
      },
      {
        name: 'Eventos en vivo exclusivos',
        description:
          'Contenido exclusivo para los poseedores de tokens en tu plataforma.',
      },
      {
        name: 'Exclusivo para miembros',
        description:
          'Utilizar el token para realizar actividades exclusivas para los miembros, ya sean físicas o presenciales.',
      },
      {
        name: 'Eventos de comunidad',
        description:
          'Mantén a los miembros de tu comunidad actualizados con tu agenda de eventos.',
      },
    ],
    cases: [
      {
        title: 'Kofre Café',
        subtitle: 'Arte y gastronomía en un solo pasaporte',
        description:
          'Con el token de Kofre Café, los miembros del Kofre Club pueden acceder a experiencias únicas, tanto gastronómicas como culturales, junto al Espacio Santander Cultural en la ciudad de Porto Alegre.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmember%20kofre.png?alt=media&token=6e85dcf9-2090-422c-9653-5e6b41695449',
        link: 'https://rarum.io/asset/eolW6ABKYvk9hhpso4Qp',
        highlights: [
          'Participación',
          'Club',
          'Experiencias',
          'Arte',
          'Gastronomía',
        ],
      },
      {
        title: 'Bored Apes*',
        subtitle: 'Un club con una identidad distintiva',
        description:
          'Deseado por celebridades y personalidades de todo el mundo, Bored Apes es un club en el que los poseedores de sus tokens tienen acceso a beneficios exclusivos como productos, contenido y eventos físicos y digitales. Cada miembro es único y el símbolo de cada usuario es una imagen distintiva. El propietario tiene total libertad sobre su acceso y puede comercializarlo en el mercado secundario si lo desea.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmember%20bored.png?alt=media&token=1fc92ace-6c17-4b43-9a91-54ce492c167c',
        link: 'https://boredapeyachtclub.com/',
        highlights: ['Participación', 'Club', 'Experiencias', 'Exclusividad'],
      },
    ],
  },
  social: {
    description:
      'Utiliza tus tokens para fomentar la interacción entre los miembros de tu comunidad, fortalecer el compromiso con tu marca y contenido.',
    features: [
      {
        name: 'Discord',
        description:
          'Utiliza el token como control de acceso a grupos exclusivos en Discord.',
      },
      {
        name: 'Telegram',
        description:
          'Utiliza el token como control de acceso a grupos exclusivos en Telegram.',
      },
      {
        name: 'Twitch',
        description:
          'Convierte el compromiso en transmisiones en vivo de Twitch en fidelización de tu audiencia con recompensas.',
      },
    ],
    cases: [
      {
        title: 'Universus',
        subtitle: 'Un juego con compromiso y recompensas para sus miembros',
        description:
          'El juego Universus combina elementos Play to Earn con una dinámica de juego divertida y contenido exclusivo para los poseedores de tokens en sus canales de Discord.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fsocial%20universus.png?alt=media&token=6b5a0286-4cc9-4ac1-a698-49a5f7e86d31',
        link: 'https://www.universus.gg/',
        highlights: ['Discord', 'Compromiso', 'Juegos'],
      },
      {
        title: 'Bored Apes*',
        subtitle: 'Un club con una identidad distintiva',
        description:
          'Deseado por celebridades y personalidades de todo el mundo, Bored Apes es un club en el que los poseedores de sus tokens tienen acceso a beneficios exclusivos como productos, contenido y eventos físicos y digitales. Cada miembro es único y el símbolo de cada usuario es una imagen distintiva. El propietario tiene total libertad sobre su acceso y puede comercializarlo en el mercado secundario si lo desea.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmember%20bored.png?alt=media&token=1fc92ace-6c17-4b43-9a91-54ce492c167c',
        link: 'https://boredapeyachtclub.com/',
        highlights: ['Compromiso', 'Club', 'Experiencias', 'Exclusividad'],
      },
    ],
  },
  gamification: {
    description:
      'Utiliza tus tokens como mecanismo de incentivo para la participación de los usuarios, con desafíos y recompensas.',
    features: [
      {
        name: 'Activación por código QR',
        description:
          'Enlaces exclusivos que se leen mediante códigos QR para canjear tokens.',
      },
      {
        name: 'Activación de compromiso de aplicaciones',
        description: 'Realiza campañas de compromiso para tu aplicación.',
      },
      {
        name: 'Activación de redes sociales',
        description:
          'Aumenta el compromiso en las redes sociales para recompensar a tus seguidores.',
      },
      {
        name: 'Activación de geolocalización',
        description:
          'Genera tokens basados en la geolocalización de tus usuarios.',
      },
      {
        name: 'Desafíos',
        description:
          'Crea desafíos que, al ser completados, generen recompensas para tus usuarios.',
      },
    ],
    cases: [
      {
        title: 'Universus',
        subtitle: 'Un juego con compromiso y recompensas para sus miembros',
        description:
          'El juego Universus combina elementos Play to Earn con una dinámica de juego divertida y contenido exclusivo para los poseedores de tokens en sus canales de Discord.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fsocial%20universus.png?alt=media&token=6b5a0286-4cc9-4ac1-a698-49a5f7e86d31',
        link: 'https://www.universus.gg/',
        highlights: ['Discord', 'Compromiso', 'Juegos'],
      },
      {
        title: 'Uptrip*',
        subtitle: 'Viajar puede ser aún mejor con beneficios exclusivos',
        description:
          'La startup Uptrip creó una solución para fomentar la participación de viajeros y aerolíneas con tokens que reconocen sus acciones, fidelizan y brindan beneficios durante el vuelo, como Wi-Fi, área VIP y mejoras de asiento.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fgamification%20uptrip.png?alt=media&token=be884c8f-6c1d-41a9-b5e1-c0cd112c7b16',
        link: 'https://www.uptrip.app/',
        highlights: ['Compromiso', 'Incentivos', 'Beneficios'],
      },
    ],
  },
  smart_tickets: {
    description:
      'Ahora tus boletos son NFT que ofrecen experiencias físicas y digitales de forma sencilla y segura. Desde el boleto hasta las activaciones dentro del evento, para fomentar el compromiso.',
    features: [
      {
        name: 'Boletos NFT primarios y secundarios',
        desctiption:
          'Venta de boletos para usuarios y entre usuarios, con rendimiento y seguridad.',
      },
      {
        name: 'Airdrops de eventos',
        description:
          'Emite tokens para incentivar la participación del público en tu evento.',
      },
      {
        name: 'Solución de acceso al evento',
        description:
          'Controla el acceso a tu evento utilizando el boleto inteligente.',
      },
      {
        name: 'Prueba de asistencia',
        description:
          'Emite certificados de participación personalizados como prueba de asistencia a tu evento.',
      },
    ],
    cases: [
      {
        title: 'Simple Music',
        subtitle: '¡Salve simpatía! El evento es tuyo.',
        description:
          'El proyecto Simple Music creó tokens que dan acceso a un concierto del artista Jorbe Ben Jor, uno de los artistas más celebrados de Brasil. Los boletos se comercializaron en grupos de 5, 20 y 40 unidades.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fsmart%20simple.png?alt=media&token=b10bcb83-fed6-40b1-a51e-b569384cc41b',
        link: 'https://rarum.io/g/Xc1SgS4szJNFtgNJ3PMe',
        highlights: ['Eventos', 'Exclusivo', 'Financiamiento colectivo'],
      },
      {
        title: 'Ticketmaster*',
        subtitle: 'Beneficio para el fan y para el artista',
        description:
          'El gigante de los eventos, Ticketmaster, lanzó una funcionalidad que permite que lotes de boletos se comercialicen exclusivamente para los propietarios de tokens de los artistas. Esto permite que el tradicional modelo de club de fans ahora se vuelva digital y el compromiso brinde aún más beneficios para los miembros.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fsmart%20ticketmaster.png?alt=media&token=703edb10-c089-42e7-9d12-ebcf4079299d',
        link: 'https://business.ticketmaster.com/business-solutions/nft-token-gated-sales/',
        highlights: ['Eventos', 'Exclusivo', 'Club de fans'],
      },
    ],
  },
  digital_twins: {
    description:
      'Las posibilidades de conectar elementos físicos y digitales son infinitas. Crea experiencias únicas alrededor de tu producto y marca para involucrar al público.',
    features: [
      {
        name: 'Mercado de productos físicos y digitales NFT',
        description:
          'Venta de productos físicos y digitales en el mercado primario y secundario.',
      },
      {
        name: 'Código QR',
        description:
          'A través de un código QR en tu producto físico, el usuario será llevado a una experiencia digital única.',
      },
      {
        name: 'Solución de etiquetado NFC',
        description:
          'Con un toque del teléfono en tu producto, los usuarios pueden acceder a experiencias digitales únicas relacionadas con tu producto.',
      },
    ],
    cases: [
      {
        title: 'Digital Garage Volkswagen',
        subtitle:
          'Un automóvil digital que te abre las puertas a un momento histórico',
        description:
          'La última generación del querido automóvil de Brasil, el Gol, tuvo una serie especial en la que una de sus pocas unidades fue subastada en su plataforma digital y el ganador de la subasta recibió el vehículo y su registro en blockchain en forma de un NFT, con arte exclusivo del equipo de diseño de Volkswagen y el derecho de conocer la fábrica.',
        link: 'https://digitalgaragevw.com',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmarket%20volks.png?alt=media&token=544a59bf-3ec8-4a49-b2ee-ab95bf2a4df4',
        highlights: ["Galería de NFT's", 'Ventas', 'Subasta'],
      },
      {
        title: 'Dia do Batom',
        subtitle: 'Para una ocasión especial, innovación y empoderamiento',
        description:
          'Las marcas del Grupo Boticário abrazaron el Día del Labial con una campaña en la que cada comprador tendría su propia boca inmortalizada en un arte NFT (físico y digital), además de una infinidad de beneficios como descuentos, contenido exclusivo e incluso una visita a la fábrica.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Ftwins%20dia%20batom.png?alt=media&token=01a656b9-f187-4c25-88e3-f647c51d3098',
        link: 'https://nft.diadobatom.com.br/',
        highlights: ["Galería de NFT's", 'Ventas', 'Subasta', 'Campaña'],
      },
    ],
  },
  shared_ownership: {
    description:
      'Crea modelos de participación en tus negocios y ganancias para la comunidad utilizando tus tokens.',
    features: [
      {
        name: 'Activos fraccionables',
        description:
          'Ofrece ítems únicos en ofertas fraccionadas que generen ganancias para los usuarios.',
      },
      {
        name: 'Distribución de resultados',
        description:
          'Genera recompensas con tus tokens a los usuarios en función del rendimiento de tus activos.',
      },
      {
        name: 'Conexión DeFi',
        description:
          'Crea conexiones para modelos de Finanzas Descentralizadas (DeFi) utilizando nuestra solución.',
      },
    ],
    cases: [
      {
        title: 'Moss Amazônia',
        subtitle: 'Únete al movimiento para proteger la selva amazónica',
        description:
          'En un modelo de venta de NFT en el que cada unidad equivale a una parte de un área de preservación en la selva, Moss permite que su comunidad participe en acciones de protección del "pulmón del mundo" de forma sencilla y segura.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fshared%20moss.png?alt=media&token=31bf7dcd-00bb-4047-9249-d21b71be8b72',
        link: 'https://nft.moss.earth/',
        highlights: ['Preservación', 'Amazonia', 'ESG'],
      },
      {
        title: 'Royal*',
        subtitle: 'La marca de ropa que une a su comunidad',
        description:
          'Royal es una marca de ropa urbana que utilizó una emisión de tokens para fortalecer su relación con la comunidad y fomentar su participación, con beneficios exclusivos como descuentos, colecciones y acceso a eventos. La pre-venta de los tokens agotó su inventario en línea en menos de 2 horas.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fshared%20royal.png?alt=media&token=ddad3e35-1326-4154-903f-4089326f47cb',
        link: 'https://rarum.io/g/nbwPjvfyKfxL6peccTwE',
        highlights: ['Participación', 'Exclusividad', 'Marca'],
      },
    ],
  },
}

const concepts: ConceptTranslations = {
  marketplace: {
    title: 'Marketplace',
    description: 'Una plataforma a medida para tu empresa',
    features: [
      'Galería NFT',
      'Mi colección',
      'Ventas',
      'Integración con billetera',
      'Subastas',
    ],
  },
  membership: {
    description:
      'fomente el compromiso de su comunidad con las nuevas tecnologías',
    title: 'Membership',
    features: [
      'Lanzamientos aéreos',
      'Contenido privado',
      'Eventos en vivo exclusivos',
      'Solo miembros',
      'Eventos comunitarios',
    ],
  },
  social: {
    description: 'Presencia estructurada en nuevos canales',
    title: 'Social',
    features: [
      'Canales de discordia',
      'Grupos de telegramas',
      'Integración de Twitch',
    ],
  },
  gamification: {
    description: 'gamifica la relación con tu comunidad',
    title: 'Gamification',
    features: [
      'Activación de código QR',
      'Activación de interacción con la aplicación',
      'Activación de redes sociales',
      'Activación de geocercas',
      'Desafíos',
    ],
  },
  smart_tickets: {
    description: 'nuevos espacios para su comunidad y acciones de membresía',
    title: 'Smart Tickets',
    features: [
      'Entradas NFT primarios y secundarios',
      'Mercado',
      'Lanzamientos aéreos de eventos',
      'Solución de acceso a eventos',
      'Prueba de asistencia',
    ],
  },
  digital_twins: {
    description:
      'Generar nuevas formas de ingresos a partir de activos físicos',
    title: 'Digital Twins',
    features: [
      'NFT + mercado de productos físicos',
      'Código QR',
      'Solución de etiquetado NFC',
    ],
  },
  shared_ownership: {
    description: 'Innova y comparte la propiedad de tu activo',
    title: 'Shared Ownership',
    features: [
      'activos fraccionables',
      'Distribución de resultados',
      'Conexión DeFi',
    ],
  },
  loyalty_coins: {
    description:
      'Fomente el compromiso de las campañas y acciones de participación lideradas por su comunidad',
    title: 'Loyalty Coins',
    features: [
      'Moneda propia',
      'Distribución programada',
      'Integración con socios',
    ],
  },
}

const messages: {
  'es-ES': OpenspaceIntlShape
} = {
  'es-ES': {
    translations: {
      forCreators: {
        brand: {
          description:
            'En Rarum, tu marca puede involucrar y retener a tu audiencia de manera innovadora, creando comunidades activas y generando nuevas fuentes de ingresos.',
          sections: {
            loyalty: {
              proposal: [
                '<b>Ventajas únicas de Web3:</b> permiten a los usuarios comerciar fácilmente las monedas de lealtad entre ellos o cambiarlas por monedas de otras marcas/coaliciones, aumentando la liquidez y el valor de su moneda',
                'Monetiza las relaciones de las marcas con sus clientes',
                'Conectar fácilmente con socios',
              ],
            },
            nftGames: {
              proposal: [
                '<b>Ventajas únicas de Web3:</b> los contratos inteligentes permiten configurar desafíos con mayor flexibilidad y automatizar su ejecución, reduciendo el costo de activar la base de usuarios',
                'Capa de gamificación para ganar (desafíos de la marca)',
                'Intercambio de NFT en la plataforma con otros usuarios (+ transacciones recurrentes)',
                'Lista de beneficios canjeables dentro de la marca utilizando colecciones de NFT ganados/adquiridos',
              ],
            },
            certificate: {
              proposal: [
                '<b>Ventajas únicas de Web3:</b> el registro en la blockchain permite una autenticación sólida del producto y la trazabilidad de los eventos durante el ciclo de vida del producto',
                'Registro de autenticidad para ventas de productos y durante el ciclo de vida del producto con monitoreo de servicios (por ejemplo, revisión periódica, llamadas de seguimiento, mantenimiento) y valor de reventa',
                'Conexión entre productos físicos y digitales a través del uso de sensores NFC y/o códigos QR',
              ],
            },
          },
        },
        creators: {
          description:
            'Los creadores de contenido tienen una solución integral en Rarum para transformar el compromiso de su audiencia en ingresos, tanto a través de sus productos como de sus colaboraciones.',
          sections: {
            web3FanClub: {
              proposal: [
                '<b>Ventajas únicas de Web3:</b> la verificación de la propiedad de los NFT es una solución confiable para controlar el acceso a los beneficios de la comunidad y permite a los usuarios revender su token de membresía o vender beneficios específicos por separado, manteniendo su token de membresía',
                'Monetiza las relaciones de los influencers con sus bases de fans',
                'Venta de acceso con membresía limitada en el tiempo (por ejemplo, 1 año) que otorga acceso a una comunidad cerrada y contenido exclusivo',
              ],
            },
            creator: {
              proposal: [
                '<b>Ventajas únicas de Web3:</b> la tokenización de activos permite dividir la propiedad de los activos financieros de los creadores en unidades más pequeñas que pueden ser adquiridas por fanáticos e inversores',
                'Pago adelantado de ganancias futuras a los creadores, con el costo de financiamiento más bajo: sus propios fans',
                'Venta de NFT que representan regalías sobre flujos futuros de efectivo y beneficios del "fan club"',
              ],
            },
          },
        },
        mainSolutions: 'SOLUCIONES PRINCIPALES',
        valueProposition: 'PROPUESTA DE VALOR',
        coreModules: 'MÓDULO PRINCIPAL',
      },
      forum: {
        postedAt: 'Publicado hace {{time}}',
        noItems:
          '¿Tienes preguntas? \nUtiliza el campo de arriba y sé el primero en preguntar',
        post: {
          placeholder: 'Escribe tu pregunta aquí',
          cancelConfirmation: '¿Quieres descartar el mensaje escrito?',
          actions: {
            cancel: 'Cancelar',
            post: 'Publicar',
          },
        },
        actions: {
          reply: 'Responder',
        },
      },
      offerDetails: {
        goal: 'Meta',
        minInvestment: 'Inversión mínima',
        goalReached: '{{percent}}% de la meta alcanzada',
        goalReached_0: 'No hay cuotas reservadas en este momento',
        goalReached_beyond: 'Meta alcanzada',
        limit: 'Límite',
        timeRemaining: 'TIEMPO RESTANTE',
        offer: 'OFERTA',
        preMoneyValuation: 'VALORACIÓN PREVIA',
        companyParticipation: 'participación de la empresa',
        sections: {
          ABOUT: 'Acerca de la Inversión',
          DOCUMENTS: 'Documentos',
          KPI: 'Indicadores clave de rendimiento (KPI)',
          FOUNDERS: 'Fundadores',
          FORUM: 'Foro',
        },
        forum: {
          description:
            'Accede al foro para conversar y hacer preguntas con otros inversores y expertos',
          posts: 'Publicaciones',
          actions: {
            access: 'Acceder al Foro',
          },
        },
        actions: {
          invest: 'Invertir',
          seeProfile: 'Ver perfil en {{social}}',
          moreInfo: 'Más información',
        },
      },
      featureDetails: {
        useCases: 'Casos de Uso',
        more: 'Otros Módulos',
        highlights: 'DESTACADOS',
        actions: {
          visitCase: 'Visitar el caso',
        },
        demo: {
          title: 'Agenda una demostración y <b>conoce más sobre rarum</b>',
          actions: {
            schedule: 'Agendar una demostración',
          },
        },
        details: conceptDetails,
      },
      components: {
        drop: {
          remaining: 'Último artículo disponible',
          remaining_plural: '{{count}} artículos restantes',
          soldout: 'Todos los artículos han sido vendidos',
        },
        benefits: {
          title: 'Beneficios',
          subtitle: 'Consulta y gestiona tus beneficios',
          claim: 'Reclamar',
          claiming: 'Reclamando beneficio',
          expired: 'Beneficio expirado',
          claimed: 'Beneficio reclamado',
          expireIn: 'Expira en:',
          days_plural: '{{count}} días',
          days: '{{count}} día',
          remaining: '{{count}} artículo disponible',
          remaining_plural: '{{count}} artículos disponible',
          claimConfirmation: {
            title: 'Reclamar beneficio',
            actions: {
              claim: 'Reclamar beneficio',
            },
            success: {
              title: 'Beneficio reclamado!',
              description:
                'Próximamente recibirá instrucciones y más información sobre este reclamo en su correo electrónico registrado {{email}}',
              actions: {
                understood: 'Entiendo',
              },
              subtitle:
                '¿Está seguro de que desea reclamar este beneficio? <br/>Esta acción no se puede deshacer.',
              pending:
                'Esta transacción puede tardar unos minutos. Una vez que se confirme el canje, recibirá instrucciones y más información en su correo electrónico registrado <b>{{email}}</b>',
            },
            details: {
              title: 'Detalle de beneficio',
              details: 'Más detalles',
              actions: {
                claim: 'Reclamar beneficio',
              },
            },
          },
        },
      },
      generic: {
        noItemsFound: 'No se encontraron artículos',
        brandMotto: 'Esto es raro. Este es Rarum.',
        knowMore: 'Sepa mas',
      },
      home: {
        forCreators: {
          heading: 'PARA QUIÉN ES RARUM',
          title: 'Nuestras soluciones se adaptan a tu perfil',
          tabs: ['Creador', 'Marca'],
          profile: 'PERFIL DEL CLIENTE',
          sections: {
            brand: {
              loyalty: {
                title: 'Loyalty Coins',
                profile: [
                  'Las marcas que deseen crear su propia moneda para recompensar a sus clientes leales.',
                ],
              },
              nftGames: {
                title: 'Engage to Earn',
                profile: [
                  'Las marcas que buscan agregar experiencias de gamificación a su programa de lealtad, aumentando la participación de los usuarios y el valor de por vida',
                ],
              },
              certificate: {
                title: 'Certificado de Producto',
                profile: [
                  'Marcas con bienes duraderos para el consumidor que buscan extender el compromiso vinculado al producto (fabricantes de automóviles, electrónica, venta minorista de lujo)',
                ],
              },
            },
            creators: {
              web3FanClub: {
                title: 'Club de Fans Web3',
                profile: [
                  'Instagrammer',
                  'Influencer Digital',
                  'Periodista',
                  'Streamer',
                ],
              },
              creator: {
                title: 'Club de Inversionistas Creadores',
                profile: ['Músico', 'Compositor', 'Youtuber', 'Escritor'],
              },
            },
          },
          actions: {
            knowMore: 'Saber Más',
            clickToKnowMore: 'Haga clic para más información',
          },
        },
        banner: {
          v2: {
            brand: 'rarum',
            brandMotto: 'Community Engagement as a Service',
            brandBenefit: 'Valoramos lo que es raro: tu comunidad.',
            actions: {
              features: 'Descubre nuestra plataforma Whitelabel',
            },
          },
          title: `ACTIVOS DIGITALES <br/><span class="${Styles.gradient}">SUSTENTABLES Y PARA <br/>TODOS</span>`,
          subtitle:
            'Tenga un mercado de tokens personalizado para su comunidad',
          action: {
            create: '¡Crea tu tienda!',
          },
        },
        sections: {
          highlights: {
            title: 'Ofertas abiertas',
          },
          contractModel: {
            section: 'CÓMO FUNCIONA',
            title: 'Puedes contratar Rarum en dos formatos:',
            subtitle:
              'Habla con nuestro equipo y descubre cuál formato funciona mejor para tu negocio.',
            card: 'Habla con nuestro equipo y descubre cuál formato funciona mejor para tu negocio.',
            models: {
              platform: {
                title: 'Plataforma White Label',
                subtitle:
                  'Una interfaz amigable para tus usuarios, que abstrae las complejidades de Web3 e integra los mercados primario y secundario, así como métodos de pago.',
              },
              api: {
                title: 'API',
                subtitle:
                  'La solución ideal para integrar tu aplicación o plataforma con Web3 de forma rápida y segura.',
              },
            },
            actions: {
              contact: 'Contactar',
            },
          },
          features: {
            title: 'QUÉ OFRECEMOS',
            description: 'convertimos el compromiso en negocio',
            benefit:
              'Desarrollamos un conjunto de módulos de producto que se combinan para crear el modelo de participación y monetización que mejor se adapte a tus usuarios y tu negocio.',
            actions: {
              schedule: 'Programar una demostración',
            },
            concepts,
            mainFeatures: 'CARACTERÍSTICAS PRINCIPALES',
          },
          believers: {
            title: 'Quien cree con Rarum',
            description:
              'marcas que ya han convertido <b>Likes en ROI</b> con Rarum',
          },
          brand: {
            title: '100% Carbon Neutral',
            description: `Compensamos todas las emisiones de carbono de la plataforma y los activos negociados.

Rarum también opera en blockchains que son eficientes en sus emisiones de gases de efecto invernadero.`,
          },
          faq: {
            title: 'Frequently asked questions',
            description:
              'Las preguntas más frecuentes de Clientes y Marcas que ya han utilizado los servicios de Rarum.',
            questions: [
              {
                question: '¿Qué es una plataforma Whitelabel?',
                answer:
                  'Una plataforma Whitelabel es aquella en la que puede personalizar la experiencia de usar el producto con su marca e identidad visual. Así funciona Rarum, ¡las mejores facciones con tu cara!',
              },
              {
                question:
                  '¿Cuál es el tiempo de personalización de una Tienda?',
                answer:
                  'Este es un proceso realmente rápido, con una lista de verificación organizada de acciones, en unas pocas semanas, su tienda estará activa y disponible para su comunidad.',
              },
              {
                question:
                  '¿Cómo puedo involucrar a mi audiencia utilizando tokens?',
                answer:
                  'Antes de implementar su tienda, nuestro equipo le presentará un marco de compromiso para usted y su audiencia utilizando tokens. Esta metodología es el resultado de un extenso trabajo de referencia realizado con más de 27 plataformas existentes y más de 100 criterios de evaluación.',
              },
              {
                question: '¿En qué red blockchain se generan mis tokens?',
                answer:
                  'Nuestra plataforma es compatible con todas las redes compatibles con el estándar Ethereum Virtual Machine (EVM), que incluye redes como Ethereum, Polygon, Celo y ¡muchas otras!',
              },
              {
                question:
                  '¿Cuáles son los métodos de pago admitidos en la plataforma?',
                answer:
                  'Admitimos el pago a través de PIX, Tarjeta de Crédito y Criptomonedas (USDC).',
              },
              {
                question:
                  '¿Es necesario conocer sobre criptomonedas para usar Rarum?',
                answer:
                  'Con Rarum, el acceso al mundo Web3 es muy sencillo. Nuestra solución es capaz de gestionar toda la complejidad de la cadena de bloques para su marca y su audiencia, sin requerir el uso de billeteras o criptomonedas para interactuar. Sin embargo, los usuarios más avanzados pueden conectar sus billeteras y utilizar sus criptomonedas si así lo desean.',
              },
            ],
          },
          gallery: {
            heading: 'Descubre las Galerías Rarum',
            title: 'Últimas galerías',
            action: {
              seeAll: 'Ver todas las galerías',
            },
          },
          latest: {
            title: 'Tokens destacados',
            action: {
              seeAll: 'Ver todos',
            },
          },
          store: {
            title: 'Tiendas',
          },
          create: {
            title:
              '¡Crea tu propria galería o tienda y empieza a vender tus tokens!',
            actions: {
              knowMore: 'Sepa mas',
            },
            highlight: {
              create: 'Crea tus tokens',
              easy: 'Fácil de comprar y vender',
              secure: 'Seguro, transparente y carbon free',
            },
          },
          partnership: {
            title: 'Neutralidad de Carbono',
            description:
              'En nuestra búsqueda de prácticas más sostenibles, hemos reducido significativamente nuestras emisiones de carbono e implementado tecnologías blockchain de bajo impacto ambiental.',
            actions: {
              knowMore: 'Sepa mas',
            },
          },
          news: {
            title: 'Artículos',
          },
          contactUs: {
            success: `<span class="${Styles.gradient}">Contacto enviado<br/>Ahora solo espera</span>`,
            title: 'Contacto',
            description:
              'Complete este formulario y discutamos cómo podemos ayudar a su negocio',
            form: {
              title: 'Habla con Rarum',
              description:
                'Rellene los campos a continuación y, si es posible, explíquenos el motivo de su contacto. Gracias por su interés.',
            },
            v2: {
              title: '¿Vamos a crear caminos <b>con rarum?</b>',
              description: 'Sigue a Rarum en las redes sociales de OnePercent',
              actions: {
                schedule: 'Programar una demostración',
              },
            },
          },
        },
      },
      allGalleries: {
        title: 'GALERÍAS',
        sections: {
          highlight: {
            title: 'Galerías destacadas',
          },
          createYourOwn: {
            title:
              'Crea tu propria galería o tienda y empieza a vender tus tokens?',
            action: {
              knowMore: 'Sepa mas',
            },
          },
        },
      },
      myCollection: {
        actions: {
          seeAllGallery: 'Mostrar todos',
          hideAllGallery: 'Volver a la lista',
        },
      },
      galleryDetails: {
        noMarket: 'Artículos de la galería',
        noAvailable: `Sigue visitando!
Luego tendremos nuevas ofertas en esta galería`,
        actions: {
          seeAllMarketplace: 'Ver todo en el mercado',
          seeAll: 'Ver todo los artículos',
        },
        expiredItems: 'Artículos cerrados',
        market: 'Artículos de mercado',
        items: 'Tokens',
      },
      topbar: {
        menus,
        auth: {
          login: 'Iniciar sesión',
          signup: 'Crea tu cuenta',
        },
      },
    },
  },
}

export default messages
