import { Benefit, ClaimableBenefit } from 'components/Benefits/Benefits.types'
import { ERC721ABI } from 'core/constants/ERC721'
import {
  BaseAssetType,
} from 'core/logic/asset/asset.types'
import IMyCollectionFacade, {
  BalancesOfType,
  FacadeTypes,
} from 'service/IMyCollectionFacade'
import { functions } from 'core/modules/firebase'
import { i18n } from 'translate/i18n'
import Web3 from 'web3'
import { ContractType } from 'core/logic/contract/types/types'

export default class ERC721OnchainFacade extends IMyCollectionFacade {
  nftContractForRead!: Contract.ERC721

  constructor(
    nftContractAddress: string,
    web3ForRead: Web3,
    web3ForWrite: Web3
  ) {
    super(nftContractAddress, FacadeTypes.BENEFITS, web3ForRead, web3ForWrite)

    this.nftContractForRead = new this.web3ForRead.eth.Contract(
      ERC721ABI,
      this.contractAddress
    ) as any
  }
  async claimBenefit(benefit: ClaimableBenefit) {
    await functions.httpsCallable('assets-redeem')({
      tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
      assetId: benefit.assetId,
      benefit: benefit.code,
      amount: 1,
      tokenId: String(benefit.tokenId),
      language: i18n.language,
    })
  }
  async getOwnedItems(): Promise<BalancesOfType<ContractType.Loyalty>[]> {
    const nftBalancesByWallet = await Promise.all(
      this.wallets.map((wallet) =>
        this.nftContractForRead.methods.balancesOf(wallet).call()
      )
    )
    const CIDsToConsider: Set<string> = new Set()
    const balanceMap: { [cid: string]: string[] } = {}
    const tiersCIDs = await this.nftContractForRead.methods.tierList().call()

    for (let nftBalances of nftBalancesByWallet) {
      Object.assign(
        balanceMap,
        nftBalances.tokenIds.reduce((r, tokenId, index) => {
          const tierId = nftBalances.tiers[index]
          const tierCID = tiersCIDs[Number(tierId)]
          const currentTokens = r[tierCID] || []
          return {
            ...r,
            [tierCID]: [...currentTokens, tokenId],
          }
        }, {} as { [cid: string]: string[] })
      )
      for (let cid of Object.keys(balanceMap)) CIDsToConsider.add(cid)
    }
    const CIDs = Array.from(CIDsToConsider)

    return CIDs.reduce(
      (r, a) => [
        ...r,
        ...balanceMap[a].map(
          (tokenId) =>
            ({
              type: ContractType.Loyalty,
              balance: 1,
              cid: a,
              tokenId: Number(tokenId),
            } as BalancesOfType<ContractType.Loyalty>)
        ),
      ],
      [] as BalancesOfType<ContractType.Loyalty>[]
    )
  }
  async getBenefits(asset: BaseAssetType) {
    const attributeDetails = asset.attributes_extension!.reduce(
      (r, attr) => ({
        ...r,
        [attr.trait_type]: attr,
      }),
      {} as {
        [k: string]: NonNullable<typeof asset.attributes_extension>[number]
      }
    )
    const attributes = await Promise.all([
      this.nftContractForRead.methods.attributes(String(asset.tokenId)).call(),
    ])

    const benefits: (ClaimableBenefit & {
      benefitKey: string
    })[] = []

    for (let benefitMap of attributes) {
      for (let [attributeName, attributeAmount] of benefitMap) {
        const existingBenefit = benefits.find(
          (b) => b.benefitKey === attributeName
        )
        if (!existingBenefit)
          benefits.push({
            benefitKey: attributeName,
            available: Number(attributeAmount),
            description:
              attributeDetails[attributeName][i18n.language as LanguageCodes]
                .description,
            title:
              attributeDetails[attributeName][i18n.language as LanguageCodes]
                .title,
            details:
              attributeDetails[attributeName][i18n.language as LanguageCodes]
                .details_html,
            assetId: asset.id,
            code: attributeName,
            tokenId: asset.tokenId!,
          })
        else existingBenefit.available += Number(attributeAmount)
      }
    }

    return benefits as Benefit[]
  }
}
