import React from 'react'
import View from './ChatThread.view'
import { ChatThreadProps } from './ChatThread.types'
import { useChatRoom } from 'context/ChatRoom'
import { useUser } from 'core/logic/user'

export default function ChatThreadLogic(props: ChatThreadProps) {
  const { reply, loading, error } = useChatRoom()
  const { profile, detail } = useUser()
  return (
    <View
      {...props}
      loading={loading}
      error={error}
      onReply={(replyInfo) => {
        return reply({
          text: replyInfo,
          threadId: props.id,
          user: {
            img: detail!.photoURL! || '',
            name: profile!.name,
          },
          createdAt: new Date(),
        })
      }}
    />
  )
}
