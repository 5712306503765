/// <reference path="../../../utility.d.ts"/>
/// <reference path="../../../globals.d.ts"/>

import { ComponentProps, PropsWithChildren, useMemo } from 'react'
import { t } from 'translate/i18n'
import OneText from '@onepercentio/one-ui/dist/components/Text'

/**
 * Utility to show text on screen
 **/
export default function Text({
  ...textProps
}: Pick<
  ComponentProps<typeof OneText>,
  'type' | 'style' | 'className' | 'color'
> &
  (
    | {
        code: Parameters<typeof t>[0]
        format?: Parameters<typeof t>[1]
      }
    | PropsWithChildren<{}>
  )) {
  const content = 'code' in textProps ? t(textProps.code) : textProps.children
  const propsToSpread = useMemo(() => {
    return {
      ...textProps,
      code: undefined
    }
  }, [textProps])
  return <OneText {...propsToSpread}>{content}</OneText>
}
