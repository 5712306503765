import { useEffect, useMemo, useRef, useState } from 'react'
import { MyCollectionViewProps } from './MyCollection.types'
import { GalleryType, HeadlessGallery } from 'core/logic/gallery/gallery.types'
import { AssetWithBalance } from 'core/logic/asset/asset.types'
import {
  BaseAssetMargin,
  BaseWidth,
  GalleryThumbnail,
} from 'openspace/components/GalleryCard/GalleryCard.view'
import OrderableList from '@onepercentio/one-ui/dist/components/OrderableList'
import { i18n, tO } from 'translate/i18n'
import CollectionItem from 'pages/Authenticated/MyCollection/CollectionItem'
import PaginableContainer from 'openspace/components/PaginableContainer'
import { useLocalPagination } from '@onepercentio/one-ui/dist/hooks/usePagination'
import useOrder from 'openspace/hooks/useOrder'
import useElementFit from '@onepercentio/one-ui/dist/hooks/useElementFit'
import { SectionView } from 'openspace/components/Section/Section'
import Button from '@onepercentio/one-ui/dist/components/Button'
import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer'
import Container from 'openspace/components/Container'
import { useOrderableEvents } from '@onepercentio/one-ui/dist/components/OrderableList/OrderableList'
import useLocalStorage from '@onepercentio/one-ui/dist/hooks/persistence/useLocalStorage'
import EmptyCollection from 'pages/Authenticated/MyCollection/EmptyCollection'
import { MyCollectionTestIds } from './MyCollection.e2e'
import { DeliveryType } from 'core/logic/delivery/delivery.types'
import { PendingPurchase } from 'core/logic/purchase/purchase.types'
import SectionLoader from 'openspace/components/SectionLoader/SectionLoader'
import FooterLogic from 'components/Footer/Footer.logic'
import Styles from './MyCollection.module.scss'

/**
 * The list of owned collections
 **/
export default function MyCollectionView({
  galleries,
  ...props
}: MyCollectionViewProps) {
  const order = useOrder('my-collection-galleries')
  const { galleryIds, assetByGallery } = useMemo(() => {
    const galleryMap = [
      ...(props.closedCollection || []),
      ...(props.pending || []),
      ...(props.openedCollection || []),
    ].reduce(
      (r, assetOrPurchaseOrDelivery) => {
        function returnGalleryMapWithAsset(galleryId?: string) {
          return {
            ...r,
            [galleryId! || 'default']: [
              ...(r[galleryId! || 'default'] || []),
              assetOrPurchaseOrDelivery,
            ],
          }
        }
        if ('type' in assetOrPurchaseOrDelivery) {
          if (assetOrPurchaseOrDelivery.type === 'airdrop') {
            return returnGalleryMapWithAsset(
              assetOrPurchaseOrDelivery.asset!.galleryId
            )
          }
        }
        const galleryId =
          'galleryId' in assetOrPurchaseOrDelivery
            ? assetOrPurchaseOrDelivery.galleryId
            : assetOrPurchaseOrDelivery.offer.galleryId
        return returnGalleryMapWithAsset(galleryId)
      },
      {} as {
        [g: GalleryType['id']]: (
          | DeliveryType
          | PendingPurchase
          | AssetWithBalance
        )[]
      }
    )

    return {
      galleryIds: Object.keys(galleryMap).filter((a) => a !== 'default'),
      assetByGallery: galleryMap,
    }
  }, [props.openedCollection, props.pending, props.closedCollection])

  const defaultCollection = assetByGallery.default || []

  const onlyExistingGalleriesIds = useMemo(
    () => galleryIds.filter((id) => galleries?.find((g) => g.id === id)),
    [galleries, galleryIds]
  )
  const isLoading =
    (galleries || undefined) === undefined ||
    (props.openedCollection || undefined) === undefined

  const gallerySections = useMemo(() => {
    const galleriesBoundSections = onlyExistingGalleriesIds.map((galleryId) => {
      const gallery = galleries!.find((g) => g.id === galleryId)!
      return (
        <GalleryWrapper
          key={gallery.id}
          gallery={gallery}
          onClickAsset={props.onClickAsset}
          opened={assetByGallery[gallery.id]}
        />
      )
    })

    return galleriesBoundSections
  }, [assetByGallery, defaultCollection, galleries, onlyExistingGalleriesIds])

  return (
    <>
      {isLoading ? (
        <SectionLoader data-testid={MyCollectionTestIds.LOADER} />
      ) : !onlyExistingGalleriesIds.length && !defaultCollection.length ? (
        <Container className={Styles.root}>
          <EmptyCollection data-testid={MyCollectionTestIds.EMPTY} />
        </Container>
      ) : (
        <OrderableList
          className={Styles.root}
          keyOrder={order.order}
          onChangeKeyOrder={order.onChangeOrder}
          data-testid={MyCollectionTestIds.ASSETS}
          shrinkToOnOrder={36 * 1.25}>
          {gallerySections}
        </OrderableList>
      )}
      <FooterLogic />
    </>
  )
}

function GalleryWrapper({
  gallery,
  opened,
  onClickAsset,
}: {
  gallery?: GalleryType | HeadlessGallery
  opened: (AssetWithBalance | DeliveryType | PendingPurchase)[]
  onClickAsset: (a: AssetWithBalance | DeliveryType | PendingPurchase) => void
}) {
  const sectionRef = useRef<HTMLDivElement>(null)
  const isSectionCollapsable = gallery !== undefined && 'icon' in gallery
  const [showAll, setShowAll] = useLocalStorage(
    `my-col-${gallery?.id || 'default'}-state`,
    isSectionCollapsable ? false : true
  )
  const [tempHide, setTempHide] = useState(false)
  const expanded = showAll && !tempHide

  const AssetsListInstance = useMemo(
    () =>
      !expanded
        ? AssetsListFactory(onClickAsset)
        : ({
            assets,
          }: {
            assets: (AssetWithBalance | DeliveryType | PendingPurchase)[]
          }) => <AssetsList assets={assets} onClickAsset={onClickAsset} />,
    [onClickAsset, expanded]
  )
  useOrderableEvents(
    useMemo(
      () => ({
        'order-start': () => setTempHide(true),
        'order-stop': () => setTempHide(false),
      }),
      []
    )
  )

  const salt = useMemo(() => Date.now(), [expanded])

  return (
    <>
      <SectionView
        disableHover
        disableOrdering={isSectionCollapsable === false}
        title={
          gallery && 'icon' in gallery ? (
            <>
              <GalleryThumbnail
                icon={gallery.icon}
                className={Styles.thumbnail}
              />
              {gallery[i18n.language as LanguageCodes].title}
            </>
          ) : (
            <></>
          )
        }
        className={Styles.sectionRoot}>
        <Container ref={sectionRef} className={Styles.container}>
          <>
            {opened.length && (
              <AdaptiveContainer
                className={Styles.collectionContainer}
                direction='v'>
                <AssetsListInstance key={salt} assets={opened} />
              </AdaptiveContainer>
            )}

            {isSectionCollapsable && (
              <Button
                variant='transparent'
                onClick={() => {
                  if (showAll) {
                    const scrollable =
                      document.querySelector('#scrollable')!.firstElementChild!
                    scrollable.scrollTo({
                      top: sectionRef.current!.parentElement!.parentElement!
                        .parentElement!.parentElement!.parentElement!.offsetTop,
                      behavior: 'smooth',
                    })
                    setTimeout(() => {
                      setShowAll(false)
                    }, 500)
                  } else {
                    setShowAll(true)
                  }
                }}>
                {tO(
                  expanded
                    ? 'myCollection.actions.hideAllGallery'
                    : 'myCollection.actions.seeAllGallery'
                )}
              </Button>
            )}
          </>
        </Container>
      </SectionView>
    </>
  )
}

function AssetsList({
  assets,
  onClickAsset,
}: {
  assets: (AssetWithBalance | DeliveryType | PendingPurchase)[]
  onClickAsset: (
    asset: AssetWithBalance | DeliveryType | PendingPurchase
  ) => void
}) {
  return (
    <div className={Styles.fullList}>
      {assets.map((asset) => (
        <div
          key={`a-${asset.id} t-${'tokenId' in asset ? asset.tokenId : ''}`}
          className={Styles.collectionItem}>
          <CollectionItem
            showStickyTop={false}
            assetOrDeliveryOrPurchase={asset}
            onClick={() => onClickAsset(asset)}
          />
        </div>
      ))}
    </div>
  )
}

function AssetsListFactory(
  onClickAsset: (
    asset: AssetWithBalance | DeliveryType | PendingPurchase
  ) => void
) {
  return function AssetsList({
    assets,
  }: {
    assets: (AssetWithBalance | DeliveryType | PendingPurchase)[]
  }) {
    const { ref, itemsToShow = 1 } = useElementFit(BaseWidth)
    const paginable = useLocalPagination(assets, itemsToShow * 3)
    useEffect(() => {
      paginable.getPage(0)
    }, [itemsToShow, assets])

    return (
      <PaginableContainer
        ref={ref}
        src={paginable}
        jsx={{
          width: BaseWidth + BaseAssetMargin,
          factory: (
            asset: AssetWithBalance | DeliveryType | PendingPurchase
          ) => (
            <div
              className={Styles.collectionItem}
              key={`a-${asset.id} t-${
                'tokenId' in asset ? asset.tokenId : ''
              }`}>
              <CollectionItem
                showStickyTop={false}
                assetOrDeliveryOrPurchase={asset}
                onClick={() => onClickAsset(asset)}
              />
            </div>
          ),
        }}
      />
    )
  }
}
