import React, { Fragment, useMemo, useState } from 'react'
import { ChatThreadCreatorViewProps } from './ChatThreadCreator.types'
import Styles from './ChatThreadCreator.module.scss'
import Avatar from '@onepercentio/one-ui/dist/components/Avatar/Avatar'
import { useUser } from 'core/logic/user'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import Input from '@onepercentio/one-ui/dist/components/Input'
import { t, tO } from 'translate/i18n'
import Button from '@onepercentio/one-ui/dist/components/Button'
import SharedStyles from '../ChatThread/ChatThread.module.scss'
import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer/AdaptiveContainer'
import Loader from '@onepercentio/one-ui/dist/components/Loader/Loader'

/**
 * Creates a thread and uploads
 **/
export default function ChatThreadCreatorView({
  onPost,
  loading,
  error,
  focused: staticFocusState,
  onCancel,
}: ChatThreadCreatorViewProps) {
  const { profile, detail } = useUser()

  const [text, setText] = useState('')
  const [_focused, setFocused] = useState(false)
  const focused = staticFocusState === undefined ? _focused : staticFocusState
  function clear() {
    setFocused(false)
    setText('')
  }

  const [top, bottom] = useMemo(() => {
    if (focused) {
      return [
        <Fragment key='focused'>
          <div className={SharedStyles.row}>
            <Avatar name={profile!.name} imgSrc={detail!.photoURL!} size={44} />
            <div>
              <OneText type={FigmaTypo.H4}>{profile!.name} </OneText>
            </div>
          </div>
          <br />
        </Fragment>,
        <Fragment key='focused'>
          <div className={SharedStyles.row}>
            <Button
              variant='transparent'
              onClick={() => {
                if (onCancel) onCancel()
                if (text.trim()) {
                  const confirmation = window.confirm(
                    tO('forum.post.cancelConfirmation')
                  )
                  if (confirmation) clear()
                } else {
                  clear()
                }
              }}
              color='primary'>
              {tO('forum.post.actions.cancel')}
            </Button>
            <Button
              disabled={loading || text === ''}
              variant='filled'
              onClick={() => {
                onPost(text).then(() => {
                  clear()
                })
              }}
              color='primary'>
              {loading ? <Loader /> : tO('forum.post.actions.post')}
            </Button>
          </div>
        </Fragment>,
      ]
    } else {
      return [<Fragment key='empty' />, <Fragment key='empty' />]
    }
  }, [focused, text, loading, error])

  return (
    <>
      <div className={`${Styles.root} ${focused ? Styles.focused : ''}`}>
        <AdaptiveContainer direction='v'>{top}</AdaptiveContainer>
        <Input
          error={error ? t('generic.errorMsg') : undefined}
          disabled={loading}
          onFocus={() => setFocused(true)}
          onBlur={({ target: { value } }) => {
            if (!value.trim()) setFocused(false)
          }}
          onChange={({ target: { value } }) => setText(value)}
          value={text}
          placeholder={tO('forum.post.placeholder')}
          multiline={2}
        />
        <AdaptiveContainer direction='v'>{bottom}</AdaptiveContainer>
      </div>
    </>
  )
}
