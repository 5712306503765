export const FigmaTypo = {
  H1: 'boldTitle',
  H2: 'boldTitleBig',
  H4: 'subtitle',
  H3: 'title',
  P14: 'caption',
  P16: 'content',
  P12: 'p12',
  P10: 'p10',
} as const

export const Typo = {
  v2_H1: 'title_v2',
  v2_P20: 'p20_v2',
  v2_P24: 'p24_v2',
  v2_P30: 'p30_v2',
  v2_P40: 'p40_v2',
  small: 'small',
  smallBold: 'smallBold',
  h3heading: 'h3heading',
  h2: 'h2',
  p16: 'p16',
  h4: 'h4',
  h3: 'h3',
} as const
