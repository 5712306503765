import { logger } from '../helpers'
import { i18n } from '../../translate/i18n'
import { StableTokenData } from 'core/logic/token/token.types'
import { detect, Browser, detectOS } from 'detect-browser'
import { Chains } from 'context/Chain'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const __DEV__ =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

export const CVM_EMAIL = process.env.REACT_APP_CVM_MAIL

export const DELIVERY_TYPE = {
  TRANSFER: 0,
  MINT: 1,
  PACK: 2,
}

export const SIGNUP_CLAIM: {
  portalId: string
  formId: string
} = JSON.parse(process.env.REACT_APP_CLAIM_FORM_CONFIG || '{}')

export const CONTACT_US: {
  portalId: string
  formId: string
} = JSON.parse(process.env.REACT_APP_CONTACT_FORM_CONFIG || '{}')

export const METAMAP_CONFIG: {
  clientId: string
  flowId: string
} = JSON.parse(process.env.REACT_APP_METAMAP_CONFIG || '{}')

if (process.env.NODE_ENV !== 'test') {
  if (process.env.REACT_APP_PRODUCTION === 'false')
    logger(`[ RARUM ] Warning: REACT_APP_PRODUCTION is set to 'false'.`)
  if (!process.env.REACT_APP_PRODUCTION)
    logger(`[ RARUM ] Warning: REACT_APP_PRODUCTION is not set.`)
  if (process.env.REACT_APP_FIREBASE_CONFIG === undefined)
    logger(
      `[ RARUM ] Warning: REACT_APP_FIREBASE_CONFIG is undefined. Using default config.`
    )
}
export const JUNO_PUBLIC_TOKEN = process.env.REACT_APP_JUNO_PUBLIC_TOKEN

export const JUNO_LIB_URL = process.env.REACT_APP_JUNO_LIB_URL

export const REACT_APP_STRIPE_API_KEY =
  process.env.REACT_APP_STRIPE_API_KEY || ''
export const REACT_APP_COINBASE_ORIGIN =
  process.env.REACT_APP_COINBASE_ORIGIN || ''

export const PRODUCTION = process.env.REACT_APP_PRODUCTION === 'true'

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY

export const LS_REDIRECT_URI = 'redirect_uri'

export const LS_PENDING_CREDENTIAL = 'pending_credential'

export const PAGE_TRANSITION_DURATION = 300

export const PACK_TYPE = 'pack'
export const SINGLE_TYPE = 'single'

export const FIREBASE_CONFIG = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG!
)

export const RARUM_DESCRIPTION = i18n.t('core.constants.rarumDescription', {
  returnObjects: true,
}) as unknown as String[]

export const DATA_DELETION_INSTRUCTIONS = i18n.t(
  'core.constants.dataDeletionInstructions',
  { returnObjects: true }
) as unknown as String[]

export const EXTERNAL_ENVIRONMENT_ALERT = i18n.t(
  'core.constants.externalEnvironmentAlert'
)

export const BROWSER = detect()!.name as Browser

export const chainConfig: { [key in Chains]: ChainConfig } = process.env
  .AUTOMATION
  ? {
      POLYGON: {
        id: 1337,
        name: 'Localhost',
        currency: 'MATIC',
        explorerUrl: 'https://localhost:404',
        explorerName: 'Localhost fake explorer',
        providerAddress: '',
        rpcUrl: '',
        stableTokens: [
          {
            symbol: 'USDC',
            address: '',
            decimals: 6,
          },
        ],
        gasFeeEndpoint: undefined,
      },
      MOONBEAM: {
        id: 1337,
        name: 'Localhost',
        currency: 'MATIC',
        explorerUrl: 'https://localhost:404',
        explorerName: 'Localhost fake explorer',
        providerAddress: '',
        rpcUrl: '',
        stableTokens: [
          {
            symbol: 'USDC',
            address: '',
            decimals: 6,
          },
        ],
        gasFeeEndpoint: undefined,
      },
    }
  : process.env.REACT_APP_PRODUCTION === 'true'
  ? {
      POLYGON: {
        id: Number('0x89'),
        name: 'Polygon',
        currency: 'MATIC',
        explorerUrl: 'https://polygonscan.com',
        explorerName: 'Polygonscan',
        providerAddress:
          'https://polygon-mainnet.g.alchemy.com/v2/x9WRmpIz0BhGayr3su79N_jRP_PYAsQO',
        rpcUrl: 'https://polygon-rpc.com',
        stableTokens: [
          {
            symbol: 'USDC',
            address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            decimals: 6,
          },
        ],
        gasFeeEndpoint: 'https://gasstation.polygon.technology/v2',
      },
      MOONBEAM: {
        id: Number('0x504'),
        name: 'Moonbeam',
        currency: 'GLMR',
        explorerUrl: 'https://moonscan.io',
        explorerName: 'Moonbase',
        providerAddress: 'https://1rpc.io/2q16bQSq6pmNvR7pd/glmr',
        rpcUrl: 'https://rpc.moonbeam.network',
        stableTokens: [],
        gasFeeEndpoint: undefined,
      },
    }
  : {
      POLYGON: {
        id: Number('0x13881'),
        name: 'Mumbai',
        currency: 'MATIC',
        explorerUrl: 'https://mumbai.polygonscan.com',
        explorerName: 'Polygonscan',
        providerAddress:
          'https://polygon-mumbai.g.alchemy.com/v2/P5_aVGUW34FSeQssOR7XeMkPyeDUkExf',
        rpcUrl: 'https://rpc-mumbai.maticvigil.com',
        stableTokens: [
          {
            symbol: 'USDC',
            address: '0x8b649099F4e3F5c5C032eeA961AB85048C1C4509',
            decimals: 18,
          },
        ],
        gasFeeEndpoint: 'https://gasstation-testnet.polygon.technology/v2',
      },
      MOONBEAM: {
        id: Number('0x507'),
        name: 'Moonbase',
        currency: 'DEV',
        explorerUrl: 'https://moonbase.moonscan.io',
        explorerName: 'Moonbase',
        providerAddress: 'https://rpc.testnet.moonbeam.network',
        rpcUrl: 'https://rpc.testnet.moonbeam.network',
        stableTokens: [],
        gasFeeEndpoint: undefined,
      },
    }

export type Stable = {
  symbol: string
  address: string
  decimals: number
}

export type ChainConfig = {
  id: number
  name: string
  currency: string
  explorerUrl: string
  explorerName: string
  providerAddress: string
  rpcUrl: string
  stableTokens: Stable[]
  gasFeeEndpoint: string | undefined
}

export const UF = [
  { nome: 'Acre', sigla: 'AC' },
  { nome: 'Alagoas', sigla: 'AL' },
  { nome: 'Amapá', sigla: 'AP' },
  { nome: 'Amazonas', sigla: 'AM' },
  { nome: 'Bahia', sigla: 'BA' },
  { nome: 'Ceará', sigla: 'CE' },
  { nome: 'Distrito Federal', sigla: 'DF' },
  { nome: 'Espírito Santo', sigla: 'ES' },
  { nome: 'Goiás', sigla: 'GO' },
  { nome: 'Maranhão', sigla: 'MA' },
  { nome: 'Mato Grosso', sigla: 'MT' },
  { nome: 'Mato Grosso do Sul', sigla: 'MS' },
  { nome: 'Minas Gerais', sigla: 'MG' },
  { nome: 'Pará', sigla: 'PA' },
  { nome: 'Paraíba', sigla: 'PB' },
  { nome: 'Paraná', sigla: 'PR' },
  { nome: 'Pernambuco', sigla: 'PE' },
  { nome: 'Piauí', sigla: 'PI' },
  { nome: 'Rio de Janeiro', sigla: 'RJ' },
  { nome: 'Rio Grande do Norte', sigla: 'RN' },
  { nome: 'Rio Grande do Sul', sigla: 'RS' },
  { nome: 'Rondônia', sigla: 'RO' },
  { nome: 'Roraima', sigla: 'RR' },
  { nome: 'Santa Catarina', sigla: 'SC' },
  { nome: 'São Paulo', sigla: 'SP' },
  { nome: 'Sergipe', sigla: 'SE' },
  { nome: 'Tocantins', sigla: 'TO' },
]

export const STABLE_TOKENS: Array<StableTokenData> = [
  {
    symbol: 'USDC',
    address: process.env.REACT_APP_STABLE_USDC_ADDRESS || '',
    decimals: parseInt(process.env.REACT_APP_STABLE_USDC_DECIMALS || '0'),
  },
]

export const SHARE_FACEBOOK_APP_ID = '485160149428159'
export const SHARE_TITLE = i18n.t('core.constants.shareTitle')
export const SHARE_URL = 'https://rarum.io'
export const SHARE_TEXT = i18n.t('core.constants.shareText')
export const RARUM_NAME = 'Rarum'

export { ERC20ABI } from './ERC20ABI'
export { ERC1155ABI } from './ERC1155ABI'
export { RARUMSALESABI, RAW_RARUMSALESABI } from './RARUMSALESABI'

export const RARITIES = ['COMMON', 'RARE', 'EPIC', 'LEGENDARY'] as const
export const SIGNATURE_TTL_SECONDS = 3600
export const SOCIAL_LINKS = {
  instagram: {
    title: 'Instagram',
    link: 'https://www.instagram.com/onepercentio',
  },
  linkedin: {
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/company/onepercentio',
  },
  twitter: {
    title: 'Twitter',
    link: 'https://twitter.com/OnePercentIO',
  },
}
export const PLATFORM = (() => {
  const os = detectOS(window.navigator.userAgent)!
  if (os.toLowerCase().includes('windows')) return 'windows'
  if (os.toLowerCase().includes('linux')) return 'linux'
  else return os
})()
