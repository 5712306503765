import _i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { ReactElement } from 'react'
import { initReactI18next } from 'react-i18next'
import { messages } from './languages'
import purify from 'dompurify'

const supportedLanguages = Object.keys(messages)
/**
 * Checks if the language is supported and returns it. Returns undefined otherwise
 * @param detectedLanguage The language detected
 */
function checkLanguageOrFallback(detectedLanguage: string) {
  return supportedLanguages.find((language) => language === detectedLanguage)
}

let tenantLanguages = {
  messages,
  fallback: checkLanguageOrFallback(window.navigator.language) || 'en-US',
}
if (process.env.REACT_APP_RESTRICT_LANGUAGES) {
  const languages = process.env.REACT_APP_RESTRICT_LANGUAGES.split(',')
  for (let lng in messages)
    if (!languages.includes(lng)) delete messages[lng as keyof typeof messages]
  tenantLanguages.fallback = languages[0]
}

const originalDetect = LanguageDetector.prototype.detect
LanguageDetector.prototype.detect = function (...args) {
  const lang = originalDetect.bind(this)(...args)
  return (
    checkLanguageOrFallback(Array.isArray(lang) ? lang[0] : (lang as string)) ||
    tenantLanguages.fallback
  )
}

class SearchQueryDetector {
  type: 'languageDetector' = 'languageDetector'
  name: 'searchQuery' = 'searchQuery'

  lookup() {
    const obj = new URLSearchParams(window.location.search)

    return checkLanguageOrFallback(obj.get('lang') as string)
  }
}

const detectorInstance = new LanguageDetector()
detectorInstance.addDetector(new SearchQueryDetector())

_i18n
  .use(initReactI18next)
  .use(detectorInstance)
  .init({
    debug: false,
    defaultNS: 'translations',
    detection: {
      order: ['searchQuery', 'localStorage'],
    },
    fallbackLng: tenantLanguages.fallback,
    ns: ['translations'],
    resources: tenantLanguages.messages,
    interpolation: {
      format: function (value, format) {
        switch (format) {
          case 'uppercase':
            return value ? value.toUpperCase() : ''
          default:
            return value
        }
      },
    },
  })

const t = <
  T extends keyof TranslationCodesByTenant = keyof TranslationCodesByTenant,
  R extends any = string
>(
  code: TranslationCodesByTenant[T],
  props?: { [key: string]: any }
) => {
  if (props) {
    const cloneProps = { ...props }
    for (let key in props)
      if (typeof props[key] === 'object') cloneProps[key] = `#${key}#`
    let intl = _i18n.t(code, cloneProps)
    if (typeof intl === 'string') {
      let result = intl.split('#')
      if (result.length === 1) return result[0] as R
      for (let key in props)
        if (typeof props[key] === 'object')
          result = result.map((a) => (a === key ? props[key] : a))
      return result as unknown as R
    } else {
      return intl as R;
    }
  } else {
    return _i18n.t(code, props) as R
  }
}

const tO: typeof t<'openspace'> = t

const tS: typeof t<'playground'> = t

/**
 * This indicates editable text elements. Created to prevent returning an ReactElement and accidentaly handling it as a string creating the risk of crashing
 */
const tE: (...params: Parameters<typeof t>) => ReactElement = t as any

/**
 * Used for dynamic texts that are not necessarily available on the translation file
 * @param code A predefined text for translation file (usefull for dynamic strings `some.code.${SOMETHING_THAT_MAY_OR_MAY_NOT_BE_DEFINED}`)
 * @param defaultStr The default string to show when the code is not defined
 * @returns A translation or default
 */
const tDefault = (
  code: TranslationCodesByTenant[keyof TranslationCodesByTenant] | string,
  defaultStr: string
) => _i18n.t(code, defaultStr)

const EscapedTranslation: (props: {
  code: TranslationCodesByTenant[keyof TranslationCodesByTenant]
  props?: { [key: string]: any }
  className?: string
}) => ReactElement = ({ code, props, className }) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: _i18n.t(code, props as any, {
          interpolation: { escapeValue: false },
        }),
      }}
      className={className}
    />
  )
}

const i18n = _i18n as {
  t: typeof t
  language: LanguageCodes
  changeLanguage: (lang: LanguageCodes) => void
  on: (
    eventName: 'languageChanged',
    cb: (language: LanguageCodes) => void
  ) => void
} & Pick<
  typeof _i18n,
  | 'changeLanguage'
  | 'addResource'
  | 'getResource'
  | 'store'
  | 'addResourceBundle'
>

const sanitized = (
  code: TranslationCodesByTenant[keyof TranslationCodesByTenant] | string,
  props?: any
) =>
  purify.sanitize(_i18n.t(code, props), {
    ADD_ATTR: ['target', 'frameborder', 'allow'],
    ADD_TAGS: ['iframe'],
  })

export { i18n, t, tO, tS, tE, tDefault, EscapedTranslation, sanitized }
