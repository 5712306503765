import { TranslationShape } from './rarum.pt'
import { PREDEFINED_DEADLINES } from 'pages/Authenticated/Marketplace/CreateOffer/CreateOffer.types'
import { ClaimAuctionStatus } from 'components/offer/OfferAuctionRace/ClaimAuctionItem/ClaimAuctionItem.types'
import { KYCRequiredStatus } from 'components/offer/OfferAuctionRace/KYCActionWrapper/KYCActionWrapper.types'
const messages: { 'en-US': TranslationShape } = {
  'en-US': {
    translations: {
      features: {
        challenge: {
          claim: {
            claiming: {
              title: "Please Wait",
              description: "We are processing the item redemption operation."
            },
            confirmation: {
              actions: {
                viewDetails: `See claim data`,
                goToClaim: `Go to claim`,
                approve: "Approve",
              },
              approve_gallery: `You need to authorize the management of tokens from gallery {{galleryName}}.`,
              title_claiming: `Claiming item`,
              deplete: `The item {{itemName}} will no longer be available in your collection, as all units are being used for claim.`,
              disclaimer: `When confirming the claim, the operation cannot be undone.`,
              warning: `The selected items will be transformed into a new item, and the available quantity will be reduced in "My collection".`,
              title: "Claim confirmation",
            },
            authRequired: `To see your progress, you need to log in with your account.`,
            requirement: {
              available: `{{available}} of {{required}} items available for claim.`,
              status: {
                incomplete: `Pending claim`,
                complete: "Complete",
              },
            },
            loading: {
              description: `We are loading the claim information`,
              title: "Wait a moment",
            },
            follow_instructions: `What is required to claim this Reward:`,
            title: "Claim the item",
            reward_rules: "Reward rules",
            requirements: "Requirements for claim",
            more_details: "More details",
            actions: {
              seeItem: `Check item`,
              status: {
                claimable: `Claim`,
                unavailable: "Complete the items to claim",
                claiming: "Claiming"
              },
            },
            status: {
              claimable: "Available for claim",
              unavailable: "Unavailable for claim",
            },
          },
          section: {
            title: "Highlight rewards",
          },
          banner: {
            amount_of_type: "{{count}} items {{itemType}}",
            to_claim: "To retrieve this reward, you need:",
          },
        },
      },
      walletRequiredControl: {
        connectWallet: "Connect wallet",
        connectWalletDescription:
          "Before starting, you need to connect your Metamask wallet.",
        connectWalletLink: "Click here to learn how to make it.",
        transferWallet: {
          intro: {
            actions: {
              cancel: "Cancel",
              startOperation: "Start process",
            },
          },
          waitingConfirmation: {
            title: "Confirmation needed",
            description:
              "To proceed with the process, please check the link we just sent to your email. A confirmation is necessary to connect your NFTs to the wallet in your profile.",
            actions: {
              understood: "Understood",
            },
          },
          fromEmailLink: {
            title: "Confirm operation",
            description: "Let's transfer your NFTs to your wallet:",
            actions: {
              understoodLimitations:
                "I am aware that this process will be executed once and cannot be reverted.",
              understoodDanger:
                "I am aware of the risks and benefits of using an external wallet and that Rarum will no longer have access to my NFTs after the migration is completed.",
              cancel: "Cancel",
              confirm: "Confirm",
            },
          },
          operationStarted: {
            title: "Operation confirmed",
            description:
              "The operation is in progress and in a few minutes your items will be transferred to your wallet.",
            actions: {
              understood: "Understood",
            },
          },
        },
        migrationRequired: {
          title: "Connect NFTs to Wallet",
          description: "To proceed with this operation, you first need to connect the items in your collection to your wallet.",
          actions: {
            goToMigration: "Proceed to migration"
          }
        },
        wrongWallet: {
          title: "Error connecting wallet",
          description1: "It's not possible to connect using the wallet:",
          description2:
            "Try again with the wallet associated with your account:",
        },
        actions: {
          connectWalletMetamask: 'Connect Metamask wallet',
          reloadWindow: 'Check installation',
          switchNetwork: 'Connect to {{name}} network',
        },
        switchNetwork: 'Switch to {{name}} network',
        switchNetworkDescription:
          'Make sure your wallet is connected to {{name}} network ({{currency}}). If not, switch networks before starting.',
        connecting: 'Connecting',
        connected: 'Connected',
        metamaskGuideTarget: '',
      },
      generic: {
        knowMore: `Know more`,
        save: "Save",
        close: "Close",
        retryBtn: "Try again",
        soon: "soon!",
        buy: "BUY",
        cancel: "Cancel",
        continue: "Continue",
        errorMsg: "Something went wrong",
        contactUs: "Contact us",
        share: "Share:",
        attention: "Attention!",
        goBack: "Go back",
        next: "Next",
        notNow: "Not now",
        optional: "Optional",
        or: "or",
        understood: "Understood",
        notFound: {
          actions: {
            backToHome: "Go back to home",
          },
          title: "Resource not found.",
        },
        fieldRequired: "This field is required",
        invalidPhone: "Invalid phone number",
        invalidDate: "Invalid date",
        day: "{{count}} day",
        day_plural: "{{count}} days",
        today: "Today",
      },
      marketplace: {
        buyOffer: {
          whatIsRarum: "What is Rarum?",
          details: "Purchase details",
          destinatary: "Recipient (you):",
          itemName: "Item name:",
          value: "Value:",
          balanceWarning:
            "Your balance in {{currency}} ({{amount}}) is not sufficient",
          boughtItem: "Purchase completed!",
          approveTokens: "Approve limited token spending",
          final: "Confirm purchase",
          title: "Finish purchase",
          description: "Follow these steps to finish your purchase",
          actions: {
            seeItem: "View item",
            retry: "Retry",
            approve: "Approve",
            cancel: "Cancel",
            confirm: "Confirm",
          },
        },
        assetDetails: {
          available: "{{count}} offer available",
          available_plural: "{{count}} offers available",
          offers: "Offers",
          lowestPriceOffer: "Cheapest offer",
          marketplaceValue: "Value in marketplace",
          seller: "Seller",
          you: "You",
          noOffers:
            "There are no offers for the selected currency ({{currency}})",
          actions: {
            listOffers: "View offers",
            manageMyITem: "Manage offer",
          },
        },
        offerCreation: {
          title: "Sell item",
          description: "Set the following parameters to create your offer.",
          actions: {
            backToHome: "Return to item",
            cancel: "Cancel",
            confirm: "Confirm and publish",
          },
          sections: {
            preview: {
              title: "Offer preview:",
            },
            type: {
              title: "Type of sale",
              direct: "Direct sales",
              auction: "Auction",
            },
            currency: {
              title: "Currency and item value",
              description: "Define value for this sale",
              description_plural: "Select currency and value for this sale",
              chooseYourCurrency: "Select the currency",
              defineAmount: "Provide the sell price",
              disclaimer: {
                youReceive: "(you receive: {{ amount }})",
                tax: "(Fee: {{ amount }}%)",
              },
            },
            itemAmount: {
              title: "Item amount",
              description: "Provide the amount of the same item",
            },
            deadline: {
              title: "Time",
              description: "How long you offer will be active",
              disclaimer: "minimum: 1 month",
              predefined: {
                one_month: "1 month",
                two_months: "2 months",
                three_months: "3 months",
              } as {
                  [k in PREDEFINED_DEADLINES]: string;
                },
            },
          },
          creation: {
            error: {
              title: "Oops, your request failed :(",
              description:
                "Please try again. If the error persists, help us improve, contact us at contact@onepercent.io",
            },
            inProgress: {
              title: "Creating offer",
              description:
                "Your offer will be ready soon.\nWe wish you success in your sales!",
              actions: {
                wait: "Please wait",
              },
            },
            finished: {
              title: "Offer created",
              description: "Your offer is ready",
              actions: {
                seeItem: "See item",
              },
            },
          },
          edition: {
            error: {
              title: "Oops, your request failed :(",
              description:
                "Please try again. If the error persists, help us improve, contact us at contact@onepercent.io",
            },
            inProgress: {
              title: "Updating your offer",
              description: "Soon your offer will be updated.",
              actions: {
                wait: "Please wait",
              },
            },
            finished: {
              title: "Offer updated",
              description: "Your offer was successfully updated.",
              actions: {
                seeItem: "See item",
              },
            },
          },
          removal: {
            error: {
              title: "Oops, your request failed :(",
              description:
                "Please try again. If the error persists, help us improve, contact us at contact@onepercent.io",
            },
            inProgress: {
              title: "Remove offer",
              description:
                "Are you sure you wish to remove your offer? After confirmed, the operation cannot be canceled.",
              actions: {
                wait: "Confirm and remove",
              },
            },
            finished: {
              title: "Your offer was successfully removed!",
              description:
                "We are proceeding with the transaction. Soon it will be finished.",
              actions: {
                seeItem: "Ok",
              },
            },
          },
          confirmation: {
            title: "Finish sale",
            description:
              "Follow the steps below to complete creating your sale",
            configure: "Set up your sale",
            approve: "Approve Metamask operation",
            confirm: "Confirm operation",
            actions: {
              approve: "Approve",
              confirm: "Confirm",
              cancel: "Cancel creation",
            },
          },
        },
        offerEdition: {
          actions: {
            confirm: "Confirm and update",
          },
        },
        banner: {
          header: {
            title: "Marketplace",
            description:
              "Discover the items on sale and all the items that can be part of your Collection",
            connectedWallet: "Connected wallet",
            actions: {
              connectWallet: "Connect wallet",
              disconnectWallet: "Disconnect",
              dismiss: "Continue",
            },
          },
          faq: {
            title:
              "<b>Learn how to sell</b> the items available on your Collection",
            action: "Learn more",
            targetGuide: "",
          },
        },
        selectUnitsOnSale: "Select the amount of items",
        unitsOnSale: "{{count}} units",
        unitsOnSale_plural: "{{count}} units",
        salePrice: "Sale price",
        totalUnits: "units: {{units}}",
        remainingUnits: "remaining: {{units}}",
        remainingHighlight: "{{count}} item on sale now",
        remainingHighlight_plural: "{{count}} items on sale now",
        lowestPrice: "Lowest price",
        buyNow: "Buy now",
        filters: {
          order: {
            lowestToHighest: "Lowest to highest",
            highestToLowest: "Highest to lowest",
          },
          assets: {
            showAll: "All cards",
            cardsOnSale: "Cards on sale",
          },
          currency: "Currency",
          clear: "Clean filters",
          rarity: {
            label: "Rarity",
            types: {
              COMMON: "Common",
              EPIC: "Epic",
              LEGENDARY: "Legendary",
              RARE: "Rare",
            } as {
                [k in typeof import("core/constants")["RARITIES"][number]]: string;
              },
          },
        },
      },
      highlights: {
        marketplace: {
          title: "Now you can buy and sell cards from other collectors",
          description:
            "Increase your Digital Collection, sell duplicate cards and complete your collection.",
          action: "Go to market",
        },
        marketplaceSoon: {
          title:
            "Soon you will be able to buy and sell cards from other collectors",
          action: "Let me know",
        },
      },
      app: {
        cookiesConsent: {
          understand: "I understand and approve",
          message: "This website uses cookies to enhance the user experience.",
        },
      },
      wallet: {
        connect: "Connect wallet",
        switchingAccount: {
          warning: "When switching account you have to connect again.",
        },
      },
      unauthenticated: {
        terms: {
          termOfUse: "Terms of Use and Privacy Policy",
          return: "Return",
        },
        risks: {
          title: 'Risk Acknowledgment',
          description: 'The investor declares that:',
          list: [
            'has full knowledge of the risks associated with investments made in financial assets offered on the Oken Platform, as well as the possibility of total or partial loss of their investment;',
            'is aware that any losses suffered by them as a result of their decisions to invest or redeem their investments are entirely their responsibility;',
            'assumes full responsibility to the Oken Platform and third parties for the information provided to the Platform, as well as the legitimacy of the documents submitted, being responsible for any damages eventually caused to the Oken Platform or third parties due to the legitimacy of the same;',
          ],
        },
        galleryCards: {
          title: "Cards",
          text1: "All cards available on the platform!",
          filter: {
            COMMON: "Common",
            RARE: "Rare",
            EPIC: "Epic",
            LEGENDARY: "Legendary",
            all: "All",
          },
          filterNoResult: "The filter couldn't find results",
          actions: {
            clearFilters: "Clean filters",
          },
        },
        socialSign: {
          rarumInscription:
            "By signing up for Rarum, you acknowledge that you have read and agree to all",
          termOfUse: "Terms of Use and Privacy Policy",
          alert:
            "This email has already been used in this account {{existingPlatform}}, please sign in to this platform to conclude your connection",
          continue: "Continue",
          continueWith: "Continue with",
          dataConsent:
            "I agree to share my personal data with {{tenantName, uppercase}} for marketing purposes",
          noAuth: {
            title: "Hello",
            description:
              "Please, open our website using your Web Browser for a full experience.",
            goBack: "Go Back",
          },
        },
        identification: {
          signIn: "ENTER",
          signInUsing: "Or login with:",
          signUp: "Sign up",
          forgotPassword: "Forgot your password?",
          cancel: "Cancel",
        },
        galleryDetail: {
          backToCards: "Back to Cards",
          itemsDetail: "Item Details",
          description: "Description",
          drawnIn: "Available in",
        },
        galleries: {
          title: "Featured galleries",
          description: "Access our tematic galleries to see the offers",
        },
        drops: {
          list: {
            collectablePackages: "Collectable Items",
            featuredOffers: "Featured Offers",
            featuredDescription: "Here you find the best items for purchase",
            description:
              "Here you find all items avaiable for purchase in the store",
            notAvailable:
              "These items are no longer available, but don't worry, soon you'll be able to search for specific items at the marketplace.",
            previousPackages: "Previous items",
            stickyDrop: {
              saleNow: "sale now!",
            },
            soonDropItem: {
              comingSoon: "Coming soon!",
            },
            dropItem: {
              liveBadge: {
                liveNow: "live now!",
              },
            },
          },
          details: {
            info: {
              bookPackage: "Buy this item",
              notAvailable:
                "There are no more units available for this item. Select another to collect more items with Rarum.",
              waitEvent: "Wait for the event to begin!",
              ctaLabelApprove: "Approve",
              ctaLabelBuy: "Buy",
              missingProvider:
                "To buy this NFT, you need to have a wallet like Metamask. Please install it.",
              approvalFailMessage:
                "It was not able to confirm your approval, check your transactions, refresh this screen, or try again later",
              approvalSuccessMessage: "Success! Now you are ready to buy.",
              purchaseFailMessage:
                "It was not able to confirm your purchase, check your transactions before trying again.",
              purchaseSuccessMessage:
                "Success! It might take a few minutes till your NFT is listed in your gallery, git it a few minutes.",
            },
            availability: {
              packageDetail: "Details of the item",
              packageAvailable: "This item is available for sale at this time:",
            },
            packages: "items",
          },
        },
        deletion: {
          return: "Return",
          remotionInstruction:
            " Instructions to remove your personal information from the application",
        },
        connect: {
          createAccount: "Create your account at Rarum",
          login: "Login to Rarum",
        },
        faqview: {
          faq: {
            1: "To start, <b>register</b> using your Google or Facebook account.",
            2: `Great! Now you can get to know the available collections. Each one has a certain number of items that can vary in quantity and are organized in three rarity levels: <b>Hero, Premium and Legend</b>. The cards have been distributed and named according to their Rarity level.`,
            3: `The next step is to choose one of the packages from the collections available. Please remember: the cards are offered randomly. Each package contains a certain number of random cards from that collection. The packages differ in rarity and how desirable the cards are. The higher the rarity level, the more desirable the item is, and there are a lower number of them available.
            `,
            4: `Now it’s time to pay for your package. After selecting the one, you will be directed to a payment screen. Choose the payment method and insert the requested data. As soon as the payment is approved, our system will deliver the NFTs to your collection randomly and according to the rarity chosen: hero, premium and legend.`,
            5: `And that is it! The final step is to receive your package in your collection. Click on “open package” and reveal your cards. And it is already possible to check your property in the blockchain, with transparency and immutability. The cards will be stored in your collection here and, soon, you will be able to negotiate them with other collectors. Congrats!`,
            accordion: {
              title: {
                1: 'What is a NFT?',
                2: 'Why is NFT a collectible item?',
                3: 'What can I do with my NFT?',
                4: 'How much is my NFT?',
                5: 'What is a token?',
                6: 'What is crypto art?',
                7: 'What is a digital wallet of crypto-assets?',
                8: 'What is blockchain?',
                9: 'In which blockchain were NFTs created?',
                10: 'Why did we choose {{name}}?',
                11: 'How do I check my NFT in the {{name}} network?',
                12: 'What is metadata, and where is this digital art stored? ',
                13: 'How does the auction work?',
                14: 'How does the NFT transfer work?',
                last: 'More questions?',
              },
              content: {
                1: `The acronym NFT stands for (in English) Non-Fungible Tokens or, in Portuguese: "tokens não fungíveis", in other words, not substitutable. They cannot be changed for another equivalent item. NFT is a type of token with unique characteristics, registered in a chain of blocks (Blockchain), representing an asset that is innately digital, or real-world in the digital format.

                Here lies a very important differential: we are the first platform with sustainable NFTs, in other words, Carbon Neutral. All the emissions of greenhouse gases from the creation of the NFTs are compensated using carbon tokens from various projects in the crypto ecosystem.`,
                2: `Imagine a very valuable work of art that is on exhibition at a famous museum.

                This work of art can be seen by thousands of museum visitors. It also can be photographed and even copies can be purchased and displayed at your house. It doesn’t matter how many copies or exact replicates are made, the real value will always be in the original work.

                This is the same logic in the digital environment with the birth of NFTs. These unique tokens, which are revolutionizing the art world and lovers of some collectible item, allow the certification of the unique property’s originality, rather than physically or 100% digital.`,
                3: `The possibilities are infinite, you may sell, save or exhibit, always guaranteeing the property’s registration based on the Blockchain, giving trust, traceability, safety and transparency in the process.
                <br />
                <br />
                For example:
                <br />
                1. Save it as a souvenir.<br />
                2. Give it as a present to a family member or friend.<br />
                3. Sell it directly to someone who is interested in purchasing this limited asset.<br />
                4. Put it for sale on one of the platforms for sales and buying of NFT (for example, Rarum).`,
                4: `The price of the NFT is basically dictated by the market, it is not possible to foresee if this digital asset will have a monetary value at any time in the future. This will exclusively depend on the supply and demand of this item.

                Recent market movement has shown great opportunities in collectibles and for owners of NFTs around the world`,
                5: `From a financial point of view, a token applied to Blockchain is a digital representation of a financial asset.

                This representation can happen in two ways. It can be a token that represents an asset that has a real-world version: these are called “backed asset tokens” .

                Or also it can be tokens that don’t have anything to do with traditional assets that we are used to and actually represent only a 100% digital one, as is the case of the NFT that we are using in our platform.`,
                6: `Cryptoart is a concept of artwork that is represented in Blockchain and went through the process of being changed into a token, just like cryptocoin for the financial market. From the possibility of the tokenization of assets, collectible tokens appeared, which became famous as the acronym: NFT (Non-Fungible Tokens). These tokens are characterized by their uniqueness in the digital environment, therefore, they are also able to be collected.

                The cryptoart movement started to gain a little relevance in 2017, but has boomed over the last few years with the advancement of technology and the appearance of diverse selling and purchasing platforms, as well as artists focused on this market and consumers interested in collecting cryptoart.`,
                7: `These digital wallets are applications in which assets can be managed simply and autonomously by their owner. In this environment, you can store your cryptographic assets, here represented as "My Collection" or transfer them to another wallet that you own.

                You should pay attention when accessing your wallet and as you deal with your assets because it is here that you deal with your secure access and ownership of your assets. When you transfer the assets to another wallet, we suggest that you check the reputation of the company which will intermediate this wallet and follow the security recommendations when opening a new wallet. `,
                8: `A blockchain is essentially a digital ledger of transactions that is duplicated and distributed across the entire network of computer systems on the blockchain. Blockchain is a term that has been widely used when referring to a new technological  infrastructure of Web3 (a new phase of the internet) that has as its main characteristics: decentralization, security and transparency. Also, it allows for the creation and exchange of digital assets between people around the world, without the need of an intermediary.

                This technology allowed for the creation of cryptocoins, Bitcoin for example.

                The theme has become more and more relevant over the years with the maturing of other Blockchain networks such as Ethereum, with the emergence of smart contracts.

                The main uses of this technology are for payment method solutions, for tokenized assets and other types (artwork, stocks, real estate, carbon credit among others), registration and authentication of contracts and documents in general, even the birth of NFTs, as the ones that we are seeing in this platform.`,
                9: `The NFT tokens were created on the Polygon network.`,
                10: '',
                11: `There are many tools that allow you to check information in a Blockchain, these are known as Block Explorer. The most popular one in the {{name}} network is <a href="{{explorerUrl}}" target="_blank">{{explorerName}}</a>.

Copy and paste the address of your NFT into {{explorerName}}, then you can access the metadata that composes your NFT.`,
                12: `Metadata is all the information that describes what that digital asset represents. To guarantee a decentralized approach in the storage of the files, we use the service <a href="https://ipfs.io/" target="_blank">IPFS</a> for storing both the art itself and the file that contains this information.

Unlike other more common forms of cloud storage, where one company “takes care of” the guarding of this information, with IPFS this guarding is also decentralized, providing more resilience and security for the information.

This way, even if the site goes off-air, you can access your NFT forever, as long as you have done the recovery protocol correctly.`,
                13: `You can access a single item in NFT (a digital asset) format from the auction. All you have to do is follow a few criteria for a successful bid. These are as follows:
                The bid amount needs to be greater than the minimum increment;
                Be less than at the maximum increment of the already placed bid;
                Enter your valid cell phone number;
                Submit documentation that will be requested from the contact by the platform's team to validate you as the winner of the auction;
                Make the payment, choosing one of the formats available at the platform: Credit Card or Cryptocurrency;
                After approval of the documentation and payment. That's it! You will receive the exclusive NFT in your Collection.`,
                14: `
                For payments in PIX or cryptocurrency, the transfer will be available automatically. For charges on credit cards, the transfer will only be available 30 calendar days after registering the same credit card data. This process ensures the security of the platform and the safety of your experience with us. If you change your credit card on the platform, this process will count again for 30 days of system security.
                <br/>
                <br/>
                To perform this operation, you will need to follow a few steps. Let's go to the instructions! Fill in the following fields with the amount of NFTs to transfer, and the receiving address of your digital wallet to make the transfer.
                <br/>
                Step 1 - Download, install and create a <a href="https://metamask.io" target="_blank">MetaMask</a> wallet account on your cell phone (available on the Play Store, Apple Store) or on your computer; <br/>
                Step 2 - When you open your wallet, you will find your receipt address below your account name. Copy this address. You will use it for the process.
                <br/>
                <br/>
                Viewing my NFT
                <br/>
                Now you have your NFT in your digital wallet. Now you can view it on another platform of your choice.
                `,
                last: `If you need more information or have any further questions about the process, contact us via e-mail at: contact@onepercent.io
                We will reply by email shortly.

                Thank you for the trust deposited in us!`,
              },
            },
          },
        },
        claim: {
          soldOff: {
            title: "Item Sold Out",
            description:
              "We're sorry, but this item has reached the maximum number of redemptions.",
          },
          signup: {
            main: {
              title:
                "<b>Enter your best email</b> to receive access to your exclusive NFT",
            },
            result: {
              title:
                "<b>You rock!</b><br/>With your participation, you and hundreds more received an exclusive NFT!",
              description:
                "<b>Now just wait</b><br/>Soon, you will receive instructions in your email on how to access your exclusive NFT from Curta Consciente!",
            },
          },
          toClaim: {
            title: "Claim your NFT!",
          },
          claiming: {
            title: "Claiming",
          },
          errors: {
            alreadyClaimed: {
              title: "This item was already claimed",
              actions: {
                showItem: "Show in collection",
              },
            },
          },
          actions: {
            claim: "CLAIM",
          },
        },
      },
      authenticated: {
        userProfile: {
          purchases: "{{count}} purchase",
          purchases_zero: "No purchases have been made yet",
          purchases_plural: "{{count}} purchases",
          digitalWallet: "Digital Wallet",
          digitalWalletNotYetConfigured:
            "Wallet unavailable, waiting for the first purchase or linking with Metamask wallet",
          profileUpdate: "Profile Update",
          actions: {
            history: "View history",
            seeStore: "Go back to the store",
            setup: "Set up",
            complete: "Complete",
            incomplete: "Incomplete",
            changePersonalData: "Change personal data",
            changeDocuments: "Change documents",
            changeInvestorProfile: "Change investor profile",
            changeAdditionalData: "Change additional data",
          },
          sections: {
            personalData: ["My personal data", "Manage your personal data."],
            myDocuments: ["My Documents", "Manage and update your documents"],
            investorProfile: [
              "Investor Profile",
              "Update your investor profile",
            ],
            paymentData: [
              "Payment Data",
              "Add or change your payment data and digital wallet information.",
            ],
            communication: [
              "Communication and Privacy",
              "Manage the use of your communications and personal information.",
            ],
          },
          communication: {
            sections: ["Communication", "Privacy"],
            dataUsage: "Use personal data for communications and advertising",
            acceptCookies: "Accept cookies",
            cancel: {
              title: "Cancel account",
              description:
                "Cancel your account and delete your information from Rarum platform",
              actions: {
                cancel: "Cancel account",
              },
            },
          },
          kyc: {
            pending: {
              title: "Finish your registration and stay safe!",
              description:
                "For your safety and that of the platform, it is necessary to complete the registration with the step of recognizing and confirming your identity. Click on the button to proceed.",
            },
            awaiting_metamap: {
              title: "Validating your data",
              description:
                "We are validating your data and soon you will be able to participate in the auctions",
            },
            rejected: {
              title: "Oops, something went wrong",
              description:
                "Contact us using the button on the side to check what happened",
            },
            active: {
              title: "Everything ok with your data",
              description:
                "We have successfully validated your data and you are now ready to participate in the auction",
            },
          },
          myData: "My Data",
          dataBelow:
            "The details below refer to the holder of the means of payment that will be used to make purchases in the store. Information can be stored here for fast purchases in the future.",
          saveData: "Save data",
          deleteData: "Erase my payment information",
          wantToDelete: "Do you want to erase the payment information?",
          sureDelete:
            "Are you sure you want to erase all your payment details? When making your next purchase, you will need to inform them again.",
          eraseData: "ERASE PAYMENT INFORMATION",
          cancel: "Cancel",
          sucess: "Your payment information has been successfully saved!",
          sucessClear: "Your payment information has been successfully erased!",
          error: "An error has occurred, please try again.",
          profileForm: {
            identification: "Identification",
            fullName: "Full Name",
            cpf: "ID",
            address: "Address",
            cep: "Zip Code",
            state: "State",
            city: "City",
            place: "Place",
            number: "Number",
            complement: "Additional info (optional)",
            neighbourhood: "Neighborhood",
          },
        },
        checkout: {
          dropDetails: {
            details: 'Details of purchase',
            recipient: 'Recipient (you)',
            itemName: 'Item Name',
            value: 'Value',
            cvm: {
              footer: `Any investment in small businesses (startups) involves financial risks and significant challenges. Investments in startups should only make up a small part of a diversified investment portfolio. You will be at risk of losing part or all of the money invested in companies through the {{company}} platform. There is no guarantee of a return on the investment made. Only invest in businesses published on the {{company}} platform if you have sufficient knowledge to understand these risks and make your own investment decisions. Please see our <a href="/risk" target="_blank">Risk Warning</a> before making an investment decision.

Small business companies and offerings presented on this platform are automatically exempt from registration by the Brazilian Securities and Exchange Commission - CVM.
The CVM does not review offerings in advance.
The offerings made do not imply, on the part of the CVM, a guarantee of the accuracy of the information provided, compliance with current legislation, or a judgment about the quality of the small business company.
Before accepting an offer, carefully read the essential information about the offer, especially the section on risk warnings.

{{company}} provides a communication channel through the email address <a href="mailto:{{cvmMail}}">{{cvmMail}}</a>, where its data protection officer will receive any requests, inquiries, communications, and/or expressions from data subjects to exercise the rights stipulated in Law 13,709/2018 (General Data Protection Law), in accordance with its <a href="/terms" target="_blank">Privacy and Cookies Policy</a>.`,
            },
          },
          continue: "Continue",
          cancel: "Cancel",
        },
        myCollection: {
          open: 'Open now',
          cancel: 'Cancel',
          timeLeft: 'Time Left:',
          proceed: 'Click here to proceed with payment for this item.',
          clickToOpen: 'Click here to open your purchase',
          clickToClaim: 'Click here to claim your item',
          myCollection: 'MY COLLECTION',
          accessDetails:
            'Access and see the details of the items of your collection.',
          wantToProceed: 'Do you wish to proceed with opening your purchase?',
          wantToProceedWithClaim:
            'Do you want to proceed with claiming your item?',
          collectionItem: {
            unitsNew: "{{count}} unit",
            unitsNew_plural: "{{count}} units",
            unity: "unit",
            units: "units",
            announcePrice: "Offer price",
            active: "Active offer",
          },
          collectionItemDetails: {
            noBalance: {
              title: "Looks like you don't have the item in your wallet",
              action: {
                backToMyCollection: "See my collection",
              },
            },
            goBack: "Go back to items",
            fileFormatImpossibleToView:
              "The file format cannot be previewed. Please download the file to view it on your device.",
            download: "Download",
            itemDetails: "Item details",
            owner: "Owner",
            description: "Description",
            nftInfo: {
              blockchainRecords: "Blockchain Records",
              metadate: "IPFS Metada:",
              wallet: "Wallet of {{name}}: ",
            },
            purchaseData: {
              itemDetails: "Item detalis",
              date: "Purchase date:",
            },
            enabledOffer: {
              title: "Item with active offer!",
              description: "Attention! This item could be sold at any time",
              actions: {
                seeOffer: "See offer",
                options: "Options",
                subActions: {
                  edit: "Edit",
                  remove: "Remove offer",
                },
              },
            },
            sellItem: "Sell item",
            transferDisabled:
              "For security reasons, transferring NFTs to external wallets will be only available 30 days after payment confirmation. Your NFT will available on:",
            sellDisabledExplanation:
              "Item not yet available for sale. Wait for the review period of your last registered credit card (the period is equivalent to 30 days since the last purchase with the card on the platform). Once this process is complete, you can sell the item!",
          },
          emptyCollection: {
            empty: "You don't have any items in your collection yet.",
            goToStore: "Go to store",
          },
          filter: {
            showAll: "All cards ({{amount}})",
            cardsOnSale: "Active offers",
          },
        },
        openDrop: {
          close: "Close",
          openNow: "Open Now",
          dropDetails: {
            success: "Purchase successfully made",
            recipient: "Recipient (you).:",
            itemName: "Item Name:",
            value: "Value:",
          },
        },
        openingDrop: {
          title1: "Creating source certificate...",
          message1:
            "This may take a little longer than expected. If you need to, we suggest you come back later to check the operation",
          title2: "Offsetting carbon footprint...",
          message2:
            "We care about sustainability. That's why we offset every carbon footprint of the production of the store's items",
          title3: "Transmitting data...",
          message3:
            "You can view all the items in your collection directly on the blockchain. If you have questions about where to get the data, please visit our FAQ or contact us",
          inProgress: {
            exit: "Exit",
            opening: "Opening purchase",
            claiming: "Claiming item",
          },
          modals: {
            errorModal: {
              myCollection: "My Collection",
              openingError: "Something went wrong in opening the purchase.",
              tryAgain:
                "Please try again later, or contact Rarum through contact@rarum.io.",
              openingErrorInfoModal: "Error opening the purchase",
            },
            exitModal: {
              confirm: "Confirm",
              cancel: "Cancel",
              confirmToExit: "Confirm to leave opening the purchase.",
              confirmToExitMessage:
                "After the upload process is complete, the items will be available in My Collection. ",
              wantExit: "Do you wish to leave?",
            },
            timeOutModal: {
              myCollection: "GO BACK TO MY COLLECTION",
              waitMore: "Wait for more",
              waitingMessage1:
                "Rest assured, your items will be available in your collection soon.",
              waitingMessage2:
                "Why can this take so long? The opening process runs on the blockchain and may vary depending on the volume of transactions on the network at the moment. Please wait a few minutes or return to your collection soon.",
              timeOutMessage:
                "Oops! The opening is taking longer than expected!",
            },
          },
          claimed: {
            claimed: "Your item has been claimed",
          },
          opened: {
            opened: "Your purchase is available {{ packTitle }}",
            checkOut: "Check the following item:",
            checkOut_plural: "Check the following items:",
            myCollection: "My Collection",
          },
        },
        payment: {
          exitProptMessage: "Are you sure you want to cancel the purchase?",
          confirm: "Confirm",
          cancel: "Cancel",
          goBack: "Go Back",
          purchaseData: "Purchase data",
          fillInTheData: "Fill in the details below to complete the purchase",
          confirmTheData: "Proceed to payment",
          continue: "Continue",
          problemMessage: "A problem has occurred.",
          wantExit: "Do you wish to leave?",
          logic: {
            error:
              "An error has occurred: please check your information, or try again later",
          },
          data: {
            pix: "Pix",
            creditCard: "Credit card",
            cryptoCoin: " Crypto Currency (solamente en red Ethereum)",
            personalData: "Personal Information",
            revision: "Revision",
            payment: "Payment",
            personal: "Personal",
            address: "Address",
            coinbaseStep: "Coinbase",
            nameIsMandatory: "Name is required",
            invalidEmail: "Invalid e-mail",
            emailIsMandatory: "E-mail is required",
            cpf: "ID",
            invalidCpf: "Invalid CPF",
            invalidId: "Invalid ID",
            cep: "Zip Code",
            invalidCep: "Invalid CEP",
            stateIsMandatory: "State is required",
            cityIsMandatory: "City is required",
            patioIsMandatory: "Address is required",
            numberIsMandatory: "Number is required",
            confirmAndPay: "Confirm and pay",
            creditCardSubtitle:
              "Fill in the payment information to make the purchase",
            countryIsMandatory: "Country is required",
            zipIsMandatory: "Zip code  is required",
          },
          dropInfoMobile: {
            purchaseDetails: "Purchase Details",
          },
          review: {
            dataReview: "Review and confirm purchase information",
            holderData: "Payment holder information:",
            cpf: "ID",
            addressData: "Address Data",
            cep: "Zip Code",
            formOfPayment: "Form of Payment",
          },
          safetyLabel: {
            safePurchase: "Safe purchase",
            safePurchaseBy: "Safe purchase via",
          },
          steps: {
            stepAddress: {
              addressData: "Fill in the address information",
              cep: "Zip Code",
              state: "State",
              city: "City",
              patio: "Street/Avenue",
              number: "Number",
              nonResident: "I do not reside in Brazil",
              postalCode: "Postal code",
              country: "Country",
            },
            stepMethod: {
              selectFormOfPayment: "Select payment method",
              pixBRL: "Pix",
              creditCard: "Credit Card",
              creditCardBRL: "Credit Card (issued in Brazil)",
              creditCardUSD: "Credit Card (USD)",
              creditCardAny: "Credit Card ({{currency}})",
              cryptoBRL: "Crypto Currency (Ethereum network only)",
              cryptoUSD: "Crypto Currency (Ethereum network only)",
              cryptoCoin: "Crypto Currency",
            },
            stepReview: {
              dataReview: "Review and confirm purchase information",
              confirmMessage1:
                "Upon confirming your information you will be redirected to the payment screen.",
              confirmMessage2:
                "In case of errors in the information below, it will be necessary to remake the purchase.",
              holderData: "Information of the owner of the purchase:",
              cpf: "ID",
              foreignerId: "ID",
              edit: "Edit",
              addressData: "Address information:",
              cep: "Zip Code",
              formOfPayment: "Form of payment:",
              update: "Update registration information for future purchases",
            },
            stepUserData: {
              personalData: "Fill in personal information",
              name: "Name",
              cpf: "ID",
              foreignerId: "ID",
              foreigner: "I'm foreigner",
            },
          },
        },
        pendingPayment: {
          expiredPayment: "Expired Payment",
          expiredPurchase: "Sorry, this purchase has expired.",
          tryAgain: "Please try to make your purchase again.",
          timeLeft: "Time Left",
          myCollection: "My Collection",
          problem: "A problem has occurred",
          payWithPix: "Pay with Pix",
          payWithCreditCard: "Pay with Credit Card",
          payWithCryptoCoin: "Pay with Crypto Currency",
          data: {
            mandatoryNumber: "Mandatory number",
            invalidNumber: "Invalid number",
            mandatoryCode: "Mandatory Code",
            invalidCode: "Invalid Code",
            mandatoryShelfLife: "Expiration date is required",
            invalidShelfLife: "Invalid expiration date",
            nameIsMandatory: "Name is required",
          },
          methods: {
            creditCard: {
              creditCardData: "Fill in the credit card information",
              creditCardNumber: "Credit card number",
              holderName: "Name of the owner",
              securityCode: "Security code",
              shelfLife: "Expiration date (month/year)",
              finishPurchase: "Finish purchase",
            },
            pix: {
              copyCode: "Copy code",
              pixCodeCopied: "Pix code has been copied",
              success: "Pix code has been successufully copied",
            },
          },
          pixInfo: {
            howToPay: "How to pay with Pix",
            qrCode:
              "Use your bank's app to scan the generated QR code or copy the code.",
            checkOutData:
              "Check the information and confirm the payment through your bank's application or digital wallet.",
            waitingMessage:
              "If everything is ok, your payment will be approved in a few moments.",
          },
        },
        transfer: {
          pendingExternalTransfer: {
            actions: {
              goToTransfer: "Start migration",
            },
            description: `You have connected your wallet but have yet to migrate your balances from your account. In order to do so, please start the migration process by clicking the button below. You'll receive a link in your e-mail to confirm the migration.
Please note that all balances in your Rarum account will be transferred, so double-check if you have access to your connected wallet. Once transferred, we can no longer move them on your behalf.`,
            subtitle:
              "You have not transfered the balances to your account yet",
          },
          errors: {
            amountRequired: "Amount is required",
            invalidAmount: "Invalid amount",
            walletRequired: "A wallet address is required",
            invalidWallet: "Invalid wallet",
            unexpected:
              "An error occurred during the process. Please try again later or contact us.",
          },
          title: "NFT Transfer",
          request: {
            ongoing: {
              loading: "A transfer is finishing, please wait",
            },
            description:
              "This option allows you to transfer your NFT to an external wallet. It will also remove your item from Rarum and put it under full control of the specified wallet. Fill all the fields bellow or have more information in the link bellow.",
            more: "Have more information about NFT transfers",
            amount: {
              description: "1. Inform the amount of items you wish to transfer",
              label: "Amount",
              of: "of",
            },
            wallet: {
              description: "2. Inform the wallet address",
              label: "Wallet",
            },
            warning:
              "WARNING: For your own security, make sure the destination wallet supports the ERC-1155 token standard on the polygon network. If there is no support to such standard, it will not be able to mange the tokens you send.",
            confirm: {
              label:
                "I have read and understand all the risks descripted above",
              modal: {
                title: "Start NFT transfer",
                description:
                  'By clicking "Proceed", we will send an e-mail to {{email}} where you can confirm the transfer of {{amount}} token{{plural}} to wallet {{wallet}}',
                proceed: "Proceed",
                cancel: "Cancel",

                metamask: {
                  description:
                    'When you click "Proceed," you will need to approve the transfer on your wallet provider. Please note that this transfer requires GAS and you must have MATIC in your wallet to complete the transaction. Once confirmed, {{amount}} token{{plural}} will be transferred to the wallet {{wallet}}.',
                  finished:
                    "The transfer of {{amount}} token{{plural}} to the wallet {{wallet}} has finished",
                  actions: {
                    close: "Close",
                  },
                },
              },
            },
            submit: {
              label: "Transfer",
            },
          },
          waiting: {
            title: "Waiting for e-mail confirmation",
            description:
              "We just sent an e-mail to {{email}} with a link to proceed the transfer.",
          },
          confirmation: {
            warning:
              "Transfer might take minutes to complete. You do not need to wait until finished. You will receive an e-mail when the transfer is complete.",
            submit: "Transfer",
            cancel: "Cancel",
            description: {
              part1:
                'By clicking "transfer", the requested transfer will be fulfilled. The process might take some minutes to finish.',
              part2:
                "The transferred tokens will be removed from your collection when the transaction is confirmed.",
              part3: "This operation cannot be reverted.",
            },
          },
          finished: {
            overview: "Your NFT will be available in the destination wallet",
            attention: "Attention:",
            waitTime:
              "It might take some minutes for the item to be available in the destination wallet, due to the processing time (click",
            here: "here",
            forMore: "to know more about it);",
            wheneverAvailable:
              "Whenever available, you can visualize it in your",
            openseaAccount: "OpenSea account",
            submit: "Confirm transfer",
            finished: "NFT successfully transferred",
          },
        },
        waitingRoom: {
          descriptionMessage:
            "It's not long before you get the item you want. This room is a special space for you to receive the purchase link as soon as it becomes available.",
          waitingRoom: "Waiting room",
          eventStart: "The event will begin at 02:00 PM",
          lastUpdate: "Last status update at: 01:55 PM",
          id: "Qeue ID: 785639418e2-40bft-655eea88ad08",
          exit: "Exit",
          media: {
            waitingRoom: "Waiting room",
          },
        },
      },
      components: {
        offerRequirements: {
          regulation: {
            check:
              "Before {{offer_type_action}}, please review the regulation of this offer",
            checkTypes: {
              auction: "participating in the auction",
              off_chain_auction: "participating in the auction",
              onchain: "buying the pack",
              pack: "buying the pack",
              single: "buying the item",
              cvm88: "Invest",
            },
            action: {
              check:
                "I have already reviewed the regulation and I agree with the defined rules",
              continue: {
                auction: "Continue to auction",
                off_chain_auction: "Continue to auction",
                onchain: "Continue to purchase",
                pack: "Continue to purchase",
                single: "Continue to purchase",
                cvm88: "Continue to investment",
              },
              viewRegulation: "View regulation",
            },
          },
        },
        offerPrice: {
          noPrice: "--",
        },
        offerCountdown: {
          remaningTime:
            "Remaining time: {{days}}d {{hours}}h {{minutes}}min {{seconds}}s",
        },
        kycActionWrapper: {
          [KYCRequiredStatus.LOADING_KYC]: "Processing KYC",
          [KYCRequiredStatus.PENDING_KYC]: "Provide KYC",
          [KYCRequiredStatus.ERROR_KYC]: "KYC has error, contact us",
          [KYCRequiredStatus.AUTHENTICATE_REQUIRED]: "Sign in",
        },
        offerAuctionRace: {
          initialBid: "Initial bid",
          lastBid: "Latest bid",
          bid: "Place bid",
          actions: {
            yourLastBid: "Your bid is winning",
          },
          result: {
            status: {
              [ClaimAuctionStatus.CLAIMABLE]: "Claim",
              [ClaimAuctionStatus.NOT_WINNER]: "The item has been sold",
              [ClaimAuctionStatus.PENDING_ALLOWANCE]: "Approve",
              [ClaimAuctionStatus.CLAIMED]: "This item has been claimed",
              [ClaimAuctionStatus.DISCONNECTED_WALLET]: "Connect wallet",
            },
          },
        },
        offerAuctionBidList: {
          totalBids: "Total bids",
          bids: "Bids",
          date: "Date",
          user: "My bids",
          wallet: "Wallet",
          myBid: "My bid",
        },
        offerAuctionPhoneConfirmation: {
          confirmPhone: "Phone confirmation",
          whyConfirmPhone:
            "For enhanced security, we need to verify your phone number. Follow the steps below to continue.",
          phoneInputLabel: "1. Insert your phone number",
          sendCodeViaSMS: "Send SMS code",
          informCodeLabel: "2. Insert the verification code",
          whyInformCode:
            "Insert the verification code you've received on your phone",
          errors: {
            invalidConfirmationCode: "Incorrect validation code",
            genericError: "Something went wrong",
            action: {
              tryAgain: "Try again",
            },
          },
          actions: {
            cancel: "Cancel",
            validateAndContinue: "Validate and continue",
          },
        },
        offerAuctionBidModal: {
          bidSettleConfirmation: {
            onChain: {
              title: "Make bid",
              approveTokens: "Approve token allowance",
              confirmBid: "Make bid",
              disclaimer:
                'I agree with the <a href="/terms" target="_blank">terms and conditions</a> and I am willing to provide proof of identity if I win this auction',
              errors: {
                insufficientTokenBalance:
                  "The amount {{amount}} is higher than the available balance ({{available}})",
              },
              actions: {
                approve: "Approve",
                confirm: "Confirm",
              },
            },
          },
          newBid: "New bid",
          bidAmount: "Insert the value of your bid",
          instruction:
            "Bid value should be bigger than {{min_pct}}% and less than {{max_pct}}% of the previous bid.",
          phone: "Insert your phone number",
          itemDetail: "Item details",
          minimumBid: "Minimum bid",
          tipMinimumAndMaximumBid: "Minimum bid of {{min}} and max of {{max}}",
          lowerThanMin: "Bid cannot be less than ({{min}})",
          biggerThanMax: "Bid is bigger than allowed ({{max}})",
          required: "Bid value is required",
          incorrectPhone: "Phone format is incorrect",
          currentBid: "Latest bid",
          initialBid: "Initial price",
          confirmBidTitle: "Confirm your bid",
          youtBid: "Your bid",
          agreeWithTerms:
            "Confirm I have read and agree with all auction rules",
          success: "Bid successfuly sent!",
          congratulation:
            "Congratulations! You can watch the auction evolution on the item screen.",
          error: "Something went wrong.",
          tryLater:
            "There was an error processing your bid. Please wait a moment and try again later.",
          gotIt: "I understand",
          continue: "Continue",
          cancel: "Cancel",
          errorMap: {
            failed_sequential_bid:
              "Users are not allowed to place sequencial bids, they have to wait a new user place a bid before send another request.",
          },
        },
        approvalStatus: {
          approved: "Approved",
          approvedTooltip:
            "The allowance is enough to cover one purchase at least",
          notApproved: "Waiting for approval",
          notApprovedTooltip:
            "Before buying you need to approve (update the allowance)",
        },
        footer: {
          view: {
            sustainableNFT: "Sustainable NFT Collectibles",
            oneAndMoss: "Made with 🤘🏾 by OnePercent.",
            officialProduct:
              "Licensed oficial product, all rights are reserved.",
          },
          data: {
            collection: 'Collection',
            termOfUse: 'Terms of use and privacy policy',
            conductCode: 'Code of Conduct',
            material: 'Educational Material',
          },
        },
        availability: {
          remaining: "remaining",
          units: "units",
        },
        topBar: {
          signUp: "SIGN UP",
          signIn: "SIGN IN",
          myData: "My data",
          myCollection: "My Collection",
          exit: "Exit",
          marketplace: "Marketplace",
          data: {
            packages: "Store",
            cards: "Cards",
          },
        },
        certificate:
          "Each item in the platform has its carbon consumption (carbon dioxide emissions, which are extremely polluting, generated during the production and delivery of items) automatically compensated, using Carbon-Pegged Crypto Tokens.",
        rarumHeader: {
          whatIsRarum: "What is Rarum?",
        },
        chip: {
          chipLabelSoon: "coming soon!",
          chipLabelSale: "for sale now!",
          chipLabelSaleFinished: "sale finalized",
          dateScheduled: " {{month}}, {{day}} at {{hours}}",
          dateExpired: "{{month}} , {{day}}",
          of: "of",
          at: "at",
        },
        credentials: {
          agree: "I agree with the",
          serviceTerms: "Terms of service",
        },
        dropCard: {
          isOn: "IT'S ON",
          details: "Details",
        },
        dropUnavailable: {
          notAvailable: "This item is no longer available.",
        },
      },
      core: {
        constants: {
          regulament: "",
          rarumDescription: [
            "Rarum is a platform for digital collectibles that allows brands to have their own space to contact their audience. Our platform manages everything from the publication of items to the purchase process made by fans.",
            "Each item in the platform has its carbon consumption (carbon dioxide emissions, which are extremely polluting, generated during the production and delivery of items) automatically compensated, using Carbon-Pegged Crypto Tokens.",
            "Our platform is also responsible for the security of your data and your assets. For this reason, we use blockchain technology to ensure the validity of items traded on the platform. Each item generated corresponds to a unique record, which serves as a kind of certificate of origin, with a cryptographic signature of the person who created each item. That way, you can be sure that we put in the latest technology to make sure the data is valid and the transactions are secure.",
            "If you want to know more about rarum, you can contact us at contact@rarum.io",
          ],
          dataDeletionInstructions: [
            "If you want to delete your data from our servers, you can remove them by following the instructions below:",
            '1. Go to "Settings & Privacy" > "Settings";',
            'two. Then go to "Applications and Websites";',
            '3. Remove "Rarum" from your apps;',
            '4. Send an email with the subject "DELETE MY DATA" to contact@onepercent.io from the email you created your account with. Your data will be removed from our servers within 1 business day.',
          ],
          externalEnvironmentAlert:
            "You will be redirected to an external environment, from Rarum's partner payment provider. Follow the instructions for the payment method selected on the next screen.",
          shareText: "Memorable moments are now collectibles",
          shareTitle: "Rarum - Collectible NFTs",
          longDateFormat: "MMMM dd, yyyy 'at' hh'h'mm aaaaa'.m.'", // H:mm
          scheduledDateFormat: "MMM dd 'at' hh:mm aaaaa'm'", // H:mm
          expiredDateFormat: "MMMM, dd",
        },
      },
    },
  },
};

export { messages };
