import React, { Fragment, useMemo, useState } from 'react'
import { ChatThreadViewProps } from './ChatThread.types'
import Avatar from '@onepercentio/one-ui/dist/components/Avatar'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { tO } from 'translate/i18n'
import { formatDistanceToNow } from 'core/helpers/formatter'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'

import Styles from './ChatThread.module.scss'
import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer/AdaptiveContainer'
import ChatThreadCreatorView from '../ChatThreadCreator/ChatThreadCreator.view'
import AnimatedEntrance from '@onepercentio/one-ui/dist/components/AnimatedEntrance/AnimatedEntrance'
import MutableHamburgerButton from '@onepercentio/one-ui/dist/components/MutableHamburgerButton/MutableHamburgerButton'

function ReplyView({
  user,
  createdAt,
  text,
}: Pick<ChatThreadViewProps, 'user' | 'createdAt' | 'text'>) {
  return (
    <>
      <div className={Styles.row}>
        <Avatar size={44} name={user.name} imgSrc={user.img} />
        <div>
          <OneText type={FigmaTypo.H4}>{user.name} </OneText>
          <OneText type={FigmaTypo.P16}>
            {tO('forum.postedAt', { time: formatDistanceToNow(createdAt) })}
          </OneText>
        </div>
      </div>
      <OneText className={Styles.content} type={FigmaTypo.P16}>
        {text}
      </OneText>
    </>
  )
}

/**
 * A thread someone initiated inside the chat room
 **/
export default function ChatThreadView({
  user,
  createdAt,
  text,
  replies,
  onReply,
  ...control
}: ChatThreadViewProps) {
  const [replying, setReplying] = useState(false)
  const [showAllReplies, setShowAllReplies] = useState(false)

  const slicedReplies = useMemo(() => {
    const firstReplies = replies.slice(0, 3)
    return showAllReplies
      ? [...firstReplies, ...replies.slice(3)]
      : firstReplies
  }, [showAllReplies, replies])

  return (
    <>
      <hr className={Styles.divider} />
      <ReplyView user={user} createdAt={createdAt} text={text} />
      <div className={Styles.actions}>
        {replies.length > 3 && (
          <Button
            onClick={() => setShowAllReplies(!showAllReplies)}
            color='primary'
            variant='transparent'>
            {showAllReplies ? replies.length : 3}/{replies.length}
            <MutableHamburgerButton
              size={24}
              state={!showAllReplies ? 'arrow-down' : 'arrow-up'}
            />
          </Button>
        )}
        <Button
          onClick={() => setReplying(true)}
          variant='filled'
          color='primary'>
          {tO('forum.actions.reply')}
        </Button>
      </div>
      <AdaptiveContainer direction='v'>
        {replying ? (
          <ChatThreadCreatorView
            key='replying'
            onPost={async (t) => {
              await onReply(t)
              setReplying(false)
            }}
            {...control}
            focused
            onCancel={() => setReplying(false)}
          />
        ) : (
          <Fragment key='closed' />
        )}
      </AdaptiveContainer>
      <div className={Styles.replies}>
        <AnimatedEntrance>
          {slicedReplies.map((r) => (
            <ReplyView
              key={r.createdAt.toISOString()}
              user={r.user}
              createdAt={r.createdAt}
              text={r.text}
            />
          ))}
        </AnimatedEntrance>
      </div>
    </>
  )
}
