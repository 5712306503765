import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import { Currency } from 'components/offer/OfferPrice'
import { DropType } from 'core/logic/drop/drop.types'
import { CurrenciesEnum } from 'core/types/CurrenciesEnum'
import React from 'react'
import { i18n } from 'translate/i18n'
import { mapPaymentMethodToCurrency } from '../Payment.data'
import useStyles from '../Payment.style'
import {
  IPaymentStepMethodInputs,
  PaymentMethods,
  SelectedPaymentMethodInfo,
} from '../Payment.types'
import { methodLabelComponent } from './methods'
import { PaymentMethodTestIDs } from './StepMethod.e2e'

export interface PaymentViewProps {
  data: IPaymentStepMethodInputs
  onNext: (data: IPaymentStepMethodInputs) => void
  drop?: DropType | null
  paymentMethods: PaymentMethods[]
  acceptedCurrencies: CurrenciesEnum[]
  selectedPaymentMethod?: SelectedPaymentMethodInfo
  onSelectedPaymentMethod: (selected: SelectedPaymentMethodInfo) => void
}

const Step3View: React.FC<PaymentViewProps> = ({
  onNext,
  drop,
  paymentMethods,
  acceptedCurrencies,
  selectedPaymentMethod,
  onSelectedPaymentMethod,
}) => {
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [method, currency] = event.target.value.split(',') as [
      PaymentMethods,
      CurrenciesEnum
    ]

    onSelectedPaymentMethod({
      method,
      currency,
    })
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    let method = selectedPaymentMethod!.method
    let currency = selectedPaymentMethod!.currency
    onNext({ metodo: method, ...(currency ? { currency } : {}) })
  }

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <Box mt={6} mb={4}>
        <Typography variant='body1' component='p'>
          <strong>
            {i18n.t(
              'authenticated.payment.steps.stepMethod.selectFormOfPayment'
            )}
          </strong>
        </Typography>
      </Box>
      <Grid container direction='column'>
        <RadioGroup
          aria-label='payment method'
          name='payment-method'
          value={selectedPaymentMethod}
          onChange={handleChange}
          className={classes.paymentMethodGroup}
          data-testid={PaymentMethodTestIDs.PAYMENT_METHOD_SELECTOR_CONTAINER}>
          {paymentMethods.map((paymentMethod) => {
            let currencyBasedOnMethod: CurrenciesEnum

            if (!drop) return ''

            switch (paymentMethod) {
              case 'CREDIT_CARD':
                return acceptedCurrencies.map((acceptedCurrency) => {
                  return (
                    <PaymentMethod
                      key={acceptedCurrency}
                      selected={selectedPaymentMethod}
                      paymentMethod={paymentMethod}
                      currencyBasedOnMethod={acceptedCurrency}
                      drop={drop}
                      acceptedCurrencies={acceptedCurrencies}
                    />
                  )
                })
              default:
                currencyBasedOnMethod =
                  mapPaymentMethodToCurrency[paymentMethod]
                return (
                  <PaymentMethod
                    key={paymentMethod}
                    selected={selectedPaymentMethod}
                    paymentMethod={paymentMethod}
                    currencyBasedOnMethod={currencyBasedOnMethod}
                    drop={drop}
                    acceptedCurrencies={acceptedCurrencies}
                  />
                )
            }
          })}
        </RadioGroup>
      </Grid>
    </form>
  )
}

function PaymentMethod({
  drop,
  paymentMethod,
  currencyBasedOnMethod,
  selected,
  acceptedCurrencies,
}: {
  drop: DropType
  paymentMethod: PaymentMethods
  currencyBasedOnMethod: CurrenciesEnum
  selected?: SelectedPaymentMethodInfo
  acceptedCurrencies: CurrenciesEnum[]
}) {
  const classes = useStyles()
  const MethodLabel = methodLabelComponent[paymentMethod]

  // Sets the initial currency based on the payment method
  let currency: CurrenciesEnum | undefined = currencyBasedOnMethod

  // The amount of the drop based of on the currency
  let amount = drop?.prices?.[currency]

  // If there is no amount set for the currency
  if (!amount) {
    amount = drop?.unitPrice // Gets the amount from the unitPrice
    currency = drop?.currency // Gets the currency from the drop
  }

  // Shows the flag if the usd is accepted, but the currency is BRL
  const hasCreditCardUSDMethod =
    acceptedCurrencies.includes(CurrenciesEnum.USD) &&
    currency === CurrenciesEnum.BRL

  if (
    currencyBasedOnMethod === currency && // The currency (from method or the drop)
    amount &&
    currency
  ) {
    return (
      <FormControlLabel
        key={paymentMethod}
        value={`${paymentMethod},${currency}`}
        control={<Radio className={classes.paymentMethodRadio} />}
        data-testid={PaymentMethodTestIDs.PAYMENT_METHOD_CONTAINER}
        label={
          <MethodLabel
            flag={hasCreditCardUSDMethod}
            checked={
              selected?.method === paymentMethod &&
              selected?.currency === currency
            }
            price={<Currency amount={amount} currency={currency} />}
            currency={currency}
          />
        }
        classes={{ label: classes.paymentMethodItem }}
      />
    )
  } else {
    return null
  }
}

export default Step3View
