import {
  Box,
  Button,
  Typography,
  useTheme,
} from '@material-ui/core'
import ActionButton from 'components/ActionButton'
import IconRarum from 'components/IconRarum'
import { AssetType, Sale } from 'core/logic/asset/asset.types'
import { i18n, t } from 'translate/i18n'
import { SHARE_URL } from 'core/constants'
import useStyles from './BuyOffer.style'
import { BuyOfferTestIds } from './BuyOffer.e2e'
import { RarumUserProfile } from 'core/logic/user/user.types'
import clsx from 'clsx'
import DropStepper from 'components/DropStepper'
import {
  ActionStep,
  MultiStepOperation,
} from 'components/MultiStepOperation/MultiStepOperation.view'
import { ComponentProps } from 'react'
import RarumByOne from 'openspace/assets/svg/lazyLogos/RarumByOne'
import DestinatedOfferInfo from 'components/DestinatedOfferInfo'

export default function BuyOfferView({
  asset,
  user,
  sale,
  userBalance,
  loading,
  onConfirm,
  onCancel,
  onRetry,
  onApproveAllowance,
  bought,
  error,
  needsBalanceApproval,
}: {
  asset?: AssetType
  user?: RarumUserProfile & { photoUrl?: string }
  sale?: Sale
  userBalance?: number
  loading: boolean
  error: boolean
  onRetry: () => void
  onConfirm: () => void
  onCancel: () => void
  onApproveAllowance: () => void
  bought: boolean
  needsBalanceApproval?: boolean
}) {
  const classes = useStyles()
  const translatedAssetData = asset ? asset[i18n.language] ?? asset : undefined
  const insuficientBalance =
    userBalance !== undefined
      ? userBalance < (sale?.price || Number.POSITIVE_INFINITY)
      : false

  const currentStep =
    needsBalanceApproval === false && !insuficientBalance ? 2 : 1
  const approvalError = currentStep === 1 ? insuficientBalance || error : false
  const isApprovalDisabled =
    (needsBalanceApproval === undefined && !error) ||
    currentStep !== 1 ||
    (insuficientBalance && userBalance !== undefined)

  const approvalState: ComponentProps<typeof ActionStep>['status'] =
    currentStep === 2
      ? 'checkmark'
      : loading
      ? 'loading'
      : approvalError
      ? 'closed'
      : 'default'

  const isConfirmDisabled = loading || currentStep !== 2
  const confirmationState: ComponentProps<typeof ActionStep>['status'] =
    currentStep === 1
      ? 'default'
      : loading
      ? 'loading'
      : error
      ? 'closed'
      : 'default'
  const confirmationError =
    currentStep === 2 ? insuficientBalance || error : false

  const theme = useTheme()

  return bought !== true ? (
    <DropStepper
      key={'buy_confirmation'}
      steps={
        <MultiStepOperation currentStep={currentStep}>
          <ActionStep
            action={{
              label: t(
                approvalError && error
                  ? 'generic.retryBtn'
                  : 'marketplace.buyOffer.actions.approve'
              ),
              disabled: isApprovalDisabled,
              onClick: approvalError ? onRetry : onApproveAllowance,
            }}
            status={approvalState}
            stepName={t('marketplace.buyOffer.approveTokens')}
            stepNum={1}
            error={approvalError}
            stepDescription={
              insuficientBalance ? (
                <Typography color='error'>
                  {t('marketplace.buyOffer.balanceWarning', {
                    currency: sale?.currency!,
                    amount: userBalance,
                  })}
                </Typography>
              ) : null
            }
          />
          <ActionStep
            action={{
              label: t(
                confirmationError
                  ? 'generic.retryBtn'
                  : 'marketplace.buyOffer.actions.confirm'
              ),
              disabled: isConfirmDisabled,
              onClick: confirmationError ? onRetry : onConfirm,
            }}
            status={confirmationState}
            stepName={t('marketplace.buyOffer.final')}
            stepNum={2}
            error={confirmationError}
          />
        </MultiStepOperation>
      }
      asset={asset!}
      assetInfo={{
        currency: sale?.currency!,
        price: sale?.price!,
        quantity: 1,
      }}
      label={{
        back: t('marketplace.offerCreation.actions.backToHome'),
        title: t('marketplace.buyOffer.title'),
        description: t('marketplace.buyOffer.description'),
        cancel: t('marketplace.buyOffer.actions.cancel'),
        preview: t('marketplace.offerCreation.sections.preview.title'),
      }}
      loading={loading}
      onBack={onCancel}
      data-fab-testid={BuyOfferTestIds.FAB}
    />
  ) : (
    <>
      <Box className={classes.root}>
        <Box
          className={classes.row}
          style={
            {
              '--logo-color': theme.palette.text.primary,
            } as any
          }>
          <RarumByOne />
          <a href={SHARE_URL}>
            <Typography variant='body1' color='primary'>
              <IconRarum size={18} icon='help' />{' '}
              {t('marketplace.buyOffer.whatIsRarum')}
            </Typography>
          </a>
        </Box>
        <Box className={clsx(classes.row, classes.main)}>
          <Typography variant={'h2'}>
            {t(
              bought
                ? 'marketplace.buyOffer.boughtItem'
                : 'marketplace.buyOffer.details'
            )}
          </Typography>
          <DestinatedOfferInfo
            data-testid={BuyOfferTestIds.FAB}
            asset={asset}
            isSale={true}
            insuficientBalance={insuficientBalance}
            sale={sale}
            userBalance={userBalance}
          />
        </Box>
        <Box className={classes.row}>
          <ActionButton
            disabled={insuficientBalance}
            text={t(
              error
                ? 'marketplace.buyOffer.actions.retry'
                : bought
                ? 'marketplace.buyOffer.actions.seeItem'
                : 'generic.continue'
            )}
            showSpinner={loading}
            onClick={error ? onRetry : onConfirm}
          />
          {!bought && (
            <Button
              className={classes.oneMoreCustomButton}
              size={'large'}
              onClick={onCancel}>
              <IconRarum icon='close' size={24} />
              &nbsp; {t('generic.cancel')}
            </Button>
          )}
        </Box>
      </Box>
    </>
  )
}
