import { RarumUserProfile } from 'core/logic/user/user.types'
import { RootState } from 'core/modules/redux'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FirebaseReducer, useFirebase } from 'react-redux-firebase'
import { actions as userActions } from 'core/logic/user/user.reducer'

export type UserContextShape = ClickActions<
  'remove' | 'logout' | 'cancelAccount'
> & {
  isEmpty: boolean
  detail: FirebaseReducer.AuthState | undefined
  profile: FirebaseReducer.Profile<RarumUserProfile> | undefined
  setAcceptDataUsage: (accept: boolean) => void
  isLoggedIn: boolean
  wallets: string[]
}
export const UserContext = createContext<UserContextShape>(null as any)

export default function UserProvider({ children }: PropsWithChildren<{}>) {
  const { updateProfile } = useFirebase()
  const dispatch = useDispatch()
  const state = useSelector<
    RootState,
    {
      auth?: FirebaseReducer.AuthState
      profile?: FirebaseReducer.Profile<RarumUserProfile>
      isEmpty: boolean
    }
  >(({ firebase: { auth, profile } }) => {
    const { isLoaded, isEmpty } = auth
    if (isLoaded && !isEmpty) {
      return { auth, profile, isEmpty }
    }
    return { isEmpty }
  })
  const setAcceptDataUsage = useCallback(
    (accepted: boolean) => {
      const profile = state.profile
      const userTenantConfig: NonNullable<
        NonNullable<RarumUserProfile['personalData']>['tenants']
      >[string] =
        profile?.personalData?.tenants &&
        profile?.personalData?.tenants[process.env.REACT_APP_TENANT]
          ? profile.personalData.tenants[process.env.REACT_APP_TENANT]
          : { dataConsent: [] }

      userTenantConfig.dataConsent.push({
        value: accepted,
        created: new Date(), // Important to collect user's date and time
      })
      return updateProfile({
        personalData: {
          ...profile?.personalData,
          tenants: {
            ...profile?.personalData?.tenants,
            [process.env.REACT_APP_TENANT!]: userTenantConfig,
          },
        },
      })
    },
    [state.profile, updateProfile]
  )
  const isLoggedIn = useMemo(() => {
    return !!state.auth?.uid
  }, [state.profile])

  const { profile } = state
  const wallets = useMemo(
    () =>
      [profile?.internalWallet, profile?.wallet].filter(Boolean) as string[],
    [profile?.internalWallet, profile?.wallet]
  )

  const cancelAccount = () => {}
  return (
    <UserContext.Provider
      value={{
        remove: () => dispatch(userActions.remove()),
        logout: () => dispatch(userActions.logout()),
        isEmpty: state.isEmpty,
        detail: state.auth,
        profile,
        setAcceptDataUsage,
        cancelAccount,
        isLoggedIn,
        wallets,
      }}>
      {children}
    </UserContext.Provider>
  )
}

export function useUser() {
  return useContext(UserContext)
}
