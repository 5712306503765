import BigNumber from 'bignumber.js'
import { dropStatus, DROP_STATUS } from 'core/helpers/drop'
import { useAuthentication } from 'core/logic/authentication'
import {
  useSingleSaleNFTApprove,
  useSingleSaleNFTBuy,
  useSingleSaleNFTContracts,
  useSingleSaleNFTGetAllowance,
  useSingleSaleNFTGetDecimals,
  useSingleSaleNFTGetPrice,
  useSingleSaleNFTGetSymbol,
  useSingleSaleNFTGetUnitsAvailable,
} from 'core/logic/contract/contract.hook'
import { useCounters } from 'core/logic/counters/counters.hook'
import { DropType } from 'core/logic/drop/drop.types'
import { useCustomSnackbar } from 'core/logic/snackbar'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { useWalletConnector } from 'core/logic/wallet'
import {
  SIGN_IN,
  TO_DROP_CHECKOUT,
  TO_FIREBASE,
  TO_WAITING_ROOM,
} from 'core/modules/router'
import { isNil } from 'lodash'
import memoizeOne from 'memoize-one'
import { SnackbarOrigin } from 'notistack'
import { ComponentProps, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { t } from 'translate/i18n'
import InfoView from './Info.view'
import InfoShimmer from './Shimmer/InfoShimmer.view'
import Loader from '@onepercentio/one-ui/dist/components/Loader/Loader'

// TODO: #REVISIT IMO we should have 2 logics one for onchain / other for offchain
export interface InfoLogicProps {
  drop?: DropType | null
  availability?: DROP_STATUS | null
}

const APPROVAL_MULTIPLIER =
  Number(process.env.REACT_APP_DEFAULT_APPROVAL_MULTIPLIER) || 3
const DEFAULT_TIMEOUT = 60000
const SNACK_LOCATION: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'top',
}

const memoizeIds = memoizeOne((id: string) => [id])

const InfoLogic: React.FC<InfoLogicProps> = ({ drop }) => {
  const history = useHistory()
  const { hasWallet } = useTenant()

  const { authentication } = useAuthentication()
  const { hasProvider, address: userWalletAddress } = useWalletConnector()

  const { enqueueClickCloseableSnackbar } = useCustomSnackbar()

  const [processTimeout, setProcessTimeout] = useState<
    NodeJS.Timeout | undefined | null
  >()

  const [previousAllowance, setPreviousAllowance] = useState<
    BigNumber | undefined
  >()

  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false)
  const [isWaitingForPurchase, setIsWaitingForPurchase] = useState(false)

  const { sale, payment, nft } = useSingleSaleNFTContracts(
    drop?.onChain?.contracts?.sale?.address || '',
    drop?.onChain?.contracts?.payment?.address || '',
    drop?.onChain?.contracts?.nft?.address || ''
  )

  const { data: unitPrice } = useSingleSaleNFTGetPrice(sale, {
    enabled: !!sale?.options?.address,
  })

  const { data: symbol } = useSingleSaleNFTGetSymbol(payment, {
    enabled: !!payment?.options?.address,
  })

  const { data: decimals } = useSingleSaleNFTGetDecimals(payment, {
    enabled: !!payment?.options?.address,
  })
  const denominator = new BigNumber(10).pow(decimals ?? 0)

  const { mutateAsync: buy, isLoading: isBuying } = useSingleSaleNFTBuy(
    sale,
    userWalletAddress,
    {
      onSuccess: () => {
        setProcessTimeout(
          setTimeout(() => {
            setIsWaitingForPurchase(false)
            enqueueClickCloseableSnackbar(
              t('unauthenticated.drops.details.info.purchaseFailMessage'),
              {
                variant: 'warning',
                anchorOrigin: SNACK_LOCATION,
              }
            )
          }, DEFAULT_TIMEOUT)
        )
        setIsWaitingForPurchase(true)
      },
    }
  )

  const { mutateAsync: approve, isLoading: isApproving } =
    useSingleSaleNFTApprove(payment, userWalletAddress, {
      onSuccess: () => {
        setProcessTimeout(
          setTimeout(() => {
            setIsWaitingForApproval(false)
            enqueueClickCloseableSnackbar(
              t('unauthenticated.drops.details.info.approvalFailMessage'),
              {
                variant: 'warning',
                anchorOrigin: SNACK_LOCATION,
              }
            )
          }, DEFAULT_TIMEOUT)
        )
        setIsWaitingForApproval(true)
      },
    })

  const { data: allowance } = useSingleSaleNFTGetAllowance(
    payment,
    userWalletAddress,
    sale.options.address,
    {
      enabled: drop ? drop?.type === 'onchain' : false,
      cacheTime: 0,
      refetchInterval: 2000,
    }
  )

  const { data: unitsAvailable } = useSingleSaleNFTGetUnitsAvailable(
    sale,
    nft,
    drop?.asset?.tokenId,
    {
      cacheTime: 0,
      enabled: drop ? drop?.type === 'onchain' : false,
      refetchInterval: 10000,
    }
  )

  const isAllowanceEnough = unitPrice
    ? unitPrice.isLessThanOrEqualTo(allowance ?? 0)
    : false

  const { counters } = useCounters({ dropIds: drop ? memoizeIds(drop.id) : [] })

  const remainingSupply =
    drop && drop.type !== 'onchain'
      ? !isNil(counters[drop?.id])
        ? drop.supply - (counters[drop.id] || 0)
        : undefined
      : unitsAvailable?.toNumber()

  const now = new Date()
  const availability = drop
    ? dropStatus({
        drop: drop,
        remaining: remainingSupply!,
        relativeTo: now,
      })
    : undefined

  const purchaseHandler = async () => {
    // If not available, do nothing
    if (
      !drop?.id ||
      !availability ||
      availability === 'expired' ||
      availability === 'scheduled'
    )
      return

    if (drop?.type !== 'onchain') {
      const { authenticated } = authentication
      // If not authenticated, go to login page
      if (!authenticated) return history.push(TO_FIREBASE[SIGN_IN])

      // Main flow
      if (availability === 'queue') history.push(TO_WAITING_ROOM(drop.id))
      else history.push(TO_DROP_CHECKOUT(drop.id))
    } else {
      try {
        if (!isAllowanceEnough) {
          await approveAndWait()
        } else {
          await buyAndWait()
        }
      } catch (err) {
        enqueueClickCloseableSnackbar((err as any).message, {
          variant: 'error',
          anchorOrigin: SNACK_LOCATION,
        })
        return
      }
    }
  }

  const approveAndWait = async () => {
    setPreviousAllowance(allowance)
    await approve({
      spender: sale.options.address,
      value: unitPrice!
        .times(drop?.onChain?.approvalPriceMultiplier || APPROVAL_MULTIPLIER)
        .toString(),
    })
  }

  const buyAndWait = async () => {
    setPreviousAllowance(allowance)
    await buy()
  }

  // Clear the timeout
  useEffect(
    () => () => {
      if (processTimeout) clearTimeout(processTimeout)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // Await for approval
  useEffect(() => {
    if (isWaitingForApproval) {
      // TODO: #REVISIT the right way to do it is listening the events
      if (!allowance?.isEqualTo(previousAllowance ?? 0)) {
        setIsWaitingForApproval(false)
        if (processTimeout) {
          clearTimeout(processTimeout)
          setProcessTimeout(undefined)
        }
        enqueueClickCloseableSnackbar(
          t('unauthenticated.drops.details.info.approvalSuccessMessage'),
          {
            variant: 'success',
            anchorOrigin: SNACK_LOCATION,
          }
        )
      }
    }
  }, [
    isWaitingForApproval,
    allowance,
    previousAllowance,
    processTimeout,
    enqueueClickCloseableSnackbar,
    t,
  ])

  // Await for purchase
  useEffect(() => {
    if (isWaitingForPurchase) {
      // TODO: #REVISIT the right way to do it is listening the events
      if (!allowance?.isEqualTo(previousAllowance ?? 0)) {
        setIsWaitingForPurchase(false)
        if (processTimeout) {
          clearTimeout(processTimeout)
          setProcessTimeout(undefined)
        }
        enqueueClickCloseableSnackbar(
          t('unauthenticated.drops.details.info.purchaseSuccessMessage'),
          {
            variant: 'success',
            anchorOrigin: SNACK_LOCATION,
          }
        )
      }
    }
  }, [
    isWaitingForPurchase,
    allowance,
    previousAllowance,
    processTimeout,
    enqueueClickCloseableSnackbar,
    t,
  ])

  if (!drop || !availability || remainingSupply === undefined) {
    return <InfoShimmer />
  }

  return (
    <InfoView
      purchaseHandler={purchaseHandler}
      availability={availability}
      // OnChain unit price is fetched online
      drop={
        drop?.type !== 'onchain'
          ? drop
          : {
              ...drop,
              unitPrice:
                unitPrice?.dividedBy(denominator).toNumber() || drop.unitPrice,
            }
      }
      remainingSupply={remainingSupply}
      isLoading={
        isApproving || isWaitingForApproval || isBuying || isWaitingForPurchase
      }
      ctaLabel={
        isAllowanceEnough || !hasWallet
          ? t('unauthenticated.drops.details.info.ctaLabelBuy')
          : `${t(
              'unauthenticated.drops.details.info.ctaLabelApprove'
            )} ${symbol}`
      }
      approved={isAllowanceEnough}
      hasProvider={hasProvider}
    />
  )
}

export default function InfoLogicWrapper(
  props: ComponentProps<typeof InfoLogic>
) {
  const { web3 } = useWalletConnector()
  if (!web3) return <Loader />
  return <InfoLogic {...props} />
}
