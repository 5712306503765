import { useOnce } from 'core/helpers/useOnce.hook'
import { useDelivery } from 'core/logic/delivery/delivery.hook'
import { analyticsEvent } from 'core/modules/analytics/events'
import {
  doClaimDelivery,
  doOpen,
} from 'core/modules/firebase/service'
import { TO_MY_COLLECTION } from 'core/modules/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { messages } from './OpeningDrop.data'
import OpeningDropView from './OpeningDrop.view'
import { useJwtWalletRequiredEffect } from 'context/Profile'

const TEXT_DURATION = 10000
const PACK_OPENING_TIMEOUT = 1000 * 60

const OpeningDropLogic = () => {
  const history = useHistory()
  const goToCollection = () => history.push(TO_MY_COLLECTION())
  const { deliveryId } = useParams<{ deliveryId: string }>()
  const { delivery } = useDelivery({ deliveryId })
  const drop = delivery?.offer
  const [assets, setAssets] = useState<any>([])
  const [closeModalOpen, setCloseModalOpen] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const handleCloseModalOpen = () => setCloseModalOpen(true)
  const handleCloseModalClose = () => setCloseModalOpen(false)
  const handleErrorModalOpen = () => setErrorModalOpen(true)
  const handleErrorModalClose = () => goToCollection()

  const [opening, setOpening] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const isOpenRef = useRef(false)
  const [curText, setCurText] = useState(0)

  const openPack = useCallback(async () => {
    try {
      if (
        !opening &&
        ['CLOSED', 'UNCLAIMED'].includes(delivery?.status || '')
      ) {
        setOpening(true)
        if (delivery) analyticsEvent.openPack({ pack: delivery })
        if (delivery?.status === 'CLOSED') {
          await doOpen({ deliveryId, tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID })
        }
        if (delivery?.status === 'UNCLAIMED') {
          await doClaimDelivery(deliveryId)
        }
      }
    } catch (error) {
      console.error(error)
      handleErrorModalOpen()
    }
  }, [opening, delivery, deliveryId])

  const onOpenAssets = useCallback(() => {
    switch (delivery?.status) {
      case 'OPEN':
        setAssets(delivery?.assets)
        setIsOpen(true)
        isOpenRef.current = true
        break
      case 'MINED':
        setAssets([drop?.asset])
        setIsOpen(true)
        isOpenRef.current = true
        break
      case 'CLAIMED':
        setAssets([delivery?.asset])
        setIsOpen(true)
        isOpenRef.current = true
        break
    }
  }, [delivery?.status, drop?.asset, delivery?.assets])

  useJwtWalletRequiredEffect(() => {
    openPack()
  }, [openPack])

  useEffect(() => {
    onOpenAssets()
  }, [onOpenAssets])

  useEffect(() => {
    const textIntervalId = setInterval(() => {
      setCurText((curText + 1) % messages.length)
    }, TEXT_DURATION)
    return () => {
      clearTimeout(textIntervalId)
    }
  }, [curText])

  const [openingTimeout, setOpeningTimeout] = useState(false)
  const handleTimeoutModalClose = () => setOpeningTimeout(false)
  const handleTimeoutModalOpen = () => {
    if (!isOpenRef.current) setOpeningTimeout(true)
  }

  useOnce(() => {
    setTimeout(handleTimeoutModalOpen, PACK_OPENING_TIMEOUT)
  })

  return (
    <OpeningDropView
      delivery={delivery}
      drop={drop}
      assets={assets}
      onBackIntent={handleCloseModalOpen}
      onStay={handleCloseModalClose}
      backModalState={closeModalOpen}
      onBack={goToCollection}
      messages={messages}
      messageIndex={curText}
      isDropOpen={isOpen}
      errorModal={errorModalOpen}
      errorModalCloseHandler={handleErrorModalClose}
      openingTimeout={openingTimeout}
      onOpeningTimeoutCancel={handleTimeoutModalClose}
    />
  )
}

export default OpeningDropLogic
