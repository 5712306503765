import { messages as portugueseMessages } from './pt.proxy'
import { messages as englishMessages } from './en.proxy'
import { messages as spanishMessages } from './es.proxy'

const messages = {
  ...portugueseMessages,
  ...englishMessages,
  ...spanishMessages,
}

export { messages }
