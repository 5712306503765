import OriginalView from './Home.view.tsx'
import PolkaBanner from 'openspace/assets/clients/polkadot/banner.png'
import { ReactComponent as PolkaLogo } from 'openspace/assets/clients/polkadot/polkalogo.svg'
import PolkaStyles from './Home.polkadot.module.scss'
import Container from 'openspace/components/Container/Container.tsx'

export default function HomeView(props: any) {
  return (
    <>
      <div className={PolkaStyles.polkaBanner}>
        <img src={PolkaBanner} />
        <Container>
          <PolkaLogo />
        </Container>
      </div>

      <OriginalView {...props} />
    </>
  )
}
