import Styles from 'openspace/assets/styles/TextStyles.module.scss'
import { MenuOptions } from 'openspace/components/TopBar/TopBar.types'
import { AvailableConcepts } from 'openspace/pages/Authenticated/Home/v2/Home/Features/Features'
import { IPO_SECTIONS } from 'pages/Unauthenticated/Drops/Details/Models/IPO/IPO.view'

const menus: {
  [k in MenuOptions]: string
} = {
  [MenuOptions.ABOUT]: 'SOBRE A RARUM',
  [MenuOptions.FAQ]: 'FAQ',
  [MenuOptions.GALLERIES]: 'GALERIAS',
  [MenuOptions.MARKET]: 'MERCADO',
  [MenuOptions.HOW_IT_WORKS]: 'COMO FUNCIONA',
  [MenuOptions.BRAND]: 'MARCAS',
  [MenuOptions.CREATORS]: 'CRIADORES',
}

export type ConceptTranslations = {
  [F in AvailableConcepts]: {
    title: string
    description: string
    features: string[]
  }
}

export type ConceptDetailsShape =
  typeof conceptDetails[keyof typeof conceptDetails]

const OfferDetailsSections: {
  [k in keyof typeof IPO_SECTIONS]: string
} = {
  ABOUT: 'Sobre o investimento',
  DOCUMENTS: 'Documentos',
  KPI: "KPI's",
  FOUNDERS: 'Fundadores',
  FORUM: 'Forum',
}

const conceptDetails = {
  marketplace: {
    description:
      'Com um marketplace de tokens, você poderá fazer vendas no mercado primário e secundário de forma simples e rápida, integrada com meios de pagamento tradicionais, como PIX e Cartão de Crédito',
    features: [
      {
        name: 'Galerias de NFTs',
        description:
          'Para você organizar suas ofertas por temas e áreas de interesse',
      },
      {
        name: 'Minha coleção',
        description:
          'Seus usuários terão seus itens organizados e fáceis de gerenciar',
      },
      {
        name: 'Vendas',
        description:
          'Diversas formas de oferta (pacotes, venda direta, airdrops), de pagamento, mercado primário (venda direta) e secundário (venda entre os usuários), com prevenção a fraudes',
      },
      {
        name: 'Integração com carteiras',
        description:
          'Os usuários podem conectar suas carteiras cripto ao produto e acessarem o que há de melhor no mundo Web3 a partir da sua loja',
      },
      {
        name: 'Leilões',
        description:
          'Uma forma diferente de engajar seu público e tornar itens únicos em elementos de alto engajamento e receita',
      },
    ],
    cases: [
      {
        title: 'Digital Garage Volkswagen',
        subtitle: 'Uma grande marca aproximando seu público de forma inovadora',
        description:
          'Lançada em 2022, a Digital Garage é um Marketplace para venda de itens únicos ligados à marca Volkswagen. O projeto foi um sucesso desde o seu lançamento, tendo tanto vendas em pacotes quanto leilão de peças únicas, físicas e digitais.',
        link: 'https://digitalgaragevw.com',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmarket%20volks.png?alt=media&token=544a59bf-3ec8-4a49-b2ee-ab95bf2a4df4',
        highlights: ['Galeria de NFTs', 'Vendas', 'Leilão'],
      },
      {
        title: 'Dia do Batom',
        subtitle: 'Para uma data especial, inovação e empoderamento',
        description:
          'As marcas do Grupo Boticário abraçaram o Dia do Batom com uma campanha em que cada comprador teria sua própria boca imortalizada em uma arte NFT (física e digital), além de uma infinidade de benefícios, como descontos, conteúdo exclusivo e até uma visita à fábrica!',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmarket%20dia%20batom.png?alt=media&token=35797b8e-471e-4e69-bd28-b31a9671a9d0',
        link: 'https://nft.diadobatom.com.br/',
        highlights: ['Galeria de NFTs', 'Vendas', 'Leilão', 'Campanha'],
      },
    ],
  },
  membership: {
    description:
      'Seus tokens como forma de aproximar o público e representar o pertencimento à sua comunidade com benefícios exclusivos.',
    features: [
      {
        name: 'Airdrops',
        description:
          'Lançamento de tokens para incentivar o cadastro de novos usuários para a comunidade',
      },
      {
        name: 'Gated Content',
        description:
          'Acesso a conteúdos exclusivos para os membros da sua comunidade, tendo o token como chave de acesso aos benefícios',
      },
      {
        name: 'Exclusive Live Events',
        description:
          'Conteúdo exclusivo para os token holders na sua plataforma',
      },
      {
        name: 'Members Only',
        description:
          'Utilizar o token para a realização de atividades exclusivas para membros, sejam físicas ou presenciais',
      },
      {
        name: 'Community Events',
        description:
          'Mantenha os membros da sua comunidade atualizados com a sua agenda de eventos',
      },
    ],
    cases: [
      {
        title: 'Kofre Café',
        subtitle: 'Arte e gastronomia em um único passaporte',
        description:
          'Com o token do Kofre Café, os membros do Kofre Club podem acessar experiências únicas, tanto de gastronomia, quanto culturais, junto ao Espaço Santander Cultural na cidade de Porto Alegre.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmember%20kofre.png?alt=media&token=6e85dcf9-2090-422c-9653-5e6b41695449',
        link: 'https://rarum.io/asset/eolW6ABKYvk9hhpso4Qp',
        highlights: [
          'Engajamento',
          'Clube',
          'Experiências',
          'Arte',
          'Gastronomia',
        ],
      },
      {
        title: 'Bored Apes*',
        subtitle: 'Um clube com identidade marcante',
        description:
          'Desejado por celebridades e personalidades do mundo todo, o Bored Apes é um clube no qual os portadores dos seus tokens têm acesso a benefícios exclusivos, como produtos, conteúdos e eventos físicos e digitais. Cada membro é único e o símbolo de cada usuário é uma imagem marcante. O proprietário tem liberdade total sobre seu acesso, podendo comercializar em mercado secundário, se quiser.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmember%20bored.png?alt=media&token=1fc92ace-6c17-4b43-9a91-54ce492c167c',
        link: 'https://boredapeyachtclub.com/',
        highlights: ['Engajamento', 'Clube', 'Experiências', 'Exclusividade'],
      },
    ],
  },
  social: {
    description:
      'Utilize os tokens para incentivar a interação entre os membros da sua comunidade, para fortalecer o engajamento com sua marca e conteúdo.',
    features: [
      {
        name: 'Discord',
        description:
          'Utilize o token como controle de acesso a grupos exclusivos no Discord',
      },
      {
        name: 'Telegram',
        description:
          'Utilize o token como controle de acesso a grupos exclusivo no Telegram',
      },
      {
        name: 'Twitch',
        description:
          'Transforme o engajamento em lives da Twitch em fidelização do seu público com recompensas',
      },
    ],
    cases: [
      {
        title: 'Universus',
        subtitle: 'Um game com engajamento e ganho para seus membros',
        description:
          'O jogo Universus combina elementos Play to Earn com uma dinâmica de jogo divertida e com conteúdos exclusivos aos token holders em seus canais no Discord.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fsocial%20universus.png?alt=media&token=6b5a0286-4cc9-4ac1-a698-49a5f7e86d31',
        link: 'https://www.universus.gg/',
        highlights: ['Discord', 'Engajamento', 'Games'],
      },
      {
        title: 'Bored Apes*',
        subtitle: 'Um clube com identidade marcante',
        description:
          'Desejado por celebridades e personalidades do mundo todo, o Bored Apes é um clube no qual os portadores dos seus tokens têm acesso a benefícios exclusivos, como produtos, conteúdos e eventos físicos e digitais. Cada membro é único e o símbolo de cada usuário é uma imagem marcante. O proprietário tem liberdade total sobre seu acesso, podendo comercializar em mercado secundário, se quiser.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmember%20bored.png?alt=media&token=1fc92ace-6c17-4b43-9a91-54ce492c167c',
        link: 'https://boredapeyachtclub.com/',
        highlights: ['Engajamento', 'Clube', 'Experiências', 'Exclusividade'],
      },
    ],
  },
  gamification: {
    description:
      'Seus tokens como mecanismo de incentivo ao engajamento dos usuários, com desafios e recompensas',
    features: [
      {
        name: 'QR-Code Activation',
        description:
          'Links exclusivos a serem lidos no formato QR Code para resgatar tokens',
      },
      {
        name: 'App Engagement Activation',
        description: 'Faça campanhas de engajamento para seu aplicativo',
      },
      {
        name: 'Social Media Activation',
        description:
          'Eleve o engajamento nas mídias sociais para recompensar seus seguidores',
      },
      {
        name: 'Geofencing Activation',
        description:
          'Geração de tokens baseada na geolocalização dos seus usuários',
      },
      {
        name: 'Challenges',
        description:
          'Crie desafios que, quando compleetados, geram recompensas aos seus usuários',
      },
    ],
    cases: [
      {
        title: 'Universus',
        subtitle: 'Um game com engajamento e ganho para seus membros',
        description:
          'O jogo Universus combina elementos Play to Earn com uma dinâmica de jogo divertida e com conteúdos exclusivos aos token holders em seus canais no Discord.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fsocial%20universus.png?alt=media&token=6b5a0286-4cc9-4ac1-a698-49a5f7e86d31',
        link: 'https://www.universus.gg/',
        highlights: ['Discord', 'Engajamento', 'Games'],
      },
      {
        title: 'Uptrip*',
        subtitle: 'Viajar pode ser ainda melhor com benefícios exclusivos',
        description:
          'A startup Uptrip criou uma solução para engajar viajantes e empresas aéreas com tokens que reconhecem suas ações, fidelizam e dão benefícios durante o voo, como Wi-fi, Área VIP e upgrades de assento.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fgamification%20uptrip.png?alt=media&token=be884c8f-6c1d-41a9-b5e1-c0cd112c7b16',
        link: 'https://www.uptrip.app/',
        highlights: ['Engajamento', 'Incentivos', 'Benefícios'],
      },
    ],
  },
  smart_tickets: {
    description:
      'Seus ingressos agora são NFTs que entregam experiências físicas e digitais de forma simples e segura. Desde o ingresso até as ativações dentro do evento, para fomentar o engajamento.',
    features: [
      {
        name: 'NFT tickets primary and secondary',
        desctiption:
          'Venda de tickets para usuários e entre usuários, com performance e segurança',
      },
      {
        name: 'Event airdrops',
        description:
          'Emita tokens para incentivar a participação do público no seu evento',
      },
      {
        name: 'Event access solution',
        description:
          'Faça o controle do acesso ao seu evento utilizando o ticket inteligente',
      },
      {
        name: 'Proof of Attendance',
        description:
          'Emita certificados de participação personalizados como prova de participação do seu evento',
      },
    ],
    cases: [
      {
        title: 'Simple Music',
        subtitle: 'Salve simpatia! O evento é seu.',
        description:
          'O projeto Simple Music criou tokens que dão acesso a um show do artista Jorbe Ben Jor, um dos artistas mais festejados do Brasil. Os tickets foram comercializados em grupos de 5, 20 e 40 unidades.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fsmart%20simple.png?alt=media&token=b10bcb83-fed6-40b1-a51e-b569384cc41b',
        link: 'https://rarum.io/g/Xc1SgS4szJNFtgNJ3PMe',
        highlights: ['Eventos', 'Exclusivo', 'Crowdfunding'],
      },
      {
        title: 'Ticketmaster*',
        subtitle: 'Benefício para o fã e para o artista',
        description:
          'A gigante dos eventos Ticketmaster lançou uma funcionalidade que permite que lotes de ingressos sejam comercializados exclusivamente para proprietários de tokens dos artistas. Isso permite que o tradicional modelo de fã-clube agora passe a ser digital e o engajamento traga ainda mais benefícios para os membros.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fsmart%20ticketmaster.png?alt=media&token=703edb10-c089-42e7-9d12-ebcf4079299d',
        link: 'https://business.ticketmaster.com/business-solutions/nft-token-gated-sales/',
        highlights: ['Eventos', 'Exclusivo', 'Fã-Clube'],
      },
    ],
  },
  digital_twins: {
    description:
      'As possibilidades de conectar itens físicos e digitais são infinitas. Construa experiências únicas em torno do seu produto e da sua marca, para engajamento do público',
    features: [
      {
        name: 'NFT + Physical Product Marketplace',
        description:
          'Faça a venda de produtos físicos e digitais em mercado primário e secundário',
      },
      {
        name: 'QR-Code',
        description:
          'A partir de um QR Code no seu produto físico, seu usuário será levado para uma experiência digital única',
      },
      {
        name: 'NFC Tagging Solution',
        description:
          'Com um toque do celular no seu produto, é possível levar os usuários à experiências digitais únicas relacionadas ao seu produto',
      },
    ],
    cases: [
      {
        title: 'Digital Garage Volkswagen',
        subtitle:
          'Um carro digital que te abre as portas para um momento histórico',
        description:
          'A última geração do carro queridinho do Brasil, o Gol teve uma série especial, em que uma das suas poucas unidades foi leiloada em sua plataforma digital e o vencedor do leilão recebeu o veículo e seu registro em blockchain na forma de um NFT, com arte exclusiva da equipe de design da Vokswagen e o direito de conhecer a fábrica.',
        link: 'https://digitalgaragevw.com',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmarket%20volks.png?alt=media&token=544a59bf-3ec8-4a49-b2ee-ab95bf2a4df4',
        highlights: ['Galeria de NFTs', 'Vendas', 'Leilão'],
      },
      {
        title: 'Dia do Batom',
        subtitle: 'Para uma data especial, inovação e empoderamento',
        description:
          'As marcas do Grupo Boticário abraçaram o Dia do Batom com uma campanha em que cada comprador teria sua própria boca imortalizada em uma arte NFT (física e digital), além de uma infinidade de benefícios, como descontos, conteúdo exclusivo e até uma visita à fábrica!',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Ftwins%20dia%20batom.png?alt=media&token=01a656b9-f187-4c25-88e3-f647c51d3098',
        link: 'https://nft.diadobatom.com.br/',
        highlights: ['Galeria de NFTs', 'Vendas', 'Leilão', 'Campanha'],
      },
    ],
  },
  shared_ownership: {
    description:
      'Crie modelos de participação nos seus negócios e ganhos para a comunidade usando seus tokens',
    features: [
      {
        name: 'Ativos fracionáveis',
        description:
          'Itens únicos em ofertas fracionadas que geram ganhos para os usuários',
      },
      {
        name: 'Distribuição de resultados',
        description:
          'Gere recompensas com seus tokens aos usuários com base na performance dos seus ativos',
      },
      {
        name: 'Conexão DeFi',
        description:
          'Crie pontes para modelos de Finanças Descentralizadas (DeFi) usando nossa solução',
      },
    ],
    cases: [
      {
        title: 'Moss Amazônia',
        subtitle: 'Faça parte do movimento de proteção da Floresta Amazônica',
        description:
          'Em um modelo de venda de NFTs em que cada unidade equivale a parte de uma área de preservação na floresta, a Moss permite sua comunidade se engajar em ações de proteção do "pulmão do mundo", de forma simples e segura.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fshared%20moss.png?alt=media&token=31bf7dcd-00bb-4047-9249-d21b71be8b72',
        link: 'https://nft.moss.earth/',
        highlights: ['Preservação', 'Amazônia', 'ESG'],
      },
      {
        title: 'Royal*',
        subtitle:
          'Gere retorno aos fãs com o sucesso dos seus artistas preferidos',
        description:
          'Em modelos de venda fracionada de tokens que representam royalties musicais, fãs podem incentivar seus artistas preferidos e ter um retorno com o sucesso nas vendas e streaming, em um modeo inovador e sustentável.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fshared%20royal.png?alt=media&token=a792fd43-3a27-467f-bc14-66b97d092866',
        link: 'https://royal.io/',
        highlights: ['Royalties', 'Música', 'Crowdfunding'],
      },
    ],
  },
  loyalty_coins: {
    description:
      'Quer ter sua própria moeda e utilizar como programa de fidelidade para seus usuários? Essa é a solução perfeita para você!',
    features: [
      {
        name: 'Moeda Própria',
        description: 'Seu token no formato ERC-20 para fidelidade',
      },
      {
        name: 'Distribuição Programada',
        description:
          'Estabeleça critérios para distribuir tokens para seus usuários',
      },
      {
        name: 'Integração com parceiros',
        description:
          'Permita com que seus tokens possam ser usados por parceiros para entregar benefícios e agregar valor',
      },
    ],
    cases: [
      {
        title: 'MercadoPago*',
        subtitle: 'Mercado Coin chega ao mercado',
        description:
          'Como parte de seu programa de fidelidade, a MercadoPago lança sua própria moeda. Ela é distribuída no formato de cashback aos seus usuários, incentiva o uso da plataforma e permite o uso na compra de produtos e também a operação no mercado de criptomoedas.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Floyalty%20marcadopago.png?alt=media&token=f756689e-a8f2-44e5-8e25-ab0cf98704eb',
        link: 'https://meubolso.mercadopago.com.br/mercado-coin-criptomoeda-mercado-livre',
        highlights: ['Fidelidade', 'Cashback', 'E-Commerce'],
      },
      {
        title: 'Nubank*',
        subtitle: 'Nucoin sacode o mercado',
        description:
          'Criada com o propósito de fidelizar usuários à plataforma Nubank, a Nucoin funciona como um sistema de cashback em que, quanto mais você usa, mais você ganha. O projeto prevê vantagens para os proprietários dos tokens e foi desenvolvido com uma comunidade inicial de 2.000 amantes da marca engajados em participar da construção do projeto.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Floyalty%20nubank.png?alt=media&token=856048df-0676-4f2e-9669-d4a7cb89183e',
        link: 'https://blog.nubank.com.br/nubank-cria-nucoin-moeda-digital-propria/',
        highlights: ['Fidelidade', 'Banco', 'Cashback'],
      },
    ],
  },
}

const concepts: ConceptTranslations = {
  marketplace: {
    title: 'Marketplace',
    description: 'Uma plataforma customizada para sua empresa',
    features: [
      'Galeria de NFTs',
      'Minha coleção',
      'Vendas',
      'Integração com carteiras',
      'Leilões',
    ],
  },
  membership: {
    description:
      'estimule o engajamento da sua comunidade com novas tecnologias',
    title: 'Membership',
    features: [
      'Airdrops',
      'Gated Content',
      'Exclusive Live Events',
      'Members Only',
      'Community Events',
    ],
  },
  social: {
    description: 'Presença estruturada em novos canais',
    title: 'Social',
    features: ['Discord Channels', 'Telegram Groups', 'Twitch Integration'],
  },
  gamification: {
    description: 'gamifique o relacionamento com sua comunidade',
    title: 'Gamification',
    features: [
      'QR-Code Activation',
      'App Engagement Activation',
      'Social Media Activation',
      'Geofencing Activation',
      'Challenges',
    ],
  },
  smart_tickets: {
    description: 'novos espaços para sua comunidade e ações de membership',
    title: 'Smart Tickets',
    features: [
      'NFT tickets primary and secondary',
      'Market',
      'Event airdrops',
      'Event access solution',
      'Proof of Attendance',
    ],
  },
  digital_twins: {
    description: 'Gere novas formas de receita a partir de ativos físicos',
    title: 'Digital Twins',
    features: [
      'NFT + Physical Product Marketplace',
      'QR-Code',
      'NFC Tagging Solution',
    ],
  },
  shared_ownership: {
    description: 'Inove e compartilhe a propriedade do seu ativo',
    title: 'Shared Ownership',
    features: [
      'Ativos fracionáveis',
      'Distribuição de resultados',
      'Conexão DeFi',
    ],
  },
  loyalty_coins: {
    description: 'Seu programa de fidelidade reinventado',
    title: 'Loyalty Coins',
    features: [
      'Moeda própria',
      'Distribuição programada',
      'Integração com parceiros',
    ],
  },
}

const messages = {
  'pt-BR': {
    translations: {
      generic: {
        noItemsFound: 'Nenhum item encontrado',
        brandMotto: 'Isso é raro. Isso é Rarum.',
        knowMore: 'Saiba mais',
      },
      forum: {
        postedAt: 'Postado {{time}} atrás',
        noItems:
          'Está com dúvidas? \nUtilize o campo acima e seja o primeiro a perguntar',
        post: {
          placeholder: 'Escreva sua dúvida aqui',
          cancelConfirmation: 'Deseja descartar a mensagem escrita?',
          actions: {
            cancel: 'Cancelar',
            post: 'Postar',
          },
        },
        actions: {
          reply: 'Responder',
        },
      },
      offerDetails: {
        goal: 'Meta',
        minInvestment: 'Investimento mínimo',
        goalReached: '{{percent}}% da meta alcançada',
        goalReached_0: 'Sem cotas reservadas até o momento',
        goalReached_beyond: 'Meta alcançada',
        limit: 'Limite',
        timeRemaining: 'TEMPO RESTANTE',
        offer: 'OFERTA',
        preMoneyValuation: 'VALUATION',
        companyParticipation: 'participação da empresa',
        sections: OfferDetailsSections,
        forum: {
          description:
            'Acesse o forum para conversar e tirar dúvidas com outros investidores e especialistas',
          posts: 'Postagens',
          actions: {
            access: 'Acessar Forum',
          },
        },
        actions: {
          invest: 'Investir',
          seeProfile: 'Ver perfil no {{social}}',
          moreInfo: 'Mais informações',
        },
      },
      components: {
        drop: {
          soldout: 'Unidades esgotadas',
          remaining: 'Último item disponível',
          remaining_plural: '{{count}} unidades disponíveis',
        },
        benefits: {
          title: 'Benefícios',
          subtitle: 'Veja e gerencie seus benefícios',
          claim: 'Resgatar',
          expired: 'Benefício expirado',
          claiming: 'Resgatando benefício',
          claimed: 'Benefício resgatado',
          expireIn: 'Expira em:',
          days_plural: '{{count}} dias',
          days: '{{count}} dia',
          remaining: '{{count}} unidade restante',
          remaining_plural: '{{count}} unidades restantes',
          claimConfirmation: {
            details: {
              title: 'Detalhe do benefício',
              details: 'Mais detalhes',
              actions: {
                claim: 'Resgatar benefício',
              },
            },
            title: 'Confirmar resgate',
            actions: {
              claim: 'Resgatar',
            },
            success: {
              title: 'Coleta confirmada!',
              subtitle:
                'Você tem certeza que deseja resgatar esse benefício? <br/>Essa ação não poderá ser desfeita',
              description:
                'Em breve você receberá instruções e mais informações sobre essa coleta no seu email cadastrado {{email}}',
              pending:
                'Esta transação pode levar alguns minutos. Assim que confirmado o resgate, você receberá instruções e mais informações no seu email cadastrado <b>{{email}}</b>',
              actions: {
                understood: 'Entendi',
              },
            },
          },
        },
      },
      forCreators: {
        brand: {
          description:
            'Na Rarum, sua marca pode engajar e fidelizar seu público de maneira inovadora, criando comunidades ativas e gerando novas fontes de receita.',
          sections: {
            loyalty: {
              proposal: [
                '<b>Vantagens Web3 únicas:</b> habilitar usuários a trocar facilmente pontos de fidelidade entre si ou trocar por pontos de outras marcas/parcerias, aumentnado a liquidez e o valor da sua moeda',
                'Monetizar relacionamento das marcas com seus clientes',
                'Conecte facilmente com parceiros',
              ],
            },
            nftGames: {
              proposal: [
                '<b>Vantagens Web3 únicas:</b> contratos inteligentes permitem configurar desafios com flexibilidade e execução automatizada, reduzindo o custo de ativação da base de usuários',
                'Camada de gamificação para earn (challenges/ desafios da marca)',
                'Trading de NFTs na plataforma com outros usuários (+ recorrência)',
                'Lista de beneficios resgatáveis na marca com coleção de NFTS conquistados/adiquiridos',
              ],
            },
            certificate: {
              proposal: [
                '<b>Vantagens Web3 únicas:</b> o registro em blockchain permite autenticação robusta do produto e rastreabilidade de eventos durante o ciclo de vida',
                'Registro de autenticidade na venda de produtos e ao longo da vida útil com acompanhamento de serviços (eg. revisão periódica, call-back, manutenção) e valor de revenda',
                'Conexão entre o produto físico e o digital pelo uso de sensores NFC e/ou QR Code',
              ],
            },
          },
        },
        creators: {
          description:
            'Criadores de conteúdo têm na Rarum uma solução completa para transformar o engajamento do seu público em receita, tanto com seus produtos, quanto com seus parceiros e patrocinadores.',
          sections: {
            web3FanClub: {
              proposal: [
                '<b>Vantagens Web3 únicas:</b> verificação da posse de NFTs é uma solução confiável para controle de acesso da comunidade a benefícios e permite usuários revender seu acesso ou benefícios específicos separadamente, enquanto mantém a posse do token',
                'Monetizar relacionamento do influencer com base de fãs',
                'Venda de acesso para membros com prazo fixo (ex: 1 ano), que dá acesso à comunidade fechada e conteúdos exclusivos',
              ],
            },
            creator: {
              proposal: [
                '<b>Vantagens Web3 únicas:</b> tokenização de ativos permite dividir a propriedade de ativos financeiros dos criadores de conteúdo em partes menores, que podem ser adquiridas por fãs e investidores',
                'Todos os recursos do produto <b>Web3 Fan Club</b>',
                'Adiantamento de recebível (futuros rendimentos) ao creator, com o menor custo de funding: os próprios fãs',
                'Venda de NFT que representa royalties sobre fluxo de caixa futuro, e beneficios do “fa-clube”',
              ],
            },
          },
        },
        mainSolutions: 'PRINCIPAIS SOLUÇÕES',
        valueProposition: 'PROPOSTA DE VALOR',
        coreModules: 'MÓDULO CORE',
      },
      home: {
        forCreators: {
          heading: 'PARA QUEM É A RARUM',
          title: 'Nossas soluções se adaptam ao seu perfil',
          tabs: ['Criador', 'Marca'],
          profile: 'PERFIL DO CLIENTE',
          sections: {
            brand: {
              loyalty: {
                title: 'Loyalty Coins',
                profile: [
                  'Marcas em busca de criar sua moeda própria e reconhecer a fidelidade de seus clientes',
                ],
              },
              nftGames: {
                title: 'Engage to Earn',
                profile: [
                  'Marcas em busca de adicionar experiências gameficadas a seus programas de fidelidade, aumentando receita, engajamento e valor ao longo do tempo',
                ],
              },
              certificate: {
                title: 'Product Certificate',
                profile: [
                  'Marcas com bens de consumo duráveis que querem estender de engajamento atrelado ao produto (montadoras, eletro, varejo de luxo)',
                ],
              },
            },
            creators: {
              web3FanClub: {
                title: 'Web3 Fan Club',
                profile: [
                  'Instagrammer',
                  'Influencer Digital',
                  'Jornalista',
                  'Streamer',
                ],
              },
              creator: {
                title: 'Creator investment club',
                profile: ['Músico', 'Compositor', 'Youtuber', 'Escritor'],
              },
            },
          },
          actions: {
            knowMore: 'Saiba mais',
            clickToKnowMore: 'Clique para saber mais',
          },
        },
        banner: {
          title: `ATIVOS DIGITAIS <br/><span class="${Styles.gradient}">SUSTENTÁVEIS E PARA <br/>TODOS</span>`,
          subtitle:
            'Mercado de ativos tokenizados customizados para a sua comunidade',
          action: {
            create: 'Crie agora sua loja!',
          },
          v2: {
            brand: 'rarum',
            brandMotto: 'Community Engagement as a Service',
            brandBenefit: 'Nós valorizamos o que é raro: sua comunidade.',
            actions: {
              features: 'Conheça nossa plataforma Whitelabel',
            },
          },
        },
        sections: {
          highlights: {
            title: 'Ofertas abertas',
          },
          contractModel: {
            section: 'COMO FUNCIONA',
            title: 'Você pode contratar a Rarum em dois formatos:',
            subtitle:
              'Fale com nossa equipe e descubra qual formato funciona melhor para seu negócio.',
            card: 'Fale com nossa equipe e descubra qual formato funciona melhor para seu negócio.',
            models: {
              platform: {
                title: 'Plataforma White Label',
                subtitle:
                  'Uma interface amigável para seu usuário, que abstrai as complexidades da Web3 e integra meios de pagamento, mercado primário e secundário.',
              },
              api: {
                title: 'API',
                subtitle:
                  'A solução ideal para integrar seu aplicativo ou plataforma à Web3, de forma rápida e segura.',
              },
            },
            actions: {
              contact: 'Entrar em contato',
            },
          },
          gallery: {
            heading: 'Conheça as Galerias da Rarum',
            title: 'Últimas galerias',
            action: {
              seeAll: 'Ver todas galerias',
            },
          },
          latest: {
            title: 'Últimas ofertas',
            action: {
              seeAll: 'Ver todos itens',
            },
          },
          store: {
            title: 'Lojas',
          },
          believers: {
            title: 'Quem acredita com a Rarum',
            description:
              'marcas que já transformaram <b>Likes em ROI</b> com a Rarum',
          },
          brand: {
            title: '100% Carbon Neutral',
            description: `Compensamos todas as emissões de carbono da plataforma e dos ativos comercializados.

A Rarum também opera em blockchains que são eficientes nas suas emissões de gás de efeito estufa.`,
          },
          faq: {
            title: 'Frequently asked questions',
            description:
              'As perguntas mais frequentes dos Clientes e Marcas que já usaram os serviços da Rarum.',
            questions: [
              {
                question: 'O que é uma plataforma Whitelabel?',
                answer:
                  'Uma plataforma Whitelabel é aquela em que você pode customizar a experiência de uso do produto com a sua marca e identidade visual. É assim que a Rarum funciona, as melhores funcionalidades com a sua cara!',
              },
              {
                question: 'Qual o tempo de personalização de uma Loja?',
                answer:
                  'Este é um processo realmente rápido, com um checklist organizado de ações, em poucas semanas, sua loja estará no ar e disponível para sua comunidade.',
              },
              {
                question: 'Como posso engajar meu público utilizando tokens?',
                answer:
                  'Antes de implantarmos a sua loja, nossa equipe irá te apresentar um framework de engajamento para você e seu público usando tokens. Essa metodologia é fruto de um extenso trabalho de benchmark feito com mais de 27 plataformas existentes e mais de 100 critérios de avaliação.',
              },
              {
                question: 'Em qual rede blockchain meus tokens são gerados?',
                answer:
                  'Nossa plataforma é compatível com todas as redes compatíveis com o padrão Ethereum Virtual Machine (EVM), o que inclui redes como Ethereum, Polygon, Celo e tantas outras!',
              },
              {
                question:
                  'Quais são as formas de pagamentos suportada pela plataforma?',
                answer:
                  'Suportamos pagamento em PIX, Cartão de Crédito e Criptomoedas (USDC).',
              },
              {
                question: 'É preciso conhecer sobre cripto para usar a Rarum?',
                answer:
                  'Com a Rarum, o acesso ao mundo Web3 é muito simples. A nossa solução é capaz de gerenciar toda a complexidade da blockchain para sua marca e seu público, sem obrigar o uso de wallets ou criptomoedas para poder interagir. Mesmo assim, usuários mais avançados podem conectar suas carterias e utilizar suas criptomoedas, caso seja do interesse.',
              },
            ],
          },
          create: {
            title:
              'Crie sua própria galeria ou loja e comece a vender seus itens!',
            actions: {
              knowMore: 'Saiba mais',
            },
            highlight: {
              create: 'Crie seus ativos digitais',
              easy: 'Fácil para comprar e vender',
              secure: 'Segura, transparente e carbono neutro',
            },
          },
          partnership: {
            title: 'Carbono Neutro',
            description:
              'Em nossa busca por práticas mais sustentáveis, reduzimos significativamente nossas emissões de carbono e implementamos tecnologias blockchain de baixo impacto ambiental.',
            actions: {
              knowMore: 'Saiba mais',
            },
          },
          news: {
            title: 'Notícias',
          },
          contactUs: {
            form: {
              title: 'Falar com a Rarum',
              description:
                'Preencha os campos abaixo e, se puder, nos explique o motivo do seu contato. Agradecemos pelo seu interesse.',
            },
            v2: {
              title: 'Vamos criar caminhos <b>com a rarum?</b>',
              description: 'Acompanhe a Rarum nas redes sociais da OnePercent',
              actions: {
                schedule: 'Agende uma demonstração',
              },
            },
            title: 'Entre em contato',
            description:
              'Preencha os dados abaixo e vamos conversar sobre como ajudar o seu negócio',
            success: `<span class="${Styles.gradient}">Contato enviado<br/>Agora é só aguardar</span>`,
          },
          features: {
            title: 'O QUE OFERECEMOS',
            description: 'nós transformamos engajamento em negócio',
            benefit:
              'Desenvolvemos um conjunto de módulos que se combinam para criar o modelo de engajamento e monetização que melhor se encaixa a seus usuários e seu negócio.',
            actions: {
              schedule: 'Agende uma demonstração',
            },
            concepts,
            mainFeatures: 'PRINCIPAIS FUNCIONALIDADES',
          },
        },
      },
      featureDetails: {
        useCases: 'Casos de uso',
        details: conceptDetails,
        more: 'Outro módulos',
        highlights: 'HIGHLIGHTS',
        actions: {
          visitCase: 'Visite o case',
        },
        demo: {
          title: 'Agende uma demonstração e <b>saiba mais sobre a rarum</b>',
          actions: {
            schedule: 'Agendar uma demonstração',
          },
        },
      },
      allGalleries: {
        title: 'GALERIAS',
        sections: {
          highlight: {
            title: 'Galerias em destaque',
          },
          createYourOwn: {
            title:
              'Crie sua própria galeria ou loja e comece a vender seus ativos digitais!',
            action: {
              knowMore: 'Saiba mais',
            },
          },
        },
      },
      myCollection: {
        actions: {
          seeAllGallery: 'Ver todos',
          hideAllGallery: 'Voltar a lista',
        },
      },
      galleryDetails: {
        items: 'Ofertas ativas',
        expiredItems: 'Ofertas encerradas',
        market: 'Itens do mercado',
        noMarket: 'Itens da galeria',
        noAvailable: `Fique de olho!
Em breve teremos ofertas ativas nesta galeria`,
        actions: {
          seeAllMarketplace: 'Ver todos no mercado',
          seeAll: 'Ver todos os items',
        },
      },
      topbar: {
        menus,
        auth: {
          login: 'Acessar conta',
          signup: 'Cadastre-se',
        },
      },
    },
  },
}

export type OpenspaceIntlShape = typeof messages['pt-BR']
export type AllMessagesIntlShape = OpenspaceIntlShape &
  typeof import('translate/languages/async/cvm88/pt')['default']['pt-BR']
export default messages
