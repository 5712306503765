import darkThemeFactory from './mode/dark'
import lightThemeFactory from './mode/light'

export default lightThemeFactory('#ff58a9', '#e6007a', '#ae004e', {
  primary: 'Inter',
  secondary: 'Lexend',
})
export const defaultMode = 'light'
export const alternativeMode = darkThemeFactory(
  '#ff58a9',
  '#e6007a',
  '#ae004e',
  {
    primary: 'Open Sans',
    secondary: 'Lexend',
  }
)
