import {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  ReactElement,
  RefAttributes,
} from 'react'
import Styles from './Container.module.scss'

type ContainerProps = {
  children: ReactElement | (ReactElement | null)[]
  className?: string
  id?: string
}

function Container(
  { children, className = '', id, ...otherProps }: ContainerProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      ref={ref}
      className={`${Styles.root} ${className}`}
      id={id}
      {...otherProps}>
      {children}
    </div>
  )
}

/**
 * This limits the width of the page content to include a limited visualization
 **/
export default forwardRef(Container) as ForwardRefExoticComponent<
  ContainerProps & RefAttributes<HTMLDivElement>
>
