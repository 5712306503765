import { i18n } from 'translate/i18n'
import { useDrop } from 'core/logic/drop/drop.hook'
import { useUser } from 'core/logic/user'
import { analyticsEvent } from 'core/modules/analytics/events'
import { doPurchase } from 'core/modules/firebase/service'
import { TO_DROP_DETAILS, TO_DROP_PENDING_PAYMENT } from 'core/modules/router'
import BlockLoading from 'components/BlockLoading'
import { useMemo, useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import {
  IPaymentInputs,
  PaymentMethods,
  SelectedPaymentMethodInfo,
} from './Payment.types'
import PaymentView from './Payment.view'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { PaymentProviders } from 'core/logic/tenant/tenant.types'
import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'

const parsePaymentMethods = (
  paymentProviders?: PaymentProviders
): PaymentMethods[] => {
  if (!paymentProviders) {
    return []
  }
  return Object.entries(paymentProviders).reduce<PaymentMethods[]>(
    (methods, [_name, props]) => {
      if (props.active) {
        return [
          ...methods,
          ...Object.entries(props.methods || {}).reduce<PaymentMethods[]>(
            (m, [method, active]) => [
              ...m,
              ...(active ? [method as PaymentMethods] : []),
            ],
            []
          ),
        ]
      }
      return methods
    },
    []
  )
}

const PaymentLogic = () => {
  const { tenant } = useTenant()
  const paymentMethods = useMemo(
    () => parsePaymentMethods(tenant?.paymentProviders),
    [tenant?.paymentProviders]
  ).sort()

  const history = useCustomHistory()
  const { dropId } = useParams<{ dropId: string }>()
  const { drop } = useDrop({ dropId })
  const { profile } = useUser()
  const { updateProfile } = useFirebase()

  const [doingPurchase, setDoingPurchase] = useState(false)
  const [purchaseError, setPurchaseError] = useState<string | null>()

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<SelectedPaymentMethodInfo>()

  const onBackClickHandler = () =>
    history.goBackWithFallback(TO_DROP_DETAILS(dropId))

  const onPay = async ({
    userName,
    cpf,
    email,
    numero,
    logradouro,
    estado,
    cidade,
    cep,
    metodo: _metodo,
    currency,
    persist,
    foreigner,
  }: IPaymentInputs) => {
    if (!_metodo) throw new Error('Something went wrong with payment method')

    const isCredit = _metodo === 'CREDIT_CARD'
    const isCrypto = _metodo === 'CRYPTO'

    if ((!userName || (!foreigner && !cpf) || !email) && !isCrypto) {
      throw new Error('Missing required form fields')
    }
    const sanitizedCep = !foreigner && cep ? cep.replace(/[^0-9]/g, '') : cep
    const sanitizedCpf = cpf ? cpf.replace(/[^0-9]/g, '') : ''
    let creditCardExtraFields
    if (isCredit) {
      if (!logradouro || !numero || !cidade || !estado || !cep) {
        throw new Error('Missing required form fields (credit card)')
      }
      creditCardExtraFields = {
        address: {
          street: logradouro,
          number: numero,
          // complement - optional field skipped,
          // neighborhood - optional field skipped,
          state: estado,
          city: cidade,
          postCode: sanitizedCep,
        },
      }
    }
    try {
      setPurchaseError(null)
      setDoingPurchase(true)
      const metodo =
        _metodo === 'BOLETO_PIX'
          ? tenant!.paymentProviders.mercadoPago.active
            ? 'PIX'
            : _metodo
          : _metodo

      const res = await doPurchase({
        offerId: dropId,
        tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
        amount: 1,
        paymentType: metodo,
        currency: currency,
        buyer: {
          name: userName ?? '',
          isForeigner: foreigner,
          document: sanitizedCpf,
          email,
          ...(isCredit ? creditCardExtraFields : {}),
        },
      })

      if (persist && !isCrypto) {
        try {
          await updateProfile({
            personalData: {
              name: userName,
              email,
              isForeigner: foreigner === undefined ? false : foreigner,
              cpf: sanitizedCpf,
              ...(isCredit ? { cep: sanitizedCep } : {}),
              ...(isCredit ? { state: estado } : {}),
              ...(isCredit ? { city: cidade } : {}),
              ...(isCredit ? { address: logradouro } : {}),
              ...(isCredit ? { addressNumber: numero } : {}),
            },
          })
        } catch (e) {
          // if saving fails, just ignore it
          console.error(e)
        }
      }
      history.push(TO_DROP_PENDING_PAYMENT(res.id))
    } catch (err: any) {
      console.error(err)
      const message = err?.message
      analyticsEvent.error({
        description: message
          ? `purchases-purchase: ${message}`
          : 'purchases-purchase',
      })
      setPurchaseError(i18n.t('authenticated.payment.logic.error'))
    } finally {
      setDoingPurchase(false)
    }
  }

  if (!profile?.isLoaded) {
    return <BlockLoading isOpen />
  }

  return (
    <PaymentView
      drop={drop}
      onBack={onBackClickHandler}
      onPay={onPay}
      doingPurchase={doingPurchase}
      purchaseError={purchaseError}
      profileData={profile.personalData}
      paymentMethods={paymentMethods}
      selectedPaymentMethod={selectedPaymentMethod}
      onChangePaymentMethod={setSelectedPaymentMethod}
    />
  )
}

export default PaymentLogic
