import { service } from 'core/modules/firebase'
import { CountersFirebaseType, CountersStateType } from './counters.types'

export const fetch = ({
  dropIds,
  emitter,
}: {
  dropIds: string[]
  emitter: (input: CountersStateType) => void
}) => {
  return service.fetchCounters({
    dropIds,
    onSnapshot: (snap) => {
      const docs = snap.docs

      const groupedCounters: CountersStateType = {}
      if (docs.length === 0) {
        for (let offerId of dropIds) groupedCounters[offerId] = 0
      } else {
        docs.forEach((doc) => {
          const { count, offerId } = doc.data() as CountersFirebaseType
          if (groupedCounters[offerId]) {
            groupedCounters[offerId]! += count
          } else {
            groupedCounters[offerId] = count
          }
        })
      }

      emitter(groupedCounters)
    },
  })
}
