import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { i18n, t } from 'translate/i18n'
import BackButton from 'components/BackButton'
import React, { ComponentProps } from 'react'
import useStyle from './Terms.style'
import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'
import ROUTES from 'core/modules/router'
import useScrollTo from 'hooks/useScrollTo'
import { useTerms } from 'core/logic/terms/terms.hook'
import SectionLoader from 'openspace/components/SectionLoader/SectionLoader'
import Text from 'openspace/components/Text/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'

const TermsView: React.FC<{
  termsType?: 'risk' | 'terms' | 'regulament'
}> = ({ termsType }) => {
  useScrollTo({
    withHighlight: true,
  })
  // Terms of use modal content
  const { content, title } = useContent(termsType)
  const classes = useStyle()
  const { goBackWithFallback } = useCustomHistory()

  return (
    <Container maxWidth='lg' disableGutters>
      <BackButton
        icon='arrow'
        onClick={() => goBackWithFallback(ROUTES.unauthenticated.homepage)}
        className={classes.closeButton}
        text={i18n.t('unauthenticated.terms.return')}
      />
      <Container maxWidth='md' disableGutters>
        <Box ml={4} mr={4}>
          <Typography variant='h4' component='h3' className={classes.title}>
            {title}
          </Typography>
          {content === undefined ? (
            <SectionLoader />
          ) : typeof content === 'string' ? (
            <Typography
              key={`terms-p`}
              variant='body1'
              color='textSecondary'
              className={classes.modalParagraph}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          ) : (
            content
          )}
        </Box>
      </Container>
    </Container>
  )
}

const useContent = (
  termsType: ComponentProps<typeof TermsView>['termsType']
) => {
  switch (termsType) {
    case 'risk':
      return {
        title: t('unauthenticated.risks.title'),
        content: (
          <>
            <Text
              code='unauthenticated.risks.description'
              type={FigmaTypo.P16}
            />
            <ul>
              <li>
                <Text
                  code='unauthenticated.risks.list.0'
                  type={FigmaTypo.P14}
                />
              </li>
              <li>
                <Text
                  code='unauthenticated.risks.list.1'
                  type={FigmaTypo.P14}
                />
              </li>
              <li>
                <Text
                  code='unauthenticated.risks.list.2'
                  type={FigmaTypo.P14}
                />
              </li>
            </ul>
          </>
        ),
      }
    default:
      // The terms type is not expected to change
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { terms } = useTerms()
      return {
        title: t('unauthenticated.terms.termOfUse'),
        content: terms,
      }
  }
}

export default TermsView
