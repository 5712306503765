import { BaseAssetType } from 'core/logic/asset/asset.types'
import { GalleryBound } from 'core/logic/gallery/gallery.types'

export const SIGN_IN = 'sign-in'
export const SIGN_UP = 'sign-up'
export const DROPS = 'drops'
export const CHECKOUT = 'checkout'
export const CONFIRM = 'confirm'
export const PAYMENT = 'payment'
export const PENDING = 'pending'
export const OPEN = 'open'
export const OPENING = 'opening'
export const WAITING_ROOM = 'waiting'
export const MY_COLLECTION = 'collection'
export const TERMS = 'terms'
export const DELETION = 'deletion'
export const PACKS = 'packs'
export const DETAIL = 'detail'
export const ACCOUNT = 'account'
export const ASSET = 'asset'
export const GALLERY = 'g'
export const FAQ = 'faq'

export const TO_MAINPAGE = () => '/'
export const TO_METAMASK = { [SIGN_IN]: `/${SIGN_IN}/metamask` }
export const TO_FIREBASE = {
  [SIGN_IN]: `/${SIGN_IN}/firebase`,
  [SIGN_UP]: `/${SIGN_UP}/firebase`,
}
export const TO_ACCOUNT = `/${ACCOUNT}`
export const TO_ASSET_LIST = `/${ASSET}`
export const TO_DROP_DETAILS = (dropId: string) => `/${DROPS}/${dropId}`
export const TO_DROP_CHECKOUT = (dropId: string) =>
  `/${DROPS}/${dropId}/${CHECKOUT}`
export const TO_DROP_PAYMENT = (dropId: string) =>
  `/${DROPS}/${dropId}/${PAYMENT}`
export const TO_DROP_PENDING_PAYMENT = (purchaseId: string) =>
  `/${PAYMENT}/${purchaseId}`
export const TO_WAITING_ROOM = (dropId: string) => `/${WAITING_ROOM}/${dropId}`
export const TO_DROP_OPEN = (deliveryId: string) =>
  `/${PACKS}/${deliveryId}/${OPEN}`
export const TO_DROP_OPENING = (deliveryId: string) =>
  `/${PACKS}/${deliveryId}/${OPENING}`
export const TO_MY_COLLECTION = () => `/${MY_COLLECTION}`
export const TO_COLLECTION_ITEM_DETAIL = (itemId: string, tokenId: number) =>
  `/${MY_COLLECTION}/${itemId}?tokenId=${tokenId}`
export const TO_ASSET_DETAIL = (assetId: string) => `/${ASSET}/${assetId}`
export const TO_GALLERY_DETAIL = (galleryId: string) =>
  `/${GALLERY}/${galleryId}`
export const TO_FAQ = `/${FAQ}`
export const TO_MARKETPLACE = () => ROUTES_UNAUTHENTICATED.marketplace
export const TO_MARKETPLACE_SELL = (assetId: string) =>
  ROUTES_AUTHENTICATED.sellMarketplace.replace(':assetId', assetId)
export const TO_MARKETPLACE_OFFER_EDIT = (assetId: string, offerId: string) =>
  ROUTES_AUTHENTICATED.editOffer
    .replace(':offerId', offerId)
    .replace(':assetId', assetId)
export const TO_MARKETPLACE_OFFER_DETAILS = (itemId: string, offerId: string) =>
  ROUTES_AUTHENTICATED.offerItemDetail
    .replace(':offerId', offerId)
    .replace(':assetId', itemId)
export const GALLERY_BOUND = <X extends GalleryBound>(
  pathBuilder: (entity: X) => string
) => {
  return (entity: X) => {
    if (entity.galleryId !== undefined)
      return TO_GALLERY(entity.galleryId)(pathBuilder(entity))
    else return pathBuilder(entity)
  }
}
export const TO_MARKETPLACE_ASSET_DETAILS = GALLERY_BOUND(
  (asset: BaseAssetType) =>
    ROUTES_UNAUTHENTICATED.marketplaceAssetDetail.replace(':assetId', asset.id)
)
export const TO_CREATORS_SECTION = (section: 'brand' | 'creators') =>
  ROUTES_UNAUTHENTICATED.creators.replace(':section', section)

export const TO_FEATURE_DETAILS = (feature: string) => {
  return ROUTES_UNAUTHENTICATED.featureDetails.replace(':featureId', feature)
}

export const TO_GALLERY = (galleryId: string) => (route: string) =>
  `/g/${galleryId}${route}`

export const TO_CHALLENGE = (challengeId: string) =>
  ROUTES.unauthenticated.challengeDetails.replace(':challengeId', challengeId)

export const TO_AIRDROP = (airdropId: string) => {
  return `${ROUTES.unauthenticated.claim}?airdropId=${airdropId}`
}

const ROUTES_UNAUTHENTICATED = Object.freeze({
  homepage: '/',
  terms: `/${TERMS}`,
  risk: `/risk`,
  regulation: `/regulation`,
  deletion: `/${DELETION}`,
  signIn: `/${SIGN_IN}/:provider`,
  signUp: `/${SIGN_UP}/:provider`,
  dropDetails: `/${DROPS}/:dropId`,
  asset: `/${ASSET}`,
  assetDetail: `/${ASSET}/:assetId`,
  marketplaceAssetDetail: `/marketplace/${ASSET}/:assetId`,
  galleryDetail: `/${GALLERY}/:galleryId`,
  allGalleries: `/${GALLERY}`,
  faq: `/${FAQ}`,
  marketplace: `/marketplace`,
  galleryBoundMarketplace: `/g/:galleryId/marketplace`,
  claim: `/airdrop`,
  claimSignup: `/claim/signup`,
  featureDetails: `/feature/:featureId`,
  creators: `/creators/:section`,
  challengeDetails: `/challenges/:challengeId`,
})

const ROUTES_AUTHENTICATED = Object.freeze({
  checkout: `/${DROPS}/:dropId/${CHECKOUT}`,
  payment: `/${DROPS}/:dropId/${PAYMENT}`,
  pendingPayment: `/${PAYMENT}/:purchaseId`,
  confirmPayment: `/${PAYMENT}/:purchaseId/${CONFIRM}`,
  open: `/${PACKS}/:deliveryId/${OPEN}`,
  opening: `/${PACKS}/:deliveryId/${OPENING}`,
  waitingRoom: `/${WAITING_ROOM}/:dropId`,
  myCollection: `/${MY_COLLECTION}`,
  collectionItemDetail: `/${MY_COLLECTION}/:assetId`,

  userAccount: `/${ACCOUNT}`,
  marketplaceOfferBuy: `/marketplace/${ASSET}/:assetId/${PAYMENT}/:offerId`,
  sellMarketplace: `/marketplace/${ASSET}/:assetId/sell`,
  editOffer: `/marketplace/:assetId/offer/:offerId/edit`,
  offerItemDetail: `/marketplace/:assetId/offer/:offerId`,
  transferTokens: '/transfer/confirm',
})

const ROUTES = {
  unauthenticated: ROUTES_UNAUTHENTICATED,
  authenticated: ROUTES_AUTHENTICATED,
}

export const GALLERY_BOUND_ROUTES = {
  authenticated: Object.entries(ROUTES_AUTHENTICATED).reduce(
    (r, [k, v]) => ({
      ...r,
      [k]: TO_GALLERY(':galleryId')(v),
    }),
    {} as typeof ROUTES_AUTHENTICATED
  ),
  unauthenticated: Object.entries(ROUTES_UNAUTHENTICATED).reduce(
    (r, [k, v]) => ({
      ...r,
      [k]: TO_GALLERY(':galleryId')(v),
    }),
    {} as typeof ROUTES_UNAUTHENTICATED
  ),
}

export default ROUTES
